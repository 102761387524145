import { useState, useEffect } from "react";
import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Tab } from "@mui/material";
import ClienteForm from "./components/ClienteForm";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DireccionesList from "./components/DireccionesList";
import DireccionClienteForm from "./components/DireccionForm";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ClienteModal({
    presupuestoId,
    open,
    cliente,
    telefono,
    selectedDireccionId,
    isEdit,
    isChange,
    fromPresupuesto,
    onSubmitCliente,
    onSubmitDireccion,
    onSelectedDireccion,
    onClose,
}) {

    const [tab, setTab] = useState('cliente');
    const [currentDireccion, setCurrentDireccion] = useState(null);
    const [isNewDireccion, setIsNewDireccion] = useState(false);
    const [isEditDireccion, setIsEditDireccion] = useState(false);

    useEffect(() => {

    }, [presupuestoId, cliente, isEdit, isChange]);

    const handleCloseModal = () => onClose();
    const handleChangeTab = (e, tab) => setTab(tab);
    const handleSubmitClient = (data) => { onSubmitCliente(data); onClose(); }

    const handleSetSelectedDireccion = (direccion) => { onSelectedDireccion(direccion); onClose(); }
    const handleNewDireccion = () => { 
        setCurrentDireccion(null); 
        setIsNewDireccion(true); 
        setIsEditDireccion(false); 
    }
    const handleEditDireccion = (direccion) => {
        setCurrentDireccion(direccion);
        setIsEditDireccion(true);
        setIsNewDireccion(false);
    }

    const handleSubmitDireccion = (direccion) => { 
        onSubmitDireccion(isEditDireccion, direccion); 
        setIsEditDireccion(false);
        setIsNewDireccion(false); 
    }

    const handleCancelDireccion = () => { setIsEditDireccion(false); setIsNewDireccion(false); }

    return (
        <Dialog
            open={open}
            onClose={handleCloseModal}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="cliente-form-title"
            aria-describedby="cliente-form-description">
        <DialogTitle id="cliente-form-title">
            <div className="w-100 d-flex align-items-center justify-content-between">
                <p className="fw-bold text-uppercase">{isEdit ? 'Editar' : 'Nuevo'} Cliente</p>

                <span title="cerrar" className="action" onClick={() => handleCloseModal()}>
                    <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                </span>
            </div>
           
        </DialogTitle>
        <DialogContent>
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="Cliente">
                    <Tab label="Cliente" value="cliente" />
                    <Tab label="Direcciones" value="direcciones" />
                </TabList>
                </Box>
                <TabPanel value="cliente">
                    <ClienteForm 
                        isEdit={isEdit}
                        fromPresupuesto={true}
                        telefono={telefono}
                        cliente={cliente}
                        onSubmitClient={handleSubmitClient} />
                </TabPanel>
                <TabPanel value="direcciones">
                    <div className="w-100">
                        { (!isEditDireccion && !isNewDireccion) &&
                            <DireccionesList 
                                presupuestoId={presupuestoId}
                                fromPresupuesto={fromPresupuesto}
                                direcciones={cliente?.direcciones}
                                selectedDireccionId={selectedDireccionId}
                                onNewDireccion={handleNewDireccion}
                                onEditDirecion={handleEditDireccion}
                                onSelectedDireccion={handleSetSelectedDireccion}
                            />
                        }
                    
                        { (isEditDireccion || isNewDireccion) &&
                            <div className="w-100 mt-3">
                                <DireccionClienteForm 
                                    cliente={cliente}
                                    direccion={currentDireccion}
                                    isNew={isNewDireccion}
                                    isEdit={isEditDireccion}
                                    onCancel={handleCancelDireccion}
                                    onSubmitDireccion={handleSubmitDireccion}
                                />
                            </div>
                        }
                    </div>
                </TabPanel>
            </TabContext>
        </DialogContent>
    </Dialog>
    )
}