import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProveedor, saveProveedor } from "../../../../../../../../../../redux/features/currentProveedor";
import { añadirPieza, modificarPieza } from "../../../../../../../../../../redux/features/fichaPresupuesto";
import ConfirmModal from "../../../../../../../shared/components/ConfirmModal";
import ProveedorModal from "../../../../../../../shared/components/ProveedorModal";
import { associateProveedor, comprobarProveedor } from "../../../../../../../shared/services/proveedores";
import EnvioModal from "./components/EnvioModal";
import PiezaModal from "./components/PiezaModal";
import PiezasSelectorDialog from "./components/PiezasSelector";
import ProveedorSelectorDialog from "./components/ProveedorSelector";
import { toast } from "react-toastify";
import ProveedorPocoFiableModal from "./components/PiezaModal/components/ProveedorPocoFiableModal";

export default function PiezasWizard({
    cliente,
    presupuestoId,
    selectedDireccionId,
    esVenta,
    isNew,
    isEdit,
    isDuplicate,
    isForDelete,
    onPiezasResult,
    onDeletePieza,
    onServicioEnvio,
    onCancel
}) {
    const dispatch = useDispatch();
    const [openForm, setOpenForm] = useState(false);
    const [openEnvioForm, setOpenEnvioForm] = useState(false);
    const [openProveedorForm, setOpenProveedorForm] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showPiezaSelectorModal, setShowPiezaSelectorModal] = useState(false);
    const [showProvSelectorModal, setShowProvSelectorModal] = useState(false);
    const [scrappingData, setScrappingData] = useState(null);
    const [showUnreliableModal, setShowUnreliableModal] = useState(false);
    const currentProveedor = useSelector(state => {
        return state.currentProveedor
    });
    const currentPieza = useSelector(state => {
        return state.currentPieza
    });
    
    useEffect(() => {
        if(isNew || isEdit) { setOpenForm(true);}
        
        if(isDuplicate && currentPieza){ duplicarPieza(currentPieza); }

        if(isForDelete) { setShowConfirmModal(true); }
        else { setShowConfirmModal(false); }


        if(currentPieza && !currentProveedor) { 
            dispatch({type: 'proveedor/fullfilled', payload: currentPieza?.proveedor});
        }
    }, [isEdit, isNew, isDuplicate, isForDelete, currentPieza]);


    const duplicarPieza = async(pieza) => {
        pieza.proveedor_id = pieza?.proveedor?.id;
        handleSubmitPieza(pieza);
    }

    const deletePieza = (confirm) => {
        setShowConfirmModal(false);

        if(confirm) {
            onDeletePieza(currentPieza);
        } else onCancel();
    }

    const handleCloseForm = () => { setOpenForm(false); onCancel(); }
    const handleOpenEnvioForm = (pieza) => { 
        dispatch({type: "pieza/fullfilled", payload: pieza || currentPieza});
        setOpenEnvioForm(true); 
    }
    const handleCloseEnvioForm = () => { setOpenEnvioForm(false); }

    const handleSubmitPieza = async (data) => {
        dispatch({type: 'loading/set', payload: true});

        if(presupuestoId > 0) {
            data.presupuesto_id = presupuestoId;
        }

        if(currentPieza.servicio_envio) {
            if(isDuplicate) {
                const piezaAux = Object.assign({}, currentPieza);
                delete piezaAux.id;
                dispatch(añadirPieza(piezaAux, presupuestoId > 0 ? presupuestoId : null));
            } else {
                if(isEdit) {
                    dispatch(modificarPieza(data, presupuestoId > 0 ? presupuestoId : null))
                } else dispatch(añadirPieza(data, presupuestoId > 0 ? presupuestoId : null));
            }
    
            setOpenForm(false);
            setScrappingData(null);
            onCancel();
            onPiezasResult((!isDuplicate && !isEdit ? 'new' : 'other'));
        } else {
            toast.error('Se tiene que asignar el envio a la pieza para guardarla!');
        }
    };

    const handleServicioEnvio = (servicio) => {
        dispatch({type: "pieza/servicio_envio", payload: servicio});
        setOpenEnvioForm(false);
        onServicioEnvio();
    }

    const handleOpenPiezasSelector = (data) => {
        setScrappingData(data);
        setShowPiezaSelectorModal(true);
    }

    const handleOpenProveedorSelector = (data) => {
        setScrappingData({...data,
        pieza: data.scrapping});
        console.log(data)
        setShowProvSelectorModal(true);
    }

    const handleOpenProveedorForm = (proveedor = null) => { 
        setOpenProveedorForm(true); 
        dispatch({type: 'proveedor/fullfilled', payload: proveedor});
    }

    const associateProv = async (proveedor, newName) => {
        try {
            await associateProveedor(proveedor, newName)
        } catch (e) {
            console.log(e)
        }
    }

    const handleSelectProveedor = (proveedor, isNew) => {
        const scrappingPieza = scrappingData.pieza;
        //Hacemos petiçao para asociar proveedores
        associateProv(proveedor,scrappingPieza.proveedor.nombre)
        scrappingPieza.proveedor = proveedor;
        delete scrappingPieza.proveedors;
        dispatch({type: "pieza/fullfilled", payload: scrappingPieza});
        setShowProvSelectorModal(false);
        dispatch({type: 'proveedor/fullfilled', payload: scrappingPieza.proveedor});
        
        if(isNew) { setOpenProveedorForm(true); }
    }

    const setPiezaFromScrappingSelector = (pieza) => {
        const scrappingPieza = setPiezaFromScrapping(pieza, scrappingData.url);

        if (pieza.proveedors){
            dispatch({type: 'proveedor/fullfilled', payload: pieza.proveedor});
            setScrappingData({...scrappingData, pieza: {...scrappingPieza, proveedors: pieza.proveedors, proveedor: pieza.proveedor}})
            setShowProvSelectorModal(true);
        } else {
            dispatch({type: 'proveedor/fullfilled', payload: scrappingPieza.proveedor});
            dispatch({type: "pieza/fullfilled", payload: scrappingPieza});
        }
    }

    const setPiezaFromScrapping = (scrapping, scrappingURL) => {
        return {
            ...scrapping,
            url: scrappingURL,
            precio_original: scrapping.precio_original ? parseFloat(scrapping.precio_original) : scrapping.precio_original,
            precio_compra: scrapping.precio_original ? parseFloat(scrapping.precio_original) : scrapping.precio_original,
            precio_transporte: scrapping.precio_envio ? parseFloat(scrapping.precio_envio) : 0,
            casco: 0,
            marca_id: scrapping.marca_id ? scrapping.marca_id : '',
            modelo_id: scrapping.modelo_id ? scrapping.modelo_id : '',
            tipo_pieza_id: scrapping.tipo_pieza_id ? scrapping.tipo_pieza_id : '',
            km_motor: scrapping.kilometraje ? scrapping.kilometraje : '',
            observaciones: scrapping.observaciones ? scrapping.observaciones : '',
            con_descuento: true,
        }
    }

    const handleClosePiezasSelectorModal = () => setShowPiezaSelectorModal(false);
    const handleCloseProveedorModal = () => setOpenProveedorForm(false);

    const handleUpdateProveedor = () => { 
        dispatch({type: 'proveedor/fullfilled', payload: currentProveedor});
    }

    const handleSubmitProveedor = async(data) => {
        dispatch({type: 'loading/set', payload: true});

        if(!data?.id) {
          const proveedorResponse = await comprobarProveedor({email: data.email, telefono: data.telefono})

            if(!proveedorResponse.data) {
                dispatch(saveProveedor(data))
                dispatch({type: 'proveedor/fullfilled', payload: data});
            } else {
                if(checkIfIsReliableProveedor(proveedorResponse.data)) {
                    dispatch({type: 'proveedor/fullfilled', payload: proveedorResponse.data});
                } else {
                    dispatch({type: 'proveedor/fullfilled', payload: proveedorResponse.data});
                    setShowUnreliableModal(true);
                }
            }
        } else {
          dispatch(editProveedor(data))
          handleUpdateProveedor();
        }
    }

    const handleSetProveedor = (confirm) => {
        if(confirm) {
            dispatch({type: 'proveedor/fullfilled', payload: currentProveedor});
        } else {
            dispatch({type: 'proveedor/fullfilled', payload: null});
            setOpenForm(false);
            onCancel();
        }

        setShowUnreliableModal(false);
    }

    const checkIfIsReliableProveedor = (proveedor) => { return proveedor.score === null || (proveedor.score !== null && proveedor.score >= 6.5); }


    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            { (openForm) &&
                <PiezaModal
                    open={openForm}
                    isEdit={isEdit}
                    esVenta={esVenta}
                    onSubmitPieza={handleSubmitPieza}
                    onClose={handleCloseForm}
                    onPiezasSelector={handleOpenPiezasSelector}
                    onProveedorSelector={handleOpenProveedorSelector}
                    onOpenProveedorForm={handleOpenProveedorForm}
                    onOpenServicioEnvio={handleOpenEnvioForm}
                    onSetEnvioProveedor={onServicioEnvio}
                />
            }

            { (openProveedorForm) &&
                <ProveedorModal
                    open={openProveedorForm}
                    proveedor={currentProveedor}
                    isEdit={currentProveedor ? true : false}
                    fromPresupuesto={true}
                    onSubmitProveedor={handleSubmitProveedor}
                    onSubmitAction={handleUpdateProveedor}
                    onClose={handleCloseProveedorModal}
                />
            }

            { (openEnvioForm) &&
                <EnvioModal 
                    open={openEnvioForm}
                    selectedDireccionId={selectedDireccionId}
                    cliente={cliente}
                    pieza={currentPieza}
                    onSubmit={handleServicioEnvio}
                    onClose={handleCloseEnvioForm}
                />
            }

            { (showConfirmModal) && 
                <ConfirmModal
                    onConfirmAction={deletePieza} 
                    title={'Eliminar pieza'} 
                    description={'Estás seguro de eliminar la pieza del presupuesto?'}
                    state={showConfirmModal}>
                </ConfirmModal>
            }

            { showPiezaSelectorModal && 
                <PiezasSelectorDialog
                    open={showPiezaSelectorModal}
                    data={scrappingData.scrapping}
                    onConfirmAction={setPiezaFromScrappingSelector} 
                    onClose={handleClosePiezasSelectorModal}
                />
            }
            { showProvSelectorModal && 
                <ProveedorSelectorDialog
                    open={showProvSelectorModal}
                    proveedor={scrappingData.pieza.proveedor}
                    data={scrappingData.pieza.proveedors}
                    onConfirmAction={handleSelectProveedor}
                />
            }

            { (showUnreliableModal) &&
                <ProveedorPocoFiableModal
                    pieza={currentPieza}
                    onConfirmAction={handleSetProveedor} 
                    state={showUnreliableModal}>
                </ProveedorPocoFiableModal>
            }
        </div>
    );
}