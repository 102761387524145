import React from "react";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign, faTimes } from "@fortawesome/free-solid-svg-icons";

export default function ProveedorValeModal({ 
    vale,
    state,
    onConfirmAction,
    onClose
}) {
    const [open, setOpen] = useState(state);
    const [importe, setImporte] = useState(0);

    const handleConfirm = (confirm) => {
        setOpen(false);
        onConfirmAction(confirm, importe);
    };

    const handleClose = () => { 
        setOpen(false); 
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description">
            <DialogTitle id="confirm-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">VALE DE PROVEEDOR</p>

                    <span title="cerrar" className="action" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="w-100">
                    <p>Este proveedor tiene un vale de descuento disponible.</p>
                    <p>
                        Si no lo quieres utilizar, haz clic en <span className="fw-bold text-danger">NO</span>, de lo contrario,
                        introduce el importe a descontar y haz clic en <span className="fw-bold text-green">SI</span>.
                    </p>

                    <p className="mt-3">Disponible: <b>{vale.total - vale.gastado}€</b></p>
                </div>

                <div className="w-100 mt-3">
                    <div className="form-group">
                        <label htmlFor="precio_transporte">Importe</label>
                        <div className="position-relative">
                            <input 
                                type="number" 
                                step="0.01"
                                className="form-control"
                                min={0}
                                defaultValue={importe}
                                onChange={(e) => setImporte(e.target.value)}
                             />
                            <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button type="button" className="btn btn-primary" onClick={() => handleConfirm(true)}>Si</button>
                <button type="button" className="btn btn-default" onClick={() => handleConfirm(false)}>No</button>
            </DialogActions>
        </Dialog>
    );
}