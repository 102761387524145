import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function DetalleForm({
    cliente,
    onSubmitCliente
}) {
    const clienteSchema = yup.object({
        nombre: yup.string().required("El nombre es obligatorio"),
        telefono: yup.string().required("El teléfono es obligatorio"),
        persona_contacto: yup.string().optional(),
    }).required();

    const { 
        register, 
        formState: { errors }, 
        handleSubmit,  
    } = useForm({
            mode: "onChange",
            resolver: yupResolver(clienteSchema),
            defaultValues: {
                nombre: '',
                telefono: '',
                persona_contacto: ''
            }
    });

    useEffect(() => {
    }, []);

    
    const onSubmit = async (data, e) => {
        e.preventDefault();

        if(cliente) { data.entity_id = cliente.id; }
        
        onSubmitCliente(data);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form w-100" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100 d-flex flex-column">
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("nombre", { maxLength: 50 })} 
                                defaultValue={''} />
                            
                            <div className="form-invalid">
                                {(errors.nombre) && errors.nombre?.message}
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="telefono">Teléfono</label>
                            <input 
                                type="text" 
                                className="form-control"
                                {...register("telefono", { required: true })} 
                                defaultValue={''} />

                            <div className="form-invalid">
                                {(errors.telefono) && errors.telefono?.message}
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="persona_contacto">Persona de contacto</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                {...register("persona_contacto", { maxLength: 50 })} 
                                defaultValue={''} />
                            
                            <div className="form-invalid">
                                {(errors.persona_contacto) && errors.persona_contacto?.message}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-content-center align-self-end">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}