import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchPlataformasGeneralMetrics, fetchPlataformasUserMetrics } from '../../../../../../../../redux/features/dashboard';
import '../../../Dashboard.scss';
import DateSelector from '../../../../../shared/components/DateSelector';
import UserMetrics from './components/UserMetrics';
import GeneralMetrics from './components/GeneralMetrics';

export default function PlataformasTab() {
    const dispatch = useDispatch();
    const [currentPlaforma, setCurrentPlataforma] = useState(null);
    const [startDate, setStartDate] = useState(moment().date() >= 26 ?
        moment().date(26).format('YYYY-MM-DD')
    :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().date() >= 26 ? 
        moment().add(1, 'month').date(25).format('YYYY-MM-DD')
    :   moment().date(25).format('YYYY-MM-DD'));
    const [view, setView] = useState('variable');
    const dashboard = useSelector(state => {
        return state.dashboard.entity.admin
    });
    const plataformas = useSelector(state => {
        return state.plataformas
    });

    useEffect(() => {
        dispatch(fetchPlataformasGeneralMetrics(startDate, endDate, view));
    }, []);


    const handleChangePlataforma = (e) => {
        if(parseInt(e.target.value) === -1) {
            dispatch(fetchPlataformasGeneralMetrics(startDate, endDate, view));
            setCurrentPlataforma(null);
        } else {
            dispatch(fetchPlataformasUserMetrics(e.target.value, startDate, endDate, view));
            setCurrentPlataforma(e.target.value);
        }
    }

    const handleDateInterval = (startDate, endDate, view) => {
        setView(view);
        setStartDate(startDate);
        setEndDate(endDate);

        if(!currentPlaforma) dispatch(fetchPlataformasGeneralMetrics(startDate, endDate, view));
        else dispatch(fetchPlataformasUserMetrics(currentPlaforma, startDate, endDate, view));
    }

    return (
        <div className='w-100 d-flex flex-column align-items-center dashboard'>
            <div className='w-100 mt-4 summary'>
                <h1>Información por plataforma</h1>

                <div className='w-100 d-flex align-items-center mb-4'>
                    { (plataformas && plataformas.length > 0) &&
                            <select 
                                id="plataforma"
                                defaultValue={currentPlaforma ? currentPlaforma : -1}
                                onChange={(e) => handleChangePlataforma(e)}>
                                <option key={'plataforma-' + 0} value={-1}>Todos</option>
                                {plataformas.map(op => <option key={'plataforma-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                    }

                    <DateSelector onSelectedDate={handleDateInterval} /> 
                </div>

                { (!currentPlaforma && dashboard.plataformasGeneralMetrics) &&  
                    <GeneralMetrics 
                        view={view}
                        data={dashboard.plataformasGeneralMetrics} />
                }

                { (currentPlaforma && dashboard.plataformasUserMetrics) && 
                    <UserMetrics 
                        view={view}
                        currentUser={dashboard.plataformasUserMetrics} />
                }
            </div>
        </div>
    )
}
