import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import InfoModal from "../../../shared/components/InfoModal";
import { newPresupuesto } from "../../../shared/services/presupuestos";
import { toast } from "react-toastify";
import '../../presupuestos.scss';
import { setErrorMessage } from "../../../shared/helpers/functionalities";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import Presupuesto from "./components/Presupuesto";
import { fetchPresupuesto, updatePresupuesto } from "../../../../../../redux/features/fichaPresupuesto";
import PresupuestoLogsInfo from "./components/Presupuesto/components/PresupuestoLogsInfo";

export default function FichaPresupuesto() {
    const { presupuestoId } = useParams();
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [logs, setLogs] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const [missingEnvios, setMissingEnvios] = useState(false);
    const [openConfirmExit, setOpenConfirmExit] = useState(false);
    const presupuesto = useSelector(state => {
        return state.presupuesto.entity
    })

    useEffect(() => {
        if(presupuestoId > 0) {
            dispatch(fetchPresupuesto(presupuestoId));
        }
    }, []);

    const handleNewPressupuesto = async(data) => {
        dispatch({type: "presupuesto/set-progress", payload: 99});
        const presu = {
            bastidor: data.bastidor,
            codigo_autorizacion: data.codigo_autorizacion !== '' ? data.codigo_autorizacion : null,
            observaciones: data.observaciones !== '' ? data.observaciones : null,
            validado: presupuesto.validado,
            validacion: presupuesto.validacion,
            total: presupuesto.total,
            total_envio: presupuesto.total_envio,
            iva: presupuesto.iva,
            total_iva: presupuesto.total_iva,
            entity_id: presupuesto.cliente.id,
            created_by: user.id,
            direccion_envio_id: presupuesto.direccion_envio_id ? presupuesto.direccion_envio_id : null,
            piezas: presupuesto.piezas
        };

        dispatch({type: 'loading/set', payload: true});
        const presupuestoResult = await newPresupuesto(presu)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
            dispatch({type: 'loading/set', payload: false});
        });

        if(presupuestoResult && presupuestoResult.success && presupuestoResult.data) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(presupuestoResult.message)
            dispatch({type: "search/set", payload: {search: presupuestoResult.data.numero }});
            navigate('/presupuestos/1', { replace: true });
        }
    }
    
    const handleCloseInfo = () => { setShowInfo(false); }
    const handleOpenInfo = () => {
        setShowInfo(true);
        setLogs(presupuesto?.logs)
    }

    const handleExit = () => { 
        if(presupuestoId > 0) {
            if(presupuesto.piezas?.find(p => p.servicio_envio === null || p.servicio_envio === undefined)) {
                setMissingEnvios(true);
                setOpenConfirmExit(true); 
            } else { navigate(-1); }
        } else {
            setOpenConfirmExit(true); 
        }
    }
    const handleConfirmExit = (confirm) => {
        setOpenConfirmExit(false);
        setMissingEnvios(false);

        if(confirm) navigate(-1);
    }

    const handlePresupuesto = (data) => { 
        dispatch({type: 'loading/set', payload: true});
        if(presupuestoId > 0) { dispatch(updatePresupuesto(data))  } 
        else { handleNewPressupuesto(data); }
    }
   
    return (
        <div className="w-100 content">
            <div className="w-100 mb-4">
                <button type="button" className="btn btn-add" onClick={() => (handleExit())}>
                    <span className="add">
                        <FontAwesomeIcon icon={faLongArrowAltLeft} size="1x" className="action" color="#215732" />
                    </span>
                    Atrás
                </button>
            </div>
                
           <Presupuesto 
                presupuestoId={parseInt(presupuestoId)}
                onOpenInfo={handleOpenInfo}
                onPresupuesto={handlePresupuesto}
           />
            
            { (showInfo) && 
                <InfoModal
                    state={showInfo}
                    title="Información del presupuesto"
                    content={<PresupuestoLogsInfo logs={logs} />}
                    onClose={handleCloseInfo}
                ></InfoModal>
            }

            { (openConfirmExit) && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmExit} 
                    title={'Salir'} 
                    description={'Estás seguro de salir?'}
                    content={missingEnvios ? 
                        <h5 className="fw-bold text-danger text-uppercase">¡¡Faltan por asignar envios!!</h5> 
                        : null
                    }
                    state={openConfirmExit}>
                </ConfirmModal>
            }
      </div>
    );
}