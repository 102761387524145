import { faCheck, faEdit, faFilePdf, faMoneyBill, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { generatePresupuestoPDF } from "../../../../../shared/services/presupuestos";
import { generateFacturaPDF } from "../../../../../shared/services/facturas"
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

export default function ItemsTable({
  list, 
  page,
  count,
  item, 
  proveedor,
  onPagoProveedor,
  onPageChange,
  onUpdatePrecioCompra
}) {
    const dispatch = useDispatch();
    const [pageCount, setPageCount] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [currVenta, setCurrVenta] = useState(null);
    const [currItem, setCurrItem] = useState(null);
    const [currIndex, setCurrIndex] = useState(null);
    const { 
      register, 
      formState: { errors }, 
      handleSubmit, 
      setValue,
      reset,
    } = useForm({
      mode: "onChange",
      defaultValues: {
          id: '',
          precio_compra: 0,
      }
  });

    useEffect(() => {
        var totalPages = count < 15 ? 1 : Math.ceil(count / 15);
        setPageCount(parseInt(totalPages));
    }, [list, page, count]);


    const generateThePdf = async (type, itm) => {
      let pdfResult;
      let pdfName = itm.numero;
      dispatch({type: 'loading/set', payload: true});
      
      if(type === 'presupuesto') {
        pdfResult = await generatePresupuestoPDF(itm.id);
      } else {
        pdfResult = await generateFacturaPDF(itm.factura_id);
      }
  
      if(pdfResult) {
        dispatch({type: 'loading/set', payload: false});
        toast.success('PDF generado correctamente!')
        const url = window.URL.createObjectURL(new Blob([pdfResult]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pdfName + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error('Ups! Algo ha ido mal...')
      }
    }

    const handleChange = (e, attr) => { setValue(attr, e.target.value); }
    const handleEdit = (itm, pieza, index) => {
      setIsEdit(true);
      setCurrVenta(itm);
      setCurrItem(pieza);
      setCurrIndex(index);
      reset({
        id: pieza.id,
        precio_compra: pieza.precio_compra,
      });
    }
    const cancelEdit = () => {
      setIsEdit(false);
      setCurrItem(null);
      setCurrIndex(null);
      reset();
    }

    const onSubmit = async (data, e) => {
      e.preventDefault();
      const dataAux = { id: currVenta.presupuesto_id, pieza: {...data}};
      onUpdatePrecioCompra(dataAux);
    };
    
    return (
        <div className="w-100 d-flex flex-column align-items-end">
           <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                        <th>{item === 'presupuesto'  ? 'Nº pres' : item === 'incidencia' ? 'Nº incidencia' : 'Nº factura'}</th>
                        <th className="text-center">fecha</th>
                        <th>Pieza</th>
                        <th className="text-center">€ compra</th>
                        <th className="text-center">€ envío</th>
                        {( item === 'compra') && <th className="text-center">pago proveedor</th>}
                        <th className="text-center">resp</th>
                        { (item === 'compra') ? <th className="text-center"></th> : null}
                        { (item === 'compra' && proveedor.credito) ? <th className="text-center">pago</th> : null}
                        { (item !== 'incidencia') && <th className="text-center">acciones</th> }
                    </tr>
                  </thead>
                  { 
                    list.map((itm, index) => {
                      const piezasValues = Object.values(itm.piezas);
                      let piezasRows = piezasValues.map((pieza, i) => {
                        let precioEnvio = '-';

                        if(item === 'presupuesto') {
                          const servicio = itm.servicios_envio?.find(s => s.piezas?.includes(pieza.id) && s.transportista_id === 1);

                          if(servicio) precioEnvio = servicio.precio_transporte;
                        } else if(item === 'compra') {
                          const envio = itm.envios?.find(e => e?.piezas?.find(p => p.id === pieza.id) && e.transportista_id === 1);

                          if(envio) { precioEnvio = envio.precio; }
                        } else if(item === 'devolucion') {
                          if(pieza?.devolucion_relationship?.envio) {
                            precioEnvio = pieza?.devolucion_relationship?.importe_envio;
                          }
                        } else {
                          const envio = itm.envios?.find(e => e.incidencia_id === itm.id && e.transportista_id === 1);

                          if(envio) { precioEnvio = envio.precio; }
                        }

                        const numero = i === 0 ? 
                          <td rowSpan={piezasValues.length + 1} width="12%">
                            {(item === 'presupuesto' || item === 'incidencia') ? 
                              itm.numero : 
                              item === 'devolucion' ?
                                <b className={itm.tipo_devolucion_id === 1 ? "text-warning" : "text-danger"}>{itm.numero}</b> 
                              :  
                              <b className={(itm.es_devolucion?.length > 0 && itm.es_devolucion[0].piezas?.length > 0) ? (itm.es_devolucion[0].tipo_devolucion_id === 1 ? "text-warning" : "text-danger") : ''}>{itm.numero}</b> 
                            }
                          </td> : null
                        const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">{moment(itm.fecha).format("DD-MM-YYYY")}</td> : null
                        const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1}  width="12%" className="text-center text-uppercase">{
                          itm.created_by?.username
                        }</td> : null
                        const tipoFacturaType = item !== 'incidencia' ? 
                          (item === 'presupuesto' ? 'presupuesto' 
                            : (itm.numero?.includes('AL') ? 'albaran' : 'factura')
                          ) : '';
                        const pdf = i === 0 ? <td rowSpan={piezasValues.length + 1} width="7%" className="text-center">
                          {
                            <>
                              <span title="Descargar PDF" style={{cursor: 'pointer'}} onClick={() => generateThePdf(tipoFacturaType, itm)}>
                                  <FontAwesomeIcon icon={faFilePdf} size="1x" className="action" color="#215732"></FontAwesomeIcon>
                              </span>
                            </>
                          
                          }
                        </td> : null;

                        return (
                          <tr key={'proveedor-' + i}>
                            {numero}
                            {fecha}
                            <td>
                              {
                                item === 'presupuesto' && pieza.presupuesto_relationship.unidades > 1 ?
                                  <strong>x{pieza.presupuesto_relationship.unidades}</strong> 
                                : item === 'venta' && pieza.venta_relationship.unidades > 1 ?
                                  <strong>x{pieza.venta_relationship.unidades}</strong> 
                                : item === 'devolucion' && pieza.devolucion_relationship.unidades > 1 ?
                                  <strong>x{pieza.devolucion_relationship.unidades}</strong> 
                                : ''
                              } 
                              {pieza.nombre}
                            </td>
                            <td className="text-center">
                              { isEdit && currIndex === i && currItem.id === pieza.id ?
                                <>
                                  <input 
                                    type="hidden" 
                                    {...register("id")} 
                                    defaultValue={pieza?.id || ''}
                                  />

                                  <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("precio_compra")} 
                                    defaultValue={pieza?.precio_compra}  
                                    onChange={(e) => handleChange(e, 'precio_compra')}
                                  />
                                </>
                                :
                                (pieza.precio_compra + '€')
                              }
                              </td>
                            <td className="text-center">{precioEnvio !== '-' ? precioEnvio + '€' : precioEnvio}</td>
                            { (item === 'compra') &&
                              <td className="text-center">
                                { (pieza.pago_proveedor == true) ?
                                  <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                  : (pieza.pago_proveedor == false) ? 
                                  <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                  : '-'
                                }
                              </td>
                            }
                            {responsable}
                            <td className="text-center">
                              { (item === 'compra') ?
                                <>
                                  { (isEdit && currIndex === i && currItem.id === pieza.id) ?
                                    <div>
                                      <button type="submit" className="link">
                                        <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                      </button>

                                      <span title="Cancelar" onClick={() => cancelEdit()}>
                                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                      </span>
                                    </div>
                                    :
                                    <span title="Editar precio de compra" style={{cursor: 'pointer'}} onClick={() => handleEdit(itm, pieza, i)}>
                                        <FontAwesomeIcon icon={faEdit} size="1x" className="action" color="#215732"></FontAwesomeIcon>
                                    </span>
                                  }
                                </>
                                : ''
                              }
                              </td>
                            {(item === 'compra' && proveedor.credito) ? 
                              <td className="text-center">
                                {(pieza.pago_proveedor == false || pieza.pago_proveedor === null) ?
                                    <span title="Descargar PDF" style={{cursor: 'pointer'}} onClick={() => onPagoProveedor(pieza.id, true)}>
                                        <FontAwesomeIcon icon={faMoneyBill} size="1x" className="action" color="#215732"></FontAwesomeIcon>
                                    </span>
                                    :
                                    <span title="Descargar PDF" style={{cursor: 'pointer'}} onClick={() => onPagoProveedor(pieza.id, false)}>
                                        <FontAwesomeIcon icon={faMoneyBill} size="1x" className="action" color="#dc3545"></FontAwesomeIcon>
                                    </span>
                                }
                              </td>
                              : ''
                            }
                            {item !== 'incidencia' ? pdf : null}
                          </tr>
                        )
                      })

                      return (
                        <tbody key={index}>
                          {piezasRows}
                        </tbody>
                      )
                    }) 
                  }
                </table>
              </div>
            </form>

          <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={page} 
            onChange={onPageChange} 
          />
        </div>
    );
}