import axios from 'axios';
import { refresh } from './auth';
import config from '../config';

const API = axios.create({
  baseURL: config.recomotor.crmApiUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': config.app.originUrl
  },
});

API.interceptors.request.use(
  (config) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser && currentUser.token) {
      config.headers.authorization =  `Bearer ${currentUser.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Logout user if token refresh didn't work or user is disabled
  if (error.config.url === '/refresh' || error.response.message === 'Usuario inactivo.') {
    localStorage.setItem('user', null);
    window.location.href="/";

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Try request again with new token
  return refresh({email: currentUser.email})
    .then((tokenResponse) => {
      currentUser.token = tokenResponse.data.access_token;
      localStorage.setItem('user', JSON.stringify(currentUser)); 
      // New request with new token
      const config = error.config;
      axios.defaults.headers.common.authorization = `Bearer ${tokenResponse.data.access_token}`;
      config.headers.authorization = `Bearer ${tokenResponse.data.access_token}`;

      return new Promise((resolve, reject) => {
        API.request(config).then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });
  })
  .catch((error) => {
    Promise.reject(error);
  });
});

export default API;