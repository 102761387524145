import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer} from 'react-toastify';
import { fetchReadOnlyData } from "../../redux/readOnly";

import Home from './components/Home';
import Navbar from "./components/Navbar";
import Users from './components/Users';
import UserDetail from "./components/Users/components/UserDetail";
import Clientes from './components/Clientes';
import Proveedores from './components/Proveedores';
import Presupuestos from './components/Presupuestos';
import Ventas from './components/Ventas';
import Devoluciones from './components/Devoluciones';
import Facturas from "./components/Facturas";
import DevolucionContainer from "./components/Devoluciones/components/DevolucionContainer";
import Incidencias from "./components/Incidencias";
import FichaIncidencia from "./components/Incidencias/components/FichaIncidencia";
import ClienteSheet from "./components/Clientes/components/ClienteSheet";
import ProveedorSheet from "./components/Proveedores/components/ProveedorSheet";
import Ajustes from "./components/Ajustes";
import Account from "./components/Account";
import FichaPresupuesto from "./components/Presupuestos/components/FichaPresupuesto";
import Envios from "./components/Envios";
import Comerciales from "./components/Comerciales";
import Finanzas from "./components/Finanzas";
import Loading from "./components/shared/components/Loading";
import Config from "./components/shared/components/Config"
import FacturasDesguaces from "./components/FacturasDesguaces";
import LoadingBackdrop from "./components/shared/components/LoadingBackdrop";
import Vehiculos from "./components/Vehiculos";
import ReportModal from "./components/shared/components/ReportModal";
import DevolucionMercancias from "./components/DevolucionMercancias";
import RecordatoriosClientes from "./components/RecordatoriosClientes";
import TecDoc from './components/TecDoc';

//import Echo from 'laravel-echo';
//import Pusher from 'pusher-js';

const Dashboard = ({onLogout}) => {
    const dispatch = useDispatch();
    const status = useSelector(state => {
        return state.read_only.status
    });
    const loading = useSelector(state => {
        return state.loading
    })
    const user = JSON.parse(localStorage.getItem('user'));
    const [openReport, setOpenReport] = useState(false);

    /*window.Pusher = Pusher;
    window.Echo = new Echo({
        broadcaster: 'reverb',
        key: process.env.REACT_APP_REVERB_APP_KEY,
        wsHost: process.env.REACT_APP_REVERB_HOST,
        wsPort: process.env.REACT_APP_REVERB_PORT ?? 80,
        wssPort: process.env.REACT_APP_REVERB_PORT ?? 443,
        forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? 'https') === 'https',
        enabledTransports: ['ws', 'wss'],
        apiOriginAllow: {
        allowCors: true,
        allowOrigin: "*",
        allowMethods: "GET,POST",
        allowHeaders: "Origin, Content-Type, X-Auth-Token, X-Requested-With, Accept, Authorization, X-CSRF-TOKEN, X-Socket-Id"
        },
        authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                fetch(process.env.REACT_APP_API_BASE + 'broadcasting/auth', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + user.token
                    },
                    body: JSON.stringify({
                        socket_id: socketId,
                        channel_name: channel.name
                    })
                })
                .then(response => response.json())
                .then(data => {
                    callback(false, data);
                })
                .catch((error) => {
                    callback(true, error);
                });
            }
        };
        }
    });*/

    useEffect(() => {
        // MAKE INITIAL LOAD OF READ ONLY DATA ON REDUX STORE
        dispatch(fetchReadOnlyData());
      }, []);

      const handleOpenReportProblem = () => { setOpenReport(true); }
      const handleCloseReportProblem = () => { setOpenReport(false); }

      return (
        <div className="dashboard-container">
            <Navbar 
                onOpenReportProblem={handleOpenReportProblem}
                onLogout={onLogout} />
            <Config />
            { (status.loading === 'pending' || status.loading === 'idle') &&
                <Loading />
            }

            { (status.loading === 'succeed' || status.loading === 'rejected') &&
                <Routes>
                    <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
                    <Route path="/" exact element={<Home />}></Route>

                    <Route path="/ajustes" element={<Ajustes />}></Route>
                    <Route path="/mi-cuenta" element={<Account onLogout={onLogout} />}></Route>

                    <Route path="/clientes/:page" element={<Clientes />}></Route>
                    <Route path="/cliente/ficha/:clienteId" element={<ClienteSheet />}></Route>

                    <Route path="/devoluciones/:page" element={<Devoluciones />}></Route>
                    <Route path="/devolucion/:devolucionId" element={<DevolucionContainer />}></Route>

                    <Route path="/devoluciones-mercancias/:page" element={<DevolucionMercancias />}></Route>
                    
                    <Route path="/envios/:page" element={<Envios />}></Route>

                    <Route path="/finanzas/" element={<Finanzas />}></Route>

                    <Route path="/vehiculos/:page" element={<Vehiculos />}></Route>

                    <Route path="/recordatorios/" element={<RecordatoriosClientes />}></Route>
                    
                    <Route path="/facturas-desguaces/:page" element={<FacturasDesguaces />}></Route>
                    <Route path="/facturas/:page" element={<Facturas />}></Route>
                    
                    <Route path="/presupuestos/:page" element={<Presupuestos />}></Route>
                    <Route path="/presupuesto/:presupuestoId" element={<FichaPresupuesto />}></Route>

                    <Route path="/proveedores/:page" element={<Proveedores />}></Route>
                    <Route path="/proveedor/ficha/:proveedorId" element={<ProveedorSheet />}></Route>
                    
                    <Route path="/comerciales/:page" element={<Comerciales />}></Route>

                    <Route path="/users/:page" element={<Users />}></Route>
                    <Route path="/user/:userId" element={<UserDetail />}></Route>
                    
                    <Route path="/ventas/:page" element={<Ventas />}></Route>

                    <Route path="/incidencias/:page" element={<Incidencias />}></Route>
                    <Route path="/incidencia/:incidenciaId" element={<FichaIncidencia />}></Route>

                    <Route path="/tecdoc/:page" element={<TecDoc />}></Route>
                </Routes>
            }

            { loading && <LoadingBackdrop /> }

            { (openReport) &&
                <ReportModal 
                    state={openReport}
                    onClose={handleCloseReportProblem}
                />
            }

            <ToastContainer
                containerId="dashboard"
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </div> 
    );
}

export default Dashboard