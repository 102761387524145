import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import { aprobarPedidoPieza } from "../../../shared/services/finanzas";
import { useDispatch } from "react-redux";
import { discount } from "../../../shared/services/proveedores";
import ProveedorValeModal from "../../../shared/components/ProveedorValeModal";


export default function PedidosPendientes({
    ventas, 
    onApproval
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentItem, setCurrentItem] = useState(null);
    const [valeActivo, setValeActivo] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenVale, setIsOpenVale] = useState(false);

    useEffect(() => {
        
    }, []);

    const handleCheckConfirm = (pieza, item) =>  {
        setCurrentItem(item);
        handleOpenConfirm();

        if(pieza.proveedor.vales?.length > 0) {
            const activeVale = pieza.proveedor?.vales?.find(f => f.active);

            if(activeVale) {
                setIsOpenVale(true);
                setValeActivo(activeVale);
            } else {
                handleCloseVale();
            }
        }
    }
    
    const handleOpenConfirm = () =>  setIsOpen(true);
    const handleCloseConfirm = () => setIsOpen(false);
    const handleConfirmPedido = async(confirm) => {
        if(confirm) {
            dispatch({type: 'loading/set', payload: true});
            const response = await aprobarPedidoPieza(currentItem.venta_id, currentItem.pieza_id);

            if(response && response.success) {
                dispatch({type: 'loading/set', payload: false});
                toast.success(response.message);
            }
        }

        setIsOpen(false);
        onApproval();
    }

    const handleDiscountVale = async(confirm, importe) => {
        if(confirm) {
            if(importe > 0) {
                const response = await discount({id: valeActivo.id, importe: importe});

                if(response && response.success) {
                    toast.success(response.message);
                }
            }
        }

        handleCloseVale();
    }

    const handleCloseVale = () => {
        setIsOpenVale(false);
        setValeActivo(null);
    }

    const handleGoTo = (venta) => {
        const search = venta.facturas[0].numero;
        window.open('/finanzas', "_blank", "noreferrer");
        dispatch({type: "search/set", payload: {search: search }});
        navigate('/ventas/1', { replace : true});
    }

    return (
        <div className="w-100 mt-3">
            <div className="table-responsive">
                <table className="table">
                <thead>
                    <tr>
                        <th>Nº</th>
                        <th className="text-center">fecha</th>
                        <th>Proveedor</th>
                        <th>Pieza</th>
                        <th className="text-center">€ compra</th>
                        <th className="text-center">€ envío</th>
                        <th className="text-center">resp</th>
                        <th className="text-center" width="10%">acciones</th>
                    </tr>
                </thead>
                    { ventas?.map((venta, index) => {
                        const piezasValues = Object.values(venta.piezas)
                        const piezasRows = piezasValues.map((pieza, i) => {
                            let historico = venta.historico?.filter(h => h.pieza_id === pieza.id);
                            historico = historico[historico.length - 1];

                            const numero = i === 0 ? <td rowSpan={piezasValues.length + 1} width="8%">
                                <span className="text-success fw-bold" style={{cursor: 'pointer'}} onClick={() => handleGoTo(venta)}>
                                    {venta.presupuesto.numero}
                                </span>
                            </td> : null
                            const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="8%" className="text-center">{moment(venta.fecha).format("DD-MM-YYYY")}</td> : null
                            
                        return (
                            <tr key={i}>
                                {numero}
                                {fecha}
                                <td>
                                    <div className="d-flex flex-column">
                                        <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + pieza.proveedor?.id} title="Ficha Proveedor" className="text-green text-uppercase">
                                            {pieza.proveedor?.nombre}
                                        </Link>
                                        <small>{(pieza.proveedor?.telefono_incidencias !== "" && pieza.proveedor?.telefono_incidencias !== null) ? pieza.proveedor?.telefono_incidencias : pieza.proveedor?.telefono}</small>
                                    </div>
                                </td>
                                <td>
                                    <p className="m-0">{pieza?.presupuesto_relationship?.unidades > 1 ? <strong>{'x'+pieza?.presupuesto_relationship?.unidades}</strong> : '' } {pieza.nombre}</p>
                                    { historico ? 
                                        <>
                                            <hr className="my-2" />
                                            <div><small>{historico.tipo_pedido} - {historico.codigo_pedido}</small></div>
                                            <div><small><b>Comentario:</b> {historico.comentario}</small></div>
                                        </> : ''}
                                </td>
                                <td width="10%" className="text-center">{parseFloat(pieza.precio_compra).toFixed(2)}€</td>
                                <td width="10%" className="text-center">
                                    { 
                                        venta?.envios?.find(f => f.piezas?.find(p => p.envio_relationship?.pieza_id === pieza.id) && f.transportista_id === 1) ?
                                        venta?.envios?.find(f => f.piezas?.find(p => p.envio_relationship?.pieza_id === pieza.id) && f.transportista_id === 1).precio + '€'
                                        : '-'
                                    }
                                </td>
                                <td className="text-center text-uppercase">{venta?.created_by?.username}</td>
                                <td className="text-center">
                                    <span title="Confirmar Pedido" style={{cursor: 'pointer'}} onClick={() => handleCheckConfirm(pieza, {venta_id: venta.id, pieza_id: pieza.id})}>
                                        <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#215732"></FontAwesomeIcon>
                                    </span>
                                </td>
                            </tr>
                        )})

                        return (
                            <tbody key={index}>
                                {piezasRows}
                            </tbody>
                        )
                    })}
                </table>

                { isOpen && 
                    <ConfirmModal 
                        onConfirmAction={handleConfirmPedido} 
                        title={'Confirmación de pedido al proveedor'} 
                        description={'¿Estás seguro de confirmar el pedido de esta pieza?'}
                        state={isOpen}
                        onClose={handleCloseConfirm}
                    />
                }

                { (isOpenVale) &&
                    <ProveedorValeModal 
                        vale={valeActivo}
                        state={isOpenVale}
                        onConfirmAction={handleDiscountVale}
                        onClose={handleCloseVale}
                    />
                }
            </div>
        </div>
    )
}