import React, { useEffect } from "react";
import { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchIncidencia } from "../../../../../../../../redux/features/fichaIncidencia";
import ValeDescuentoForm from "./components/ValeDescuentoForm";
import { deleteValeDescuento, enviarValeDescuento, newValeDescuento, updateValeDescuento } from "../../../../../shared/services/incidencias";
import moment from "moment";

export default function ValeDescuentoModal({
    title,  
    state, 
    incidencia,
    onClose,
}) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(state);
    const [currentVale, setCurrentVale] = useState(incidencia?.vale_descuento);

    useEffect(() => {
        setCurrentVale(incidencia?.vale_descuento);
    }, [incidencia]);

    const handleClose = () => {
        setOpen(false);
        setCurrentVale(null);
        onClose();
    };
   
    const handleSubmitValeDescuento = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        let response;

        if(currentVale === null) {
            response = await newValeDescuento(data);
        } else {
            response = await updateValeDescuento(data);
        }

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchIncidencia(incidencia.id))
        }
    }

    const handleDeleteValeDescuento = async() => {
        dispatch({type: 'loading/set', payload: true});
        let response = await deleteValeDescuento(currentVale.id);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchIncidencia(incidencia.id))
        }
    }

    const handleSendValeDescuento = async() => {
        dispatch({type: 'loading/set', payload: true});
        const response = await enviarValeDescuento(currentVale.id);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="info-title"
            aria-describedby="info-description">
            <DialogTitle id="info-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">{title}</p>

                    <span title="cerrar" className="action" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="w-100">
                    { (currentVale) &&
                        <div className="w-100 d-flex align-items-center">
                            <div className="flex-grow-1">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Código</th>
                                                <th className="text-center">Fecha de creación</th>
                                                <th className="text-center">Fecha de caducidad</th>
                                                <th className="text-center">Total</th>
                                                <th className="text-center">Gastado</th>
                                                <th className="text-center">disponible</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className="fw-bold text-green">{currentVale.codigo}</span>
                                                </td>
                                                <td className="text-center">{moment(currentVale.fecha).format('DD-MM-YYYY')}</td>
                                                <td className="text-center">
                                                    <span className="fw-bold">{moment(currentVale.fecha_caducidad).format('DD-MM-YYYY')}</span>
                                                </td>
                                                <td className="text-center">{currentVale.total}€</td>
                                                <td className="text-center">{currentVale.gastado}€</td>
                                                <td className="text-center text-green fw-bold">{currentVale.total - currentVale.gastado}€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <button className="btn btn-add ms-4 mt-3"  onClick={() => handleSendValeDescuento()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faEnvelope} size="1x" className="action" color="#215732" /> 
                                </span>
                                Enviar 
                            </button>
                        </div>
                    }

                    <div className="w-100 mt-2">
                        <ValeDescuentoForm 
                            incidencia={incidencia}
                            valeDescuento={currentVale}
                            onSubmitValeDescuento={handleSubmitValeDescuento}
                            onDeleteValeDescuento={handleDeleteValeDescuento}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}