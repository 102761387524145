import React from "react";
import { useForm } from "react-hook-form";

export default function PresupuestoFileForm({
    onSubmitPresupuesto
}) {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            ecooparts: '',
            rf: '',
            ebay: '',
        }
    });

    const handleSubmitPresupuesto = async (data, e) => {
        e.preventDefault();
        
        const presupuesto = new FormData();
        presupuesto.append('ecooparts', data.ecooparts[0]);
        presupuesto.append('rf', data.rf[0]);
        presupuesto.append('ebay', data.ebay[0]);
        onSubmitPresupuesto(presupuesto);
    }

    /*
    <div className="form-group me-3">
        <label htmlFor="excel">ECOOPARTS</label>
        <input 
            type="file" 
            className="form-control" 
            {...register("ecooparts", { maxLength: 255 })} 
            defaultValue={''} />
    </div>
    */

    return (
        <div className="w-100 d-flex mt-3">
            <div className="col-12 flex-column align-items-end">
                <form className="form align-items-center" onSubmit={handleSubmit(handleSubmitPresupuesto)}>            
                    <div className="d-flex align-items-center">
                        <div className="form-group me-3">
                            <label htmlFor="excel">RECAMBIOFACIL</label>
                            <input 
                                type="file" 
                                className="form-control" 
                                {...register("rf", { maxLength: 255 })} 
                                defaultValue={''} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="excel">EBAY</label>
                            <input 
                                type="file" 
                                className="form-control" 
                                {...register("ebay", { maxLength: 255 })} 
                                defaultValue={''} />
                        </div>
                    </div>

                    <div className="d-flex align-items-center mt-4">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                </div>
               </form>
            </div>
        </div>
    )
}