import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import LightBoxImages from "../../../../../../../../../shared/components/LightBoxImages";

export default function PiezaIntegracionInfo({
    pieza
}) {
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const [currentPieza, setCurrentPieza] = useState(null);
    const [openImage, setOpenImage] = useState(false);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        if(pieza?.integracion_json) {
            const json = JSON.parse(pieza.integracion_json);
            setCurrentPieza(json);
        }
    }, [pieza]);

    const getTipo = (tipo) => { 
        tipo = tipo.includes(' / ') ? tipo.split(' / ')[0] : tipo;
        return tipoPiezas.find(tp => tp.nombre.toLowerCase() ===  tipo.toLowerCase())?.id; 
    }

    const getDescuento = (result) => {
        let descuentos = result.proveedor?.descuentos?.length > 0 ? result.proveedor?.descuentos.sort((a, b) => b.tipo_piezas?.length - a?.tipo_piezas.length) : result.proveedor?.descuentos;
        let descuento = descuentos?.length === 1 ? descuentos[0] : descuentos?.find(f => {
            let isIn = true;

            if(f.tipo_piezas && f.tipo_piezas.length > 0 ) {
                let tipoPieza = getTipo(result.nombre);
                let tipo = tipoPiezas.find(tp => tp.id === parseInt(tipoPieza.id));
                isIn = tipo ? f.tipo_piezas.find(f => f.id === parseInt(tipoPieza.id)) : false;
            }

            if(f.max !== null && f.min !== null) {
                return (parseInt(result.precio) <= f.max && parseInt(result.precio) >= f.min && isIn)
            } else if(f.min !== null && f.max === null) {
                return (parseInt(result.precio) >= f.min && isIn)
            } else if(f.max !== null && f.min === null) {
                return (parseInt(result.precio) <= f.max && isIn)
            } else return null;
        });

        return descuento;
    }

    const listResultImages = () => {
        let imgs = "";

        if(currentPieza.imgs.length > 0) {
            imgs = currentPieza.imgs.map(img => {
                return (
                    <img
                       key={'image' + img} 
                       onClick={() => showImage(img, currentPieza.imgs)} 
                       src={img} 
                       height="150px" 
                       alt={currentPieza.origen + ' ' + currentPieza?.referencia_pieza}
                       style={{cursor: 'pointer', marginRight: '8px'}}
                   />
                )
             })
        }

        return imgs;
    }

    const showImage = (src, images,) => { 
        if(src) {
            let srcS = images.map(img =>  {
                return img
            });
            setImagesSRCs(srcS);
            let index = srcS.findIndex(img => img === src);
            setImageIndex(index);
            setOpenImage(!openImage);
        }
    };

    const getModeloDates = (pieza) => {
        return <div>
            { pieza.anopieza > 0 ? 
                <span> Año pieza: {pieza.anopieza}</span>
                : ''
            }
            { (pieza.modeloinicio > 0) ? <span>{pieza.modeloinicio}</span>
                : (pieza.modeloinicio > 0 && pieza.modelofin > 0) ?
                    <span> - {pieza.modelofin}</span>
                : (pieza.modelofin > 0) ? <span>{pieza.modelofin}</span>
                : ''
            }
        </div>
    }

    const handleCloseImages = () => {
        setOpenImage(false);
        setImagesSRCs(null);
        setImageIndex(null);
    }

    
    if(!currentPieza) {
        return (
            <div className="w-100 d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
                <p className="fw-bold text-danger text-center mx-2">No se ha encontrado información de la pieza.</p>
                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
            </div>
        )
    } else {
        return (            
            <div className="w-100 d-flex flex-column">
                <p className="m-0" style={{fontSize: '110%'}}>
                    <b>{currentPieza?.referencia_pieza ? currentPieza.referencia_pieza : 'NO REF'}</b> - {currentPieza.nombre} {currentPieza.marca} {currentPieza.modelo}
                </p>

                <hr />

                <div className="w-100">
                    <div className="w-100 d-flex">
                        <small className="text-uppercase"> { currentPieza.proveedor.nombre}</small>

                        <div className="d-flex">
                            { currentPieza.proveedor.credito ?
                                <p className="cliente-card-small fw-bold text-uppercase ms-2">A crédito</p>
                                : ''
                            }
                            { currentPieza.proveedor?.credito && currentPieza.proveedor?.integracion ? <p className="cliente-card-small mx-2">|</p> : ''}
                            { currentPieza.proveedor?.integracion ?
                                <p className="cliente-card-small fw-bold text-uppercase">Integrado</p>
                                : ''
                            }
                        </div>
                        
                        <small>
                            <b className="text-uppercase mx-2">(Score: {currentPieza.proveedor.score ? currentPieza.proveedor.score : '-'})</b>
                            { getDescuento(currentPieza)?.descuento ? 
                                (<b className="text-green text-uppercase">descuento {getDescuento(currentPieza)?.descuento}%</b>) 
                                : <b className="text-danger text-uppercase">no hay descuento</b>
                            } 
                        </small>
                    </div>

                    <span>
                        <small>Origen: &nbsp;</small>
                        <small className="text-uppercase fw-bold text-end">{currentPieza.prov}</small>
                    </span>
                </div>

                <hr />

                <div className="w-100">
                    <h6 className="fw-bold">Pieza</h6>

                    <div className="w-100 d-flex flex-column">
                        <div className="flex-grow-1 cliente-card-info mb-2">
                            <label>ID desguace:</label>
                            <p style={{'fontSize' : '100%', "color": "#222"}}>
                                {currentPieza.id_desguace}
                            </p>
                        </div>

                        <div className="flex-grow-1 cliente-card-info mb-2">
                            <label>Estado:</label>
                            <p style={{'fontSize' : '100%', "color": "#222"}}>
                                {currentPieza.ubicacion}
                            </p>
                        </div>

                        <div className="flex-grow-1 cliente-card-info mb-2">
                            <label>Observaciones:</label>
                            <p style={{'fontSize' : '100%', "color": "#222"}}>
                                {currentPieza.notapublica}
                            </p>
                            { getModeloDates(currentPieza) }
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-center mb-2">
                    { (currentPieza.imgs) && 
                    <Collapsible className="" contentInnerClassName="collapsable-content-img" trigger={"Ver imagenes"} triggerWhenOpen="Cerrar" lazyRender={true}>
                        <div className="w-100 d-flex justify-content-start mt-2">
                            { listResultImages()}
                        </div>
                    </Collapsible> }
                </div>

                
                { (openImage) &&
                    <LightBoxImages
                        images={imagesSRCs}
                        startIndex={imageIndex}
                        pieza={currentPieza}
                        onClose={handleCloseImages}
                    />
                }
            </div>
        );
    }
    
}