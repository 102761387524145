import React from 'react';
import { useEffect } from "react";

export default function ResumenPlataformas({
    resumen
}) {

    useEffect(() => {
        
    }, [resumen]);

    return (
        <div className='w-100 mt-5'>
            <h1>Resumen</h1>

            <div className='w-100 table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>plataforma</th>
                            <th className='text-center'>ventas</th>
                            <th className='text-center'>incidencias</th>
                            <th className='text-center'>ratio beneficio</th>
                            <th className='text-center'>ratio incidencias</th>
                            <th className='text-center'>facturación</th>
                            <th className='text-center'>beneficio</th>
                        </tr>
                    </thead>
                    <tbody>
                        { resumen.map(res => {
                            return (
                                <tr key={'resumen-' + res.nombre}>
                                    <td>{res.nombre.toUpperCase()}</td>
                                    <td className='text-center'>{res.num_ventas}</td>
                                    <td className='text-center'>{res.num_incidencias}</td>
                                    <td className='text-center'>{res.ratio_beneficio}%</td>
                                    <td className='text-center'>{res.ratio_incidencia}%</td>
                                    <td className='text-center'>{res.facturacion_total}€</td>
                                    <td className='text-center'>{res.beneficio_total}€</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
