import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import VirtualizedAutocompleteFacturas from "../../../shared/components/AutocompleteFacturas";
import { toast } from "react-toastify";

export default function AssignVentaForm({
    devolucion,
    onSubmitVenta,
}) {
    const { 
        register, 
        formState: { errors }, 
        handleSubmit, 
        setValue, 
        getValues,
        control } = useForm({
            mode: "onChange",
            defaultValues: {
                venta: null,
                venta_id: null
            }
    });
 
    useEffect(() => {
        
    }, []); 

    const handleChangeVenta = (venta) => {
        setValue('venta', venta);
        setValue("venta_id", venta.venta_id);
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();

        if(data.venta) {
            data.id = devolucion.id;
            data.which_crm = data.venta?.num_factura?.includes('F') ? 'crm' : 'vo';
            delete data.venta;

            onSubmitVenta(data);
        } else {
            toast.warning('Tienes que elegir una venta!');
        }
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100 d-flex flex-column">
                        <div className="form-group">
                            <label>Venta</label>
                            <input type="hidden" 
                                {...register("venta_id")} 
                                defaultValue={''} />

                            <VirtualizedAutocompleteFacturas 
                                id={'venta'}
                                control={control}
                                getValues={getValues}
                                labels={['num_factura', 'fecha', 'cliente', 'num_presupuesto']}
                                defaultValue={null}
                                onChange={handleChangeVenta}
                            />
                        </div>
                    </div>

                    <div className="d-flex align-content-center align-self-end">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}