import { useEffect, useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { fetchNotificaciones } from "../../../../redux/features/notificaciones";
import { toast } from "react-toastify";
import { archivarAllNotificaciones, archivarNotificacion } from "../shared/services/notificaciones";
import './notificaciones.scss';
import { useNavigate } from "react-router-dom";
import { enviarWhatsAppRecordatorio } from "../shared/services/whatsapp";
import ConfirmModal from "../shared/components/ConfirmModal";
import { setErrorMessage } from "../shared/helpers/functionalities";
import NotificacionItem from "./components/NotificacionItem";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";

export default function Notificaciones({
  notificaciones,
  onArchived
}) {
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState('notificaciones');
  const [isOpenSendWhatsapp, setIsOpenSendWhatsapp] = useState(false);
  const [currentNotificacion, setCurrentNotificacion] = useState(null);
  const webSocketChannel = `notifications.${user.id}`;
  
  useEffect(() => {
    //connectWebSocket();
    dispatch(fetchNotificaciones());

    const interval = setInterval(() => {
      dispatch(fetchNotificaciones());
    }, 180000);

    return () => {
      clearInterval(interval);
      //window.Echo.leave(webSocketChannel);
    }
  }, []);

  /*const connectWebSocket = () => {
    window.Echo.private(webSocketChannel)
      .listen('.NotificacioneCreated', async (e) => {
          dispatch(fetchNotificaciones());
      });
  }*/

  const handleChangeTab = (e, tab) => setTab(tab);

  const handleArchive = async(notificacion, archived) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await archivarNotificacion(notificacion, archived);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchNotificaciones());
      onArchived();
    }
  }

  const handleArchiveAll = async() => {
    dispatch({type: 'loading/set', payload: true});
    const response = await archivarAllNotificaciones();

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchNotificaciones());
      onArchived();
    }
  }

  const handleGoTo = (item, search) => {
    dispatch({type: "search/set", payload: {search: search }});

    if(item === 'presupuesto') {
      navigate('/presupuestos/1', { replace : true});
    } else if(item === 'venta'){
      navigate('/ventas/1', { replace : true});
    } else if(item === 'devolucion'){
      navigate('/devoluciones/1', { replace : true});
    } else if(item === 'incidencia'){
      navigate('/incidencias/1', { replace : true});
    }
  }

  const handleOpenConfirmSendWhatsapp = (notificacion) => { 
    setIsOpenSendWhatsapp(true); 
    setCurrentNotificacion(notificacion);
  }
  const handleCloseConfirmSendWhatsapp = () => { 
    setIsOpenSendWhatsapp(false); 
    setCurrentNotificacion(null);
  }

  const sendWhatsAapp = async(confirm) => {
    dispatch({type: 'loading/set', payload: true});
    let tipo;

    if(confirm) {
      if(currentNotificacion.mensaje.includes('Comprobante pendiente')) {
        tipo = 'comprobante';
      } else if(currentNotificacion.mensaje.includes('Presupuesto pendiente')) {
        tipo = 'presupuesto';
      }

      const response = await enviarWhatsAppRecordatorio({
        tipo: tipo,
        notificacion_id: currentNotificacion.id,
        presupuesto_id: currentNotificacion.presupuesto_id
      }).catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });
  
      if(response && response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success('WhatsApp enviado al cliente correctamente!')
      }
    }
    
    handleCloseConfirmSendWhatsapp();
  }

  
  return (
    <div className="w-100 d-flex flex-column">
      <div className="w-100 d-flex align-items-center justify-content-start mb-3">
        <button type="button" className="btn btn-info" onClick={() => handleArchiveAll()}>Archivar</button>
      </div>

      <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="Ajustes">
              <Tab label="Notificaciones" value="notificaciones" />
              <Tab label="Archivadas" value="archivadas" />
          </TabList>
          </Box>
          <TabPanel value="notificaciones">
            { notificaciones && notificaciones.notificaciones?.length > 0 ? notificaciones.notificaciones?.map(notificacion => {
              return (
                <NotificacionItem
                  key={'notificacion-activa-'+ notificacion.id} 
                  notificacion={notificacion}
                  archivadas={false}
                  onArchive={handleArchive}
                  onGoTo={handleGoTo}
                  onSendWhatsapp={handleOpenConfirmSendWhatsapp}
                />
              )
            })
            : <p className="text-center mt-3">No hay notificaciones.</p>
            }
          </TabPanel>
          <TabPanel value="archivadas">
            { notificaciones && notificaciones.archivadas?.length > 0 ? notificaciones.archivadas?.map(notificacion => {
              return (
                <NotificacionItem
                  key={'notificacion-archivada-'+ notificacion.id} 
                  notificacion={notificacion}
                  archivadas={true}
                  onArchive={handleArchive}
                  onGoTo={handleGoTo}
                  onSendWhatsapp={handleOpenConfirmSendWhatsapp}
                />
              )
            })
            : <p className="text-center mt-3">No hay notificaciones archivadas.</p>
            }
          </TabPanel>
      </TabContext>
      

      { isOpenSendWhatsapp && 
        <ConfirmModal
            onConfirmAction={sendWhatsAapp} 
            onClose={handleCloseConfirmSendWhatsapp}
            title={'WhatsApp de recordatorio'} 
            description={'Estás seguro de enviar un WhatsApp de recordatorio al cliente?'}
            state={isOpenSendWhatsapp}>
        </ConfirmModal>
      }
    </div>
  );
  
}