import React, { useState } from 'react';

export default function TipoPiezasRanking({
    tipoPiezas
}) {
    const [showDetail, setShowDetail] = useState(false);
    const [currentDetail, setCurrentDetail] = useState(null);

    const handleShowDetail = (detail) => {
        setCurrentDetail(detail);
        setShowDetail(true);
    }

    return (
        <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-3">
            <h1>Ranking 10 Tipo de piezas</h1>
            
            <div className='w-100 d-flex flex-wrap flex-md-nowrap align-items-center mt-3'>
                <div className='col-12 col-md-6 pe-0 pe-md-3'>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th className='text-center'>Incidencias</th>
                                    <th className='text-center'>Ventas</th>
                                    <th className='text-center'>% fallo</th>
                                </tr>
                            </thead>
                            <tbody>
                                { tipoPiezas ? tipoPiezas?.map((dataset, i) => {
                                    return (
                                        <tr key={'dataset-tipo-' + i} style={{cursor: 'pointer'}} onClick={() => handleShowDetail(dataset)}>
                                            <td>{dataset.label}</td>
                                            <td className='text-center'>{dataset.count_incidencias}</td>
                                            <td className='text-center'>{dataset.count_ventas}</td>
                                            <td className='text-center'>{dataset.fallo}%</td>
                                        </tr>
                                    )
                                }) : <tr><td colSpan={4} className='text-center'>No hay datos</td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            
                { (showDetail && currentDetail) &&
                    <div className='col-12 col-md-6 pe-0 px-md-5'>
                        <h1>{currentDetail.label}</h1>
                        
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Proveedor</th>
                                        <th className='text-center'>Incidencias</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { (currentDetail && currentDetail?.proveedores?.length > 0) ? currentDetail?.proveedores?.map((dataset, i) => {
                                        return (
                                            <tr key={'dataset-tipo-detail-' + i}>
                                                <td className='text-uppercase'>{dataset.nombre}</td>
                                                <td className='text-center'>{dataset.count_incidencias}</td>
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={4} className='text-center'>No hay datos</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}