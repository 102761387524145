import { useEffect, useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../../../../../../../../../../../shared/components/InfoModal";

export default function ResultsList({
    clientes,
    onClientSelected
 }) {
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [currentCliente, setCurrentCliente] = useState(null);
    
    useEffect(() => {
        
    }, [clientes]);

    const handleCheckCliente = (cliente) => {
        setCurrentCliente(cliente);
        const {poblacion_facturacion, codigo_postal_facturacion, area_facturacion} = cliente;

        if(poblacion_facturacion && codigo_postal_facturacion && area_facturacion) {
            if(area_facturacion.includes('Baleares')) {
                setShowWarningModal(true);
            } else onClientSelected(cliente)
        } else onClientSelected(cliente)
    }

    const handleSetCliente = async () => { 
        onClientSelected(currentCliente); 
    };

    return (
        <div className="w-100 d-flex flex-column justify-content-center mt-5">
            <div>
                { clientes && clientes?.length >= 2 && clientes.map(cliente => {
                    return (
                        <div className={"card-item cliente-row hover " + (cliente.bloqueado === 1 ? 'disabled' : '')} key={cliente.id} style={{ cursor: 'pointer' }} onClick={() => handleCheckCliente(cliente)}>
                            <FontAwesomeIcon icon={faUserCircle} color="#215732"></FontAwesomeIcon>

                            <div className="cliente-row-info">
                                <div className="d-flex align-items-center justify-content-between">
                                    <p>{cliente.nombre?.replace(/^./, function(str){ return str.toUpperCase(); })}</p>
                                    
                                    <div className="d-flex align-items-center">
                                        <span> {cliente.telefono}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            { showWarningModal && 
                <InfoModal
                    onClose={handleSetCliente} 
                    title={'Atención! Cliente de Baleares'} 
                    content={'Recuerda que el envío es más caro.'}
                    state={showWarningModal}>
                </InfoModal>
            }
        </div>
    );
}