import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { newMensaje, updateMensaje } from "../../../../../shared/services/mensajes";
import { useDispatch } from "react-redux";

export default function MensajeForm({msg, isEdit, onCancel, onActionDone}) {
    const dispatch = useDispatch();
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            id: 0,
            titulo: '',
            descripcion: '',
            tipo: ''
        }
    });

    useEffect(() => {
        reset(msg);
    }, [msg]);

    const handleSubmitMensaje = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: false});
        let response, mensaje = data;

        if(data.img.length > 0) {
            mensaje = new FormData();

            Object.entries(data).forEach((value) => {
                if((value[0] === 'img') && value[1]?.length > 0) {
                    mensaje.append(value[0], value[1][0])
                } else { mensaje.append(value[0], value[1]) }
            });
        }
        
        if(isEdit) response = await updateMensaje(data.id, mensaje);
        else response = await newMensaje(mensaje);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onActionDone();
        }
    }

    const handleCancel = () => onCancel(isEdit ? 'edit' : 'new');
    
    return (
        <div className="w-100 d-flex flex-column">
            { (isEdit) &&
                <div className="w-100 my-3">
                    <label>Imagen:</label>
                    { msg.img !== null ?
                        <img 
                            src={`data:image/jpeg;base64,${msg.img.file}`}
                            alt={msg.img.nombre} 
                            className="mb-4 me-4"
                            width={'200px'}
                            height={'200px'}
                            style={{objectFit: 'cover'}}
                        />
                      : <p>No hay imagen.</p>
                    }
                </div>
            }
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitMensaje)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? msg.id : ''} />

                <input 
                    type="hidden" 
                    {...register("visible")} 
                    defaultValue={isEdit ? msg.visible : true} />

                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="titulo">Título</label>
                        <input 
                            type="text" 
                            className="form-control"
                            {...register("titulo", { required: true })} 
                            defaultValue={isEdit ? msg.titulo : 0} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "El título es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="descripcion">Descripcion</label>
                        <textarea
                            {...register("descripcion")}
                            defaultValue={isEdit ? msg.descripcion : ""}
                        ></textarea>
                    </div>

                    <div className="form-group me-3">
                        <label htmlFor="tipo">Tipo</label>
                        <div className="text-info mb-2">
                            <input
                                {...register("tipo", { required: true })}
                                type="radio"
                                name="tipo"
                                value={"info"}
                                id="tipo"
                            />
                            &nbsp; <b>Información</b>
                        </div>

                        <div className="text-warning mb-2">
                            <input
                                {...register("tipo", { required: true })}
                                type="radio"
                                name="tipo"
                                value={"aviso"}
                                id="tipo"
                            />
                            &nbsp; <b>Aviso</b>
                        </div>

                        <div className="text-danger">
                            <input
                                {...register("tipo", { required: true })}
                                type="radio"
                                name="tipo"
                                value={"alerta"}
                                id="tipo"
                            />
                            &nbsp; <b>Alerta</b>
                        </div>
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "Nombre es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="img">Imagen (opcional)</label>
                        <input 
                            type="file" 
                            className="form-control" 
                            {...register("img", { maxLength: 255 })} 
                            defaultValue={''} />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    <button type="button" className="btn btn-default align-self-end ms-3" onClick={handleCancel}>Cancelar</button>
                </div>
            </form>
        </div>
    )
}