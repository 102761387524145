import { styled, Tooltip, tooltipClasses } from "@mui/material";
import moment from "moment";
import config from '../../../../../config';

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
};

export const setErrorMessage = (error) => {
    if (error.response) {
        return error.response.data.message;
    } else if (error.request) {
        return 'Ups... algo ha ido mal';
    } else {
        return error.message;
    }
}

export const isBoolean = val => 'boolean' === typeof val;

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 450,
      fontSize: 12,
      border: '1px solid #4cb698',
    },
}));

export const isDuplicate = (item, arr) => { 
    return arr.some(el =>  item.nombre.toUpperCase() === el.nombre.toUpperCase() && levenshteinDistance(item.direccion.toUpperCase(), el.direccion.toUpperCase()) === 0);
}

export const validFileExtensions = {image: ['jpg', 'png', 'jpeg', 'webp'], excel: ['xlsx', 'xls'], csv: ['csv'], pdf: ['pdf']};
export const getAllowedExt = (type) => validFileExtensions[type].map((e) => `.${e}`).toString();

export const levenshteinDistance = (str1 = '', str2 = '') => {
    const track = Array(str2.length + 1).fill(null).map(() =>
    Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
       track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
       track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
       for (let i = 1; i <= str1.length; i += 1) {
          const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
          track[j][i] = Math.min(
             track[j][i - 1] + 1, // deletion
             track[j - 1][i] + 1, // insertion
             track[j - 1][i - 1] + indicator, // substitution
          );
       }
    }
    return track[str2.length][str1.length];
 };

 export const getEstado = (transportistaId, codigo) => {
  if(transportistaId === 2) {
    if(codigo === '3') return 'text-green';
    else if(codigo === '91' || codigo === 'R6' || codigo === 'R1' || codigo === 'R2' || codigo === 'R3' || codigo === '1' || codigo === '11'|| codigo === '2') return 'text-info'
    else if (codigo === '4'|| codigo === 'R4' || codigo === 'R5') return 'text-warning'
    else if (codigo === '90'|| codigo === '1000' || codigo === '-1') return 'text-danger'
  } else {
    if(
        // SEUR
        codigo === 'LL003' || codigo === 'LL001' || 
        // MRW
        codigo === '00'
        
      ) return 'text-green';
    else if(
        // SEUR
        codigo === 'SX001' || codigo === 'GO002' || codigo === 'GO003' || 
        codigo === 'LI567' || codigo === 'LC003' || codigo === 'LI558' || codigo === 'LO001' ||
        // MRW
        codigo === '59' ||
        codigo === '47' ||
        codigo === '48'
        // DHL
    ) return 'text-info'
    else if (
      // SEUR
      codigo === 'GI643' || 
      // MRW
      codigo === '08' ||
      codigo === '45'
    ) return 'text-warning'
    else if (
      // SEUR
      codigo === 'GI646'|| codigo === 'GK001' || 
      codigo === '-1'
    ) return 'text-danger'
  }
}

  export const checkDisabledProveedor = (proveedor) => {
    if(proveedor.bloqueado) {
      return true;
    } else {
      if(proveedor?.vacaciones?.length > 0) {
        const currDay = moment();

        let isInVacation = proveedor?.vacaciones.find(vacaciones => {
            const startDate = moment(vacaciones.fecha_inicio);
            const endDate = moment(vacaciones.fecha_fin);

            if(currDay.isBetween(startDate, endDate, 'days', '[]')) {
              return true;
            } else return false;
        });

        return isInVacation ? true : false;
      } else { return false; }
    }
}

export const isOnVacation = (proveedor) => {
    if(proveedor?.vacaciones?.length > 0) {
        const currDay = moment();

        let isInVacation = proveedor?.vacaciones.find(vacaciones => {
            const startDate = moment(vacaciones.fecha_inicio);
            const endDate = moment(vacaciones.fecha_fin);

            if(currDay.isBetween(startDate, endDate, 'days', '[]')) {
              return true;
            } else return false;
        });

        return isInVacation ? true : false;
      } else { return false; }
}

export const getPiezasNoGarantia = () => {
  return [
    61, 226, 230, 233, 234,
    235, 236, 237, 238,
    239, 240, 241, 242,
    243, 244, 245, 246,
    247, 248, 249, 251, 261,
    265, 266, 267, 450
  ];
}

export const getPiezasGarantia2anyos = () => {
  return [
    2, 3, 4, 5, 8, 9, 10,
    11, 14, 15, 16, 18, 19, 
    22, 23, 24, 25, 26, 27, 36, 37,
    52, 62, 65, 69, 70, 71, 72, 73,
    75, 77, 78, 80, 81, 82, 84, 85,
    86, 87, 88, 89, 91, 92, 94, 95,
    96, 97, 98, 99, 100, 101, 102, 103, 104,
    105, 107, 108, 109, 112, 113, 120, 121,
    126, 127, 144, 146, 147, 148, 149, 150,
    151, 155, 157, 158, 159, 160, 163, 164,
    166, 167, 168, 171, 172, 173, 174, 175,
    176, 177, 178, 179, 181, 182, 183, 184,
    185, 187, 189, 193, 195, 200, 202, 203,
    210, 215, 216, 217, 218, 224, 295, 196,
    297, 298, 299, 300, 301, 302, 303, 304,
    305, 306, 307, 308, 309, 310, 311, 312,
    313, 314, 315, 316, 317, 318, 319, 320,
    321, 322, 323, 324, 325, 326, 327, 328,
    329, 330, 331, 332, 333, 334, 335, 336,
    337, 338, 340, 341, 342, 343, 344, 346,
    347, 348, 349, 350, 351, 352, 353, 354,
    355, 356, 357, 359, 360, 361, 362, 375,
    379, 380, 384, 387, 388, 391, 392, 399,
    403, 405, 414, 415, 416, 418, 420, 421,
    422, 423, 424, 425, 426, 427, 428, 429,
    430, 431, 432, 433, 437, 438, 439, 441,
    442, 447, 451, 460, 461, 462, 463, 464,
    465, 466, 471, 472, 473, 474, 476, 477,
    478, 481, 482, 490, 491, 492, 494, 495,
    496, 497, 498, 499, 504, 505, 506, 507,
    509, 510, 511, 512, 1075, 1076, 1078, 1079,
    1080, 1081, 1082, 1083, 1084, 1085, 1086,
    1089, 1090, 1091, 1094, 1096, 1097, 1098, 1099,
    1100, 1101, 1102, 1103, 1104, 1105, 1106, 1107,
    1136, 1137, 1138, 1139, 1140, 1159, 1160, 1161,
    1164, 1166, 1179, 1180, 1181, 1182, 1183, 1184,
    1189, 1191, 1221, 1230, 1234, 1236, 1239, 1240,
    1241, 1243, 1244, 1257, 1258, 1250, 1261, 1264,
    1265, 1266, 1267, 1290, 1292, 1293, 1294, 1295,
    1296, 1297, 1298, 1299, 1300, 1313, 1314, 1315,
    1323, 1324, 1325, 1331, 1343, 1344, 1346, 1347,
    1349, 1351, 1360, 1364, 1365, 1408, 1410, 1418,
    1419, 1420, 1421, 1422, 1423, 1424, 1425, 1426,
    1427, 1428, 1429, 1430, 1431, 1432, 1440, 1441,
    1442, 1455, 1456, 1457, 1458, 1459, 1460, 1461,
    1464, 1465, 1466, 1467, 1468, 1469, 1470, 1472,
    1489, 1497, 1498, 1499, 1500, 1501, 1502, 1505,
    1506, 1507, 1508, 1509, 1510, 1514, 1515, 1516,
    1517, 1518, 1519, 1522, 1523, 1524, 1525, 1526,
    1527, 1528, 1535, 1536, 1566, 1568, 1569, 1570,
    1571, 1572, 1573, 1574, 1575, 1576, 1580, 1581,
    1582, 1583, 1586, 1588, 1589, 1590, 1591, 1593,
    1594, 1595, 1596, 1597, 1598, 1599, 1600, 1601,
    1602, 1603, 1604, 1605, 1609, 1611, 1612, 1613,
    1614, 1615, 1616, 1617, 1618, 1721, 1722, 1723,
    1728, 1729, 1730, 1731, 1733, 1740, 1741, 1742,
    1759, 1761, 1772, 1777, 1778, 1779, 1780, 1784,
    1785, 1786, 1787, 1788, 1789, 1790, 1791, 1792,
    1793, 1794, 1795, 1797, 1798, 1799, 1800, 1801,
    1802, 1803, 1804
  ];
}

export const getGarantia = (tipoPiezaId) => {
  const noGarantia = getPiezasNoGarantia();
  const garantia2Años = getPiezasGarantia2anyos();

  const isNoGarantia = noGarantia.find(f => f === tipoPiezaId);
  const is2AñosGarantia = garantia2Años.find(f => f === tipoPiezaId);

  if(isNoGarantia) return 5;
  if(is2AñosGarantia) return 4;

  return 1;
}

export const checkIfIsReliableProveedor = (proveedor) => { return proveedor.score === null || (proveedor.score !== null && proveedor.score >= 6.5); } 
export const checkIfHasValesProveedor = (proveedor) => { return proveedor?.vales?.length > 0 && proveedor?.vales?.find(v => v.active); }

export const isWithMotor = (piezas) => piezas?.find(p => p.tipo_pieza_id === 406);
export const isWithCasco = (piezas) => piezas?.find(p => p.casco > 0);
export const isWithParagolpes = (piezas) => piezas?.find(p => p.tipo_pieza_id === 94 || p.tipo_pieza_id === 172);
export const isWithFaros = (piezas) => piezas?.find(p => (p.tipo_pieza_id >= 29 && p.tipo_pieza_id <= 35) || (p.tipo_pieza_id >= 1307 && p.tipo_pieza_id <= 1311));
export const isWithTurbo = (piezas) => piezas?.find(p => p.tipo_pieza_id === 443);
export const isWithPuertasAndPortones = (piezas) => piezas?.find(p => (p.tipo_pieza_id >= 146 && p.tipo_pieza_id <= 151) || (p.tipo_pieza_id >= 174 && p.tipo_pieza_id <= 176));
export const isWithComponenteElectronico = (piezas) => piezas?.find(p => (p.tipo_pieza_id >= 233 && p.tipo_pieza_id <= 249) || (p.tipo_pieza_id >= 1192 && p.tipo_pieza_id <= 1214) || (p.tipo_pieza_id >= 265 && p.tipo_pieza_id <= 267) || (p.tipo_pieza_id >= 1411 && p.tipo_pieza_id <= 1417));
export const isWithCremalleraDireccion = (piezas) => piezas?.find(p => p.tipo_pieza_id === 208);
export const isWithCentralita = (piezas) => piezas?.piezas?.find(p => (p.tipo_pieza_id >= 233 && p.tipo_pieza_id <= 249) || (p.tipo_pieza_id >= 1192 && p.tipo_pieza_id <= 1214));
export const isWithChapa = (piezas) =>  piezas?.piezas?.find(p => 
  (p.tipo_pieza_id === 94 || p.tipo_pieza_id === 172) || 
  (p.tipo_pieza_id === 70 || p.tipo_pieza_id === 71) || 
  (p.tipo_pieza_id === 1083 || p.tipo_pieza_id === 1733) ||
  (p.tipo_pieza_id >= 146 && p.tipo_pieza_id <= 151) || 
  (p.tipo_pieza_id >= 174 && p.tipo_pieza_id <= 176) ||
  (p.tipo_pieza_id === 80 || p.tipo_pieza_id === 1173) ||
  (p.tipo_pieza_id === 1514)
);
export const isWithMaximumGuarantee = (piezas) => piezas?.filter(p => p.tipo_garantia_id === 3 || p.tipo_garantia_id === 4);
export const isForeignProvider = (piezas) => piezas?.filter(p => p.proveedor.pais_id !== 1);
export const handleCopyPresupuestoText = (presupuesto, user) => {
  let text = "";

    if(presupuesto.piezas.length > 1) {
      text = `El presupuesto *${presupuesto.numero}*, tiene un precio de *${presupuesto.total_iva}€* con transporte e IVA incluidos.`
      + ` Para ver los detalles del presupuesto *y realizar el pedido*, entra en el siguiente enlace: ${config.recomotor.clientsUrl + 'checkout/' + presupuesto.checkout_id}. `;
    } else {
      if(isWithCasco(presupuesto?.piezas)) {
        text = `El presupuesto *${presupuesto.numero}*, tiene un precio de ${presupuesto.total}€  + ${presupuesto.total_envio}€ de transporte + ${presupuesto.total_casco}€ de casco + IVA = *${presupuesto.total_iva}€ en TOTAL*.`
        + ` Para ver los detalles del presupuesto *y realizar el pedido*, entra en el siguiente enlace: ${config.recomotor.clientsUrl + 'checkout/' + presupuesto.checkout_id}.`;
      } else {
        text = `El presupuesto *${presupuesto.numero}*, tiene un precio de ${presupuesto.total}€  + ${presupuesto.total_envio}€ de transporte + IVA = *${presupuesto.total_iva}€ en TOTAL*.`
        + ` Para ver los detalles del presupuesto *y para realizar el pedido*, entra en el siguiente enlace: ${config.recomotor.clientsUrl + 'checkout/' + presupuesto.checkout_id}.`;
      }
    }

    if(isWithPuertasAndPortones(presupuesto?.piezas)) {
      text += ` Generalmente *las puertas* vendidas por Recomotor *vienen sin accesorios como*: elevalunas, retrovisores, manetas, guarnecidos y cerraduras. Únicamente se vende la chapa, los demás componentes se venden a parte. Si tienes alguna consulta no dudes en preguntar`;
    }

    if(isWithChapa(presupuesto?.piezas)) {
      text += ` Las piezas de chapa, es posible que vengan con desperfectos que no se pueden llegar a apreciar en las imágenes.`;
    }

    if(isWithComponenteElectronico(presupuesto?.piezas)) {
      text += ` Los módulos electrónicos *generalmente se tienen que programar o adaptar* para su correcto funcionamiento, debido a que han estado montados en otro vehículo. Si tienes alguna consulta no dudes en preguntar`
    }

    text += " Por favor, no olvides revisar las condiciones antes de hacer la compra.";

    if(isWithMotor(presupuesto?.piezas)) {
      text += ` En el caso de los motores, recuerda que los accesorios *NO tienen garantía*, se dejan montados por razones de ahorro de tiempo en el desmontaje.`;
    }

    if(isWithCremalleraDireccion(presupuesto?.piezas)) {
      text += ` En el caso de las cremalleras de dirección, recuerda que las bieletas *NO tienen garantía*, se dejan montadas por razones de ahorro de tiempo en el desmontaje.`
    }

    if(isWithCentralita(presupuesto?.piezas)) {
      text += ` En el caso de las centralitas y piezas con componentes eléctricos no podemos ofrecer garantía, ya que una vez las vendemos, solo con montarlas en el vehículo pueden quedar inservibles, ya sea por una programación fallida o cortocircuito en el vehículo del cliente.`;
    }

    if(isWithMaximumGuarantee(presupuesto?.piezas)?.length > 0) {
      if(isWithMaximumGuarantee(presupuesto?.piezas)?.length === 1) {
        text += ` La pieza `;
      } else {
        text += ` Las piezas `;
      }

      isWithMaximumGuarantee(presupuesto?.piezas)?.map(pieza => text += `${pieza.nombre},`)
    
      if(isWithMaximumGuarantee(presupuesto?.piezas)?.length === 1) {
        text += ` tiene una garantía de `;
      } else {
        text += ` tienen una garantía de `;
      }

      if(presupuesto?.piezas.find(p  => p.tipo_garantia_id === 3)) {
        text += '1'

        if(presupuesto?.piezas.find(p  => p.tipo_garantia_id === 4)) {
          text += 'y 2 años.'
        } else {
          text += 'año';
        }
      }

      if(presupuesto?.piezas.find(p  => p.tipo_garantia_id === 4)) {
        text += '2 años.'
      }
    }

    if(isForeignProvider(presupuesto?.piezas).length > 0) {
      text += `Al tratarse de una pieza de un proveedor internacional, el transporte puede demorase por encima de los 3 días laborables.`;
    }

    const recambistaName = capitalize(user.username.split('_')[0]);
    text += ` Le ha atendido *${recambistaName}*.`;

    return text;
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const handleSetDireccion = (direccion, type) => {
  if(type === 'cliente') {
    return {
      direccion_facturacion: direccion.direccion,
      codigo_postal_facturacion: direccion.codigo_postal,
      poblacion_facturacion: direccion.poblacion,
      area_facturacion: direccion.area,
    }
  } else if(type === 'envio-remitente') {
    return {
      remitente_direccion: direccion.direccion,
      remitente_codigo_postal: direccion.codigo_postal,
      remitente_area: direccion.area,
      remitente_poblacion: direccion.poblacion,
    }
  } else if(type === 'envio-destinatario') {
    return {
      destinatario_direccion: direccion.direccion,
      destinatario_codigo_postal: direccion.codigo_postal,
      destinatario_area: direccion.area,
      destinatario_poblacion: direccion.poblacion,
    }
  } else {
    return direccion
  }
}

export const isCanariasCeutaOrMelillaOrBaleares = (postalCode) => {
  return (isCanariasCeutaOrMelilla(postalCode) || (postalCode >= 7001 && postalCode <= 7872)) ? true : false;
}

export const isCanariasCeutaOrMelilla = (postalCode) => {
  return (postalCode >= 51001 && postalCode <= 51080) ||      //CEUTA                             
  (postalCode >= 52001 && postalCode <= 52080 ) ||          // MELILLA
  (                                                         // CANARIAS
    (postalCode >= 35001 && postalCode <= 35660) ||
    (postalCode >= 38001 && postalCode <= 38917)
  )      
  ? true : false;
}

export const isAddressOutOfRange = (postalCode) => {
  if ((postalCode >= '51001' && postalCode <= '51080') || // CEUTA
  (postalCode >= '52001' && postalCode <= '52080') || // MELILLA
  ((postalCode >= '35001' && postalCode <= '35660') || (postalCode >= '38001' && postalCode <= '38917')) || // CANARIAS
  ((postalCode >= '9000-001' && postalCode <= '9980-999')) // ISLAS PORTUGAL
  ) {
    return true
  } else {
    return false
  }
}
