import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import { generateFacturaPDF, regenerateFactura } from '../shared/services/facturas_desguaces';
import Header from '../shared/components/Header'
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { fetchFacturasDesguaces } from "../../../../redux/facturasDesguaces";
import Loading from "../shared/components/Loading";
import ActionsMenu from "../shared/components/ActionsMenu";
import FacturaModal from "./components/FacturaModal";
import { getActions } from "./actions";

export default function FacturasDesguaces() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  let navigate = useNavigate();
  const [currentPage, setPage] = useState(currPage);
  const [openNew, setOpenNew] = useState(false);
  const facturas = useSelector(state => {
    return state.facturas_desguaces.entities
  });
  const facturasStatus = useSelector(state => {
    return state.facturas_desguaces.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchFacturasDesguaces(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    let query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '')
    : null;

    return query;
  }

  const handleReload = () => {
    setPage(1);
    navigate(`/facturas-desguaces/${1}`);
    dispatch(fetchFacturasDesguaces(1, getQuery()));
    handleCloseNew();
  }
  
  const handleSearch = (search, searchData) => {
    setPage(1);
    navigate(`/facturas-desguaces/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/facturas-desguaces/${newPage}`);
    dispatch(fetchFacturasDesguaces(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = facturas.total_items < 15 ? 1 : Math.ceil(facturas.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, factura) => {
    if(action === 'download') {
      generateThePdf(factura)
    } else  if(action === 'regenerate') {
      regenerate(factura)
    }
  }

  const regenerate = async(factura) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await regenerateFactura({
      factura_id: factura.id,
      tipo: factura.bastidor === "" && factura.vehiculo === '' ? 'manual' : 'auto'
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
    }
  }

  const generateThePdf = async (factura) => {
      dispatch({type: 'loading/set', payload: true});
      const pdfResult = await generateFacturaPDF(factura.id);

      if(pdfResult) {
        dispatch({type: 'loading/set', payload: false});
        toast.success('PDF generado correctamente!')
        const url = window.URL.createObjectURL(new Blob([pdfResult]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', factura.numero + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        toast.error('Ups! Algo ha ido mal...')
      }
  }

  const handleOpenNew = () => setOpenNew(true);
  const handleCloseNew = () => setOpenNew(false);

  if(facturasStatus.loading === 'pending' || facturasStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(facturasStatus.loading === 'succeed' || facturasStatus.loading === 'rejected') {
    const pageCount = getPaginationCount();

    return (
      <div className="content pt-3">
        <Header  
          viewSearch={true}
          onSearch={handleSearch}
          onNew={handleOpenNew}
        />

        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th className="text-center">desguace</th>
                  <th className="text-center">fecha</th>
                  <th className="text-center">tipo</th>
                  <th className="text-center">bastidor</th>
                  <th className="text-center">vehiculo</th>
                  <th className="text-center">total</th>
                  <th className="text-center">I.V.A</th>
                  <th className="text-center">total I.V.A</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              <tbody>
                { facturas.facturas?.map((factura) => {
                  return (
                    <tr key={factura.id}>
                      <td>{factura.numero}</td>
                      <td className="text-center text-uppercase fw-bold">{factura.desguace.nombre}</td>
                      <td className="text-center">{moment(factura.fecha).format("DD-MM-YYYY")}</td>
                      <td className="text-uppercase text-center">{factura.bastidor ? 'auto' : 'manual'}</td>
                      <td className="text-center">{factura.bastidor ? factura.bastidor : '-'}</td>
                      <td className="text-center">{factura.vehiculo ? factura.vehiculo : '-'}</td>
                      <td className="text-center">{parseFloat(factura.total).toFixed(2)}€</td>
                      <td className="text-center">{factura.iva}%</td>
                      <td className="text-center">{factura.total_iva}€</td>
                      <td className="text-center">
                        <ActionsMenu 
                            options={getActions()}
                            onAction={(action) => handleAction(action, factura)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>

        { (openNew) && 
          <FacturaModal
              state={openNew}
              onReloadFacturas={handleReload}
              onClose={handleCloseNew}
          ></FacturaModal>
        }
      </div>
    )
  }
}