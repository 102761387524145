export const getActions = (item) => {
    const actions = {
        actions: [
            {
                label: 'Ver información',
                icon: 'details',
                action: 'details'
            },
            {
                label: 'Descargar etiqueta PDF',
                icon: 'pdf',
                action: 'download'
            },
        
        ],
        extra: []
    };

    if((item.transportista_id >= 2 && (item.numero_seguimiento === "" || item.numero_seguimiento === null))) {
        actions.actions.push({
            label: 'Regenerar envío',
            icon: 'regenerate',
            action: 'regenerate'
        })
    }

    if((item.transportista_id !== 1 && !item.cancelado)) {
        actions.actions.push({
            label: 'Cancelar envío',
            icon: 'cancel',
            action: 'cancel'
        })
    }

    if(item.transportista_id === 1) {
        actions.actions.push({
            label: 'Añadir seguimiento',
            icon: 'add',
            action: 'add-info'
        })
    }

    return actions;
}