import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export default function SubestadoForm({
    incidencia,
    onSubmitSubestado
}) {
    const subEstados = useSelector(state => {
        const { subestados_incidencia } = state;
        return subestados_incidencia;
    });
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            id: incidencia.id,
            subestado_incidencia_id: incidencia.subestado_incidencia_id
        }
    });

    useEffect(() => {
        
    }, [incidencia]);

    const handleChangeSubestadoIncidencia = (e) => { setValue('subestado_incidencia_id', parseInt(e.target.value)); } 

    const onSubmit = async (data, e) => {
        e.preventDefault();
        data.subestado_incidencia_id = data.subestado_incidencia_id != -1 ? parseInt(data.subestado_incidencia_id) : null;
        onSubmitSubestado(data);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div className="w-100">
                <form className="form d-flex flex-column align-items-end" onSubmit={handleSubmit(onSubmit)}>
                    <input 
                        type="hidden" 
                        {...register("id")} 
                        defaultValue={incidencia.id} />

                    <div className="form-group">
                        <label>Sub estado</label>
                        <select 
                            {...register("subestado_incidencia_id", {required: true})}
                            defaultValue={incidencia.subestado_incidencia_id}
                            onChange={handleChangeSubestadoIncidencia}>
                            <option key={'subestado-incidencias-0'} value={-1}>Ninguno</option>
                            {subEstados.map(op => <option key={'subestado-' + op.id} value={op.id}>{op.nombre}</option>)}
                        </select>
                    </div>

                    <div className="d-flex align-items-center align-self-end">
                        <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}