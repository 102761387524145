import { useEffect, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import VirtualizedAutocompletePersonas from "../../../shared/components/AutocompletePersonas";

export default function FusionForm({
    cliente,
    onSubmitFusion
}) {
    const columnas = ['nombre', 'cif', 'telefono', 'email', 'direccion_facturacion', 'poblacion_facturacion'];
    const [columnasStatus, setColumnasStatus] = useState(new Array(columnas.length).fill(false) );
    const { register, formState: { errors }, handleSubmit, setValue, control } = useForm({
        mode: "onChange",
        defaultValues: {
            from: null,
            to: cliente.id,
            fusion_columnas: false,
            columnas: [],
        }
    });
    const [clienteFusion, setClienteFusion] = useState(null);

    useEffect(() => {
    }, []);

    const handleChangeCliente = (cliente) => {
        if(cliente !== "" || cliente !== undefined || cliente !== null) {
            setClienteFusion(cliente);
            setValue('cliente', cliente);
            setValue('from', cliente.id);
        } else setClienteFusion(null);
    }

    const handleChangeColumna = (position) => {
        const updatedCheckedState = columnasStatus.map((item, index) =>
          index === position ? !item : item
        );
    
        setColumnasStatus(updatedCheckedState);
    };
    

    const handleSubmitFusion = (data, e) => {
        e.preventDefault();

        if(columnasStatus.find(s => s === true)) {
            data.fusion_columnas = true;
            data.columnas = columnasStatus.map((column, i) => column === true ? columnas[i] : null)
                .filter(c => c !== null);
        }
        
        onSubmitFusion(data);
    }

    const getInfoCliente = (cliente, item) => {
        if(cliente) {
            return (
                <div className="w-100 d-flex flex-column">
                    <table className="table align-middle">
                        <tbody>
                            { columnas.map((columna, i) => {
                                let info = "";

                                if((columna !== 'poblacion_facturacion')) {
                                    info =   <tr key={'columna-' + i}>
                                                    { (item === 'origen') &&
                                                        <td>
                                                            <div>
                                                                <input 
                                                                    type="checkbox"
                                                                    id={`columnas-${i}`}
                                                                    name={columna}
                                                                    value={columna}
                                                                    checked={columnasStatus[i]}
                                                                    onChange={() => handleChangeColumna(i)}
                                                                />
                                                            </div>
                                                        </td>
                                                    }
                                                    <td><label>{columna}</label></td>
                                                    <td><p className="m-0">{cliente[columna]}</p></td>
                                                </tr>
                                } else {
                                    info =  <tr key={'columna-' + i}>
                                            { (item === 'origen') && 
                                            <td>
                                                <div>
                                                    <input 
                                                        type="checkbox"
                                                        id={`columnas-${i}`}
                                                        name={columna}
                                                        value={columna}
                                                        checked={columnasStatus[i]}
                                                        onChange={() => handleChangeColumna(i)}
                                                    />
                                                </div>
                                            </td>
                                            }
                                        <td><label>{columna}</label></td>
                                        <td>
                                            { cliente[columna] ?
                                                <p className="m-0">
                                                    {cliente[columna].codigo_postal} {cliente[columna].poblacion}, {cliente.pais_facturacion.nombre}
                                                </p>
                                                : <p className="m-0">-</p>
                                            }
                                        </td>
                                    </tr>
                                }
                                return info;
                            }) }
                        </tbody>
                    </table>
                </div>
            )
        } else return null;
    }
   
    return (
        <div className="w-100 d-flex">
            <form className="form w-100" onSubmit={handleSubmit(handleSubmitFusion)}>
                <input 
                    type="hidden" 
                    {...register("from")} 
                    defaultValue={cliente.id} />

                <input 
                    type="hidden" 
                    {...register("to")} 
                    defaultValue={''} />

                <div className="w-100 d-flex justify-content-end">
                    <div className="col-12 col-md-6  d-flex flex-column justify-content-between">
                        <h1>Cliente destino</h1>
                        {getInfoCliente(cliente, 'destino')}
                    </div>
                    
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
                        <div className="w-100">
                            <h1>Cliente origen (a fusionar)</h1>
                            <small className="d-block mb-3">
                            
                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
                                    <b>Este cliente se va a eliminar permanentemente.</b>
                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action" color="#dc3545" />
                            </small>
                        </div>
                        
                        {getInfoCliente(clienteFusion, 'origen')}
                    </div>
                </div>

                

                <div className="w-100 mt-5">
                    <div className="form-group">
                        <label>Cliente a fusionar:</label>
                        <VirtualizedAutocompletePersonas 
                            id={"cliente"}
                            control={control}
                            item={'cliente'}
                            labels={['nombre', 'telefono', 'cif']}
                            defaultValue={null}
                            onChange={handleChangeCliente}
                        />
                    </div>
                </div>

                <button className="btn btn-primary align-self-end">GUARDAR</button>
            </form>
        </div>
           
    );
}