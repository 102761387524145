import React from "react";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { enviarNotificacionRecambista } from "../../../shared/services/presupuestos";
import { toast } from "react-toastify";

export default function ValidacionModal({ 
    presupuestoId,
    state,
    onConfirmAction,
    onClose
}) {
    const [open, setOpen] = useState(state);
    const [motivo, setMotivo] = useState("");

    const handleConfirm = async(confirm) => {
        setOpen(false);

        if(!confirm) {
            const response = await enviarNotificacionRecambista(presupuestoId, {validacion: motivo});
            toast.success(response.message);
        }

        onConfirmAction(confirm);
    };

    const handleClose = () => { 
        setOpen(false); 
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description">
            <DialogTitle id="confirm-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">Validar presupuesto</p>

                    <span title="cerrar" className="action" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-description">
                    <p>Validas el presupuesto?</p>
                </DialogContentText>

                <div className="form-group mt-3">
                     <label htmlFor="descripcion">Motivo (opcional, si no se valida el presupuesto)</label>
                    <textarea
                        name="motivo"
                        id="motivo"
                        defaultValue={motivo}
                        onChange={(e) => setMotivo(e.target.value)}
                    ></textarea>
                </div>
            </DialogContent>
            <DialogActions>
                <button type="button" className="btn btn-primary" disabled={motivo === ""} onClick={() => handleConfirm(true)}>Si</button>
                <button type="button" className="btn btn-default" disabled={motivo === ""} onClick={() => handleConfirm(false)}>No</button>
            </DialogActions>
        </Dialog>
    );
}