import API from '../../../../../services/api';

export const getVentas = async (archivado = false, page = null, search = null) => {
    const archived = '?archivado=' + archivado;
    const pagination = page !== null ? '&page=' + page : '';
    const searcher = search !== null ? '&' + search : '';
    return await API.get('/ventas' + archived + pagination + searcher).then(res => res.data);
}

export const getVenta = async (idVenta) => {
    return API.get('/ventas/' + idVenta).then(res => res.data);
}

export const getVentaCompleta = async (idVenta) => {
    return API.get('/ventas/' + idVenta + '/completa').then(res => res.data);
}

export const getVentaConSeguimientoCompleto = async (idVenta) => {
    return API.get('/ventas/' + idVenta + '/full_completa').then(res => res.data);
}

export const newVenta = async (venta) => {
    return API.post('/ventas', venta).then(res => res.data);
}

export const replaceVenta = async (venta) => {
    return API.post('/ventas/replace', venta).then(res => res.data);
}

export const uploadProviderInvoice = async (venta, ventaId) => {
    return API.post('/ventas/' + ventaId + '/upload', venta).then(res => res.data);
}

export const downloadProviderInvoice = async (filename) => {
    return API.get('/ventas/' + filename + '/download', {responseType: 'arraybuffer'}).then(res => res.data);
}

export const enviarEmailFormularioIncidencia = async (ventaId) => {
    return API.post('/ventas/' + ventaId + '/formulario-incidencia').then(res => res.data);
}

export const updateVenta = async (venta) => {
    return API.put('/ventas/' + venta.id, venta).then(res => res.data);
}

export const deleteVenta = async (idVenta) => {
    return API.delete('/ventas/' + idVenta).then(res => res.data);
}