import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function ReportForm({
    report,
    onSubmitReport
}) {
    const recambistas = useSelector(state => {
        return state.recambistas
    });
    const reportSchema = yup.object({
        user_id: yup.number().integer().moreThan(0).required(),
    }).required();

    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(reportSchema),
        defaultValues: {
            puntos_debiles: '',
            mejoras: '',
            user_id: -1
        }
    });

    useEffect(() => {
        if(report) {
            reset(report);
        }
    }, [report]);

    const handleChangeRecambista = (e) => { 
        const value = parseInt(e.target.value);
        setValue('user_id', value);
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();
        onSubmitReport(data);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div className="w-100">
                <form className="form d-flex flex-column align-items-end" onSubmit={handleSubmit(onSubmit)}>
                    { (!report) ?
                        <div className="form-group">
                            <label>Recambista</label>
                                <select 
                                defaultValue={-1}
                                {...register("user_id", {required: true})}
                                onChange={handleChangeRecambista}>
                                <option key={'recambista-' + 0} value={-1}>Selecciona un recambista</option>
                                {recambistas.map(op => <option key={'recambista-' + op.id} value={op.id}>{op.username}</option>)}
                            </select>
                            <div className="form-invalid">
                                {errors.user_id?.message && 'Selecciona un recambista.'}
                            </div>
                        </div>
                        :
                        <>
                            <input 
                                type="hidden" 
                                {...register("id")} 
                                defaultValue={report.id} />

                            <input 
                                type="hidden" 
                                {...register("user_id")} 
                                defaultValue={report.user.id} />
                        </>
                    }

                    <div className="form-group">
                        <label htmlFor="puntos_debiles">Puntos débiles</label>
                        <textarea
                            {...register("puntos_debiles")}
                            defaultValue={report ? report.puntos_debiles : ""}
                        ></textarea>
                    </div>

                    <div className="form-group">
                        <label htmlFor="mejoras">Mejoras</label>
                        <textarea
                            {...register("mejoras")}
                            defaultValue={report ? report.mejoras : ""}
                        ></textarea>
                    </div>
                    

                    <div className="d-flex align-items-center align-self-end">
                        <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}