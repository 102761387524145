import React from 'react';
import ImagesSlider from "../ImagesSlider";
import './partCard.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faUmbrellaBeach} from "@fortawesome/free-solid-svg-icons";
import { checkDisabledProveedor, isOnVacation } from '../../../../../../../../../../../../../shared/helpers/functionalities';

export default function PartCard ({
    part,
    onSelectedPart,
    onShowMoreInfo
}) {
    const formatPrice = (price) => {
        if (price === undefined) {
            return '-';
        }

        if (typeof price === 'number') {
            price = price.toString();
        }

        price = price.replace(',', '.');
        price = parseFloat(price).toFixed(2);

        return price === 'NaN' ? '-' : price;
    }

    const getValueIfExists = (value) => value ? value : '-';

    const handleSelectedPart = () => onSelectedPart(part);

    const showMoreInfo = () => onShowMoreInfo(part);

    return (
        <div className={`mb-4 rounded part-card ${part.isFromIntegration ? 'green-part-card' : 'grey-part-card'}`} onClick={showMoreInfo}>
            <div className='d-flex row'>
                <div className='col-5'>
                    <div className='mx-2'>
                        <ImagesSlider images={part.images} />
                    </div>
                </div>
                <div className='col-7 mt-4'>
                    <div className='mx-2'>
                        <div className={'part-card__info'}>
                            { part.presupuestos_integraciones?.length > 0 ?
                                <small className="text-uppercase fw-bold mb-2">
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                                    &nbsp; Pieza añadida en otro presupuesto &nbsp;
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                                </small>
                                : ''
                            }
                            <h3 className='text-success mb-2'>{ getValueIfExists(part.pieza) }</h3>
                            <p className='mb-3'>{ getValueIfExists(part['Versión']) }</p>
                            <div className='mb-3'>
                                <p className='my-4 text-success'>
                                    <span className='bg-white rounded px-4 py-2 me-2'>
                                        Tipo: <b>{ getValueIfExists(part.type) }</b>
                                    </span>
                                            <span className='bg-white rounded px-4 py-2'>
                                        ID: <b>{ getValueIfExists(part.ref_vendedor) }</b>
                                    </span>
                                </p>
                                <div>
                                    <p className='mt-1'>{ getValueIfExists(part.observaciones) }</p>
                                    <p className='mt-1'><b>{ getValueIfExists(part.vendedor) }</b></p>
                                    <p className='mt-1'>{ getValueIfExists(part.marketPlace) }</p>
                                    { part.isFromIntegration && part?.descuento ?
                                        (<b className="text-green text-uppercase">descuento {part.descuento}%</b>)
                                        : <b className="text-danger text-uppercase">no hay descuento</b>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mb-1'>
                            <div>
                                <h3 className='text-success'>
                                    { parseInt(part.precio) === 0 ? 'A consultar' : formatPrice(part.precio) + ' €'}
                                </h3>
                            </div>

                            <div className="d-flex align-items-center">
                                { (isOnVacation(part.vendedor)) &&
                                    <FontAwesomeIcon icon={faUmbrellaBeach} size="1x" className="me-2" color="#dc3545" />
                                }
                                    
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    disabled={part?.proveedor ? checkDisabledProveedor(part.proveedor) : false}
                                    onClick={handleSelectedPart}>
                                    Seleccionar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}