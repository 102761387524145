import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import './styles/main.scss';
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import useToken from './useToken';
import { logout } from "./services/auth";

function App() {
  const navigate = useNavigate();
  const { token, setToken } = useToken();

  const handleLogout = () => {
    const logoutUser = async () => {
      const user = JSON.parse(localStorage.getItem('user'));
      const logoutResult = await logout({email: user.email});

      if(logoutResult.data) {
        setToken(null);
        localStorage.clear();
        navigate('/login', { replace: true });
      }
    }
    logoutUser();
  }

  return (
    <Routes>
      <Route path="/" element={
          !token ? 
            <Login setToken={setToken} /> 
          : <Dashboard onLogout={handleLogout} />
        }
      ></Route>

      <Route path="/login" element={
          !token ? 
            <Login setToken={setToken} /> 
          : <Dashboard onLogout={handleLogout} />
        }
      ></Route>

      <Route path='*' exact={true} element={
        !token ? 
            <Login setToken={setToken} /> 
          : <Dashboard onLogout={handleLogout} />
      } />
      <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
    </Routes>
  );
}

export default App;
