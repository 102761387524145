import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFinanzas, fetchPedidosPendientes, fetchTransferenciasPorAprobar } from "../../../../redux/features/finanzas";
import Header from "../shared/components/Header";
import Loading from "../shared/components/Loading";
import PedidosPendientes from "./components/PedidosPendientes";
import Transferencias from "./components/Transferencias";


export default function Finanzas() {
    const dispatch = useDispatch();
    const finanzas = useSelector(state => {
        return state.finanzas.entity
    });
    const finanzasStatus = useSelector(state => {
        return state.finanzas.status
    });

    useEffect(() => {
        dispatch(fetchFinanzas());
    }, []);

    const handleApprovalTransferencia = () => { dispatch(fetchTransferenciasPorAprobar()); }

    const handleApprovalPedido = () => { dispatch(fetchPedidosPendientes()); }

    if(finanzasStatus.loading === 'pending' || finanzasStatus.loading === 'idle') {
        return ( <Loading /> );
    }
    
    if(finanzasStatus.loading === 'succeed' || finanzasStatus.loading === 'rejected') {
        return (
            <div className="content pt-3">
                <Header viewSearch={false} />

                <div className="w-100 d-flex flex-column align-items-end content-table">
                    <div className='w-100 mb-4'>
                        <h6 className="fw-bold">Transferencias pendientes de aprobación</h6>

                        <div className='w-100 mt-3'>
                            { finanzas?.comprobantes?.length > 0 ?
                                <Transferencias 
                                    presupuestos={finanzas?.comprobantes}
                                    onApproval={handleApprovalTransferencia}
                                />

                                : <small>No hay transferencias para aprobar.</small>
                            }
                        </div>
                    </div>

                    <hr className="w-100 d-block" />
                    
                    <div className='w-100 mb-4'>
                        <h6 className="fw-bold">Pedidos pendientes</h6>

                        <div className='w-100 mt-3'>
                            { finanzas?.pedidos?.length > 0 ?
                                <PedidosPendientes 
                                    ventas={finanzas?.pedidos}
                                    onApproval={handleApprovalPedido}
                                />

                                : <small>No hay pedidos pendientes.</small>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}