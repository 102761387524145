import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../../../shared/components/InfoModal";
import ReportForm from "./components/ReportForm";
import { nuevoReport, updateReport } from "../../../shared/services/recambistas_reports";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function RecambistasReports({
    reports,
    onReload
}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const recambistas = useSelector(state => {
        return state.recambistas
    });
    const [open, setOpen] = useState(false);
    const [currentReport, setCurrentReport] = useState(null);

    useEffect(() => {
        
    }, []);
    
    const handleOpen = (report = null) => {
        setCurrentReport(report);
        setOpen(true);
    }
    const handleClose = () => {
        setCurrentReport(null);
        setOpen(false);
    }

    const handleReport = async(data) => {
        let response;

        if(currentReport) {
            response = await updateReport(data);
        } else {
            response = await nuevoReport(data);
        }

        if(response && response.success) {
            toast.success(response.message);
            onReload();
        }
    }

    const areNewRecambistas = () => {
        return recambistas.filter(r => reports.find(f => f.user.id === r.id) ? false : true)?.length === 0 ? false : true;
    }

    return (
        <div className="w-100 d-flex flex-column align-items-end">
            <div className="w-100 d-flex justify-content-end">
                { (areNewRecambistas()) &&
                    <button className="btn btn-add" onClick={() => handleOpen()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                        </span>
                        Nuevo
                    </button>
                }
            </div>

            <div className="table-responsive mt-4">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Recambista</th>
                            <th>Puntos débiles</th>
                            <th>Mejoras</th>
                            {(user.rol_id === 5 || user.rol_id === 1)&& <th className="text-center">acciones</th>}
                        </tr>
                    </thead>
                    <tbody>
                        { reports?.map((report) => {
                            return (
                                <tr>
                                    <td width={'8%'} className="text-uppercase">{report.user.username}</td>
                                    <td>{report.puntos_debiles ? report.puntos_debiles : '-'}</td>
                                    <td>{report.mejoras ? report.mejoras : '-'}</td>
                                    { (user.rol_id === 5 || user.rol_id === 1) &&
                                        <td width={'5%'} className="text-center">
                                            <span title="Editar" style={{cursor: 'pointer'}}>
                                                <FontAwesomeIcon icon={faEdit} size="1x" className="action" color="#215732" onClick={() => handleOpen(report)}></FontAwesomeIcon>
                                            </span>
                                        </td>
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            { open && 
                <InfoModal
                    state={open}
                    title={currentReport ? ('Editar report recambista ' + currentReport.user.username) : 'Nuevo report'}
                    content={
                        <ReportForm 
                            report={currentReport}
                            onSubmitReport={handleReport}
                        />
                    }
                    width={'md'}
                    onClose={handleClose}
                ></InfoModal>
            }
        </div>
    )
}