import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const seachPiezas = async (search) => {
    return await API.get('/piezas?search=' + search).then(res => res.data);
}

export const getMarcasIntegraciones = async () => {
  return await API.get('/integracion/makers').then((res)=> {
    return res.data.data
  });
}

export const getModelosIntegraciones = async (marca_id) => {
  return await API.get(`/integracion/modelos?marca_id=${marca_id}`).then((res)=> {
    return res.data.data
  });
}

export const getTipoPiezasIntegraciones = async () => {
  return await API.get(`/integracion/nombres`).then((res)=> {
    return res.data.data
  });
}

export const getPiezasIntegraciones = async (search, page = null) => {
    const pagination = page !== null ? '&page=' + page : '';
    let query = Object.keys(search).filter((key) => search[key] !== null && search[key] !== "" && search[key] !== 0 && search[key] !== "0")
      .map((key, i) => { return i === 0 ? '?' + key + '=' + search[key] : '&' + key + '=' + search[key]; })
    query = query.join("");

    return await API.get(`/integracion/stock` + query + pagination).then((res)=> {
      return res.data
    });
}

export const getPiezaIntegracionDetail = async (detail) => {
  let query = '?id_original=' + detail.id_original + '&proveedor_id=' + 
    detail.proveedor_id + '&referencia_pieza=' + detail.referencia_pieza;
  return await API.get(`/integracion/detail` + query).then((res)=> {
    return res.data
  });
}

export const enviarSolicitudPieza = async (imports) => {
    return API.post('/integracion/solicitud_pieza', imports).then(res => res.data);
}

export const getPiezasList = async (filters) => {
    return API.post('/piezas-export', filters, {responseType: 'blob'}).then(res => res.data);
}

export const getMotoresYCajasLaSelvaCSV = async (imports) => {
    return API.post('/integracion/la-selva', imports, {responseType: 'blob'}).then(res => res.data);
}

export const getPieza = async (idPieza) => {
    return API.get('/piezas/' + idPieza).then(res => res.data);
}

export const newPieza = async (pieza) => {
    return API.post('/piezas', pieza, pieza instanceof FormData ? headers : null).then(res => res.data);
}

export const updatePieza = async (pieza) => {
    return API.post('/piezas/' + pieza.id, pieza, pieza instanceof FormData ? headers : null).then(res => res.data);
}

export const updatePiezaPagoProveedor = async (piezaId, pieza) => {
  return API.put('/piezas/' + piezaId + '/pago-proveedor', pieza).then(res => res.data);
}

export const deletePieza = async (idPieza) => {
    return API.delete('/piezas/' + idPieza).then(res => res.data);
}