import { useState, useEffect } from "react";
import React from "react";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardVehiculo, fetchDashboardVehiculos } from "../../../../redux/features/vehiculos";
import Vehiculo from "./components/Vehiculo";
import { Pagination } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../shared/components/Header";

export default function Vehiculos() {
    const dispatch = useDispatch();
    const { page } = useParams();
    const currPage = parseInt(page);
    let navigate = useNavigate();
    const [currentPage, setPage] = useState(currPage);
    const [expanded, setExpanded] = useState(false);
    const vehiculos = useSelector(state => {
        return state.vehiculos.entities
    });
    const status = useSelector(state => {
        return state.vehiculos.status
    });
    const searcher = useSelector(state => {
        return state.search
    })

    useEffect(() => {
        dispatch(fetchDashboardVehiculos(currPage, getQuery()));
    }, [searcher]);

    const getQuery = () => {
        const isEmpty = Object.values(searcher).every(x => x === null || x === '');
        let query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '')
        : null;
    
        return query;
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        if(isExpanded && !isLoaded(panel)) {
            dispatch(fetchDashboardVehiculo(panel));
        }
    };

    const isLoaded = (panel) =>  {
        const vehiculo = vehiculos.vehiculos.find(f => f.bastidor === panel);
        return vehiculo.hasOwnProperty('piezas');
    }

    const handleSearch = (search, searchData) => {
        setPage(1);
        navigate(`/vehiculos/${1}`);
        dispatch({type: "search/set", payload: searchData});     
    }
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        navigate(`/vehiculos/${newPage}`);
        dispatch(fetchDashboardVehiculos(newPage, getQuery()));
    }

    const getPaginationCount = () => {
        var totalPages = vehiculos.total_items < 15 ? 1 : Math.ceil(vehiculos.total_items / 15);
        return parseInt(totalPages);
    }

    if(status.loading === 'pending' || status.loading === 'idle') {
        return (
        <div className="content">
            <div className="w-100 h-100 d-flex align-items-center justify-content-center loading">
                <FontAwesomeIcon icon={faSpinner} size="2x" spin /> <span>Cargando</span>
            </div>
        </div>
        )
    }

    if(status.loading === 'succeed' || status.loading === 'rejected') {
        const pageCount = getPaginationCount()

        return (
            <div className="content align-items-end pt-3">
                <div className="w-100 d-flex align-items-center mb-4">
                    <Header 
                        viewSearch={true} 
                        onSearch={handleSearch}
                    ></Header>
                </div>

                <div className="w-100">
                    { vehiculos.vehiculos?.length > 0 ? 
                        vehiculos.vehiculos?.map((vehiculo) => {
                            return (
                                <Vehiculo 
                                    key={'vehiculo-' + vehiculo.bastidor}
                                    expanded={expanded}
                                    vehiculo={vehiculo}
                                    onChange={handleChange}
                                />
                            )
                        })
                        : <p>No hay vehiculos</p>
                    }
                </div>

                <Pagination 
                    className="mt-3" 
                    count={pageCount} 
                    page={currentPage} 
                    onChange={handleChangePage} 
                />
            </div>
        )
    }
}