import { useState, useEffect } from "react";
import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ChangePassword from "./components/ChangePassword";
import UserInfo from "./components/UserInfo";

export default function Account({onLogout}) {
    const [tab, setTab] = useState('current-fact');

    useEffect(() => {
        
    }, []);

    const handleChangeTab = (e, tab) => setTab(tab);

    const handleReset = () => { onLogout(); }
  
    return (
        <div className="content ajustes">
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="Mi Cuenta">
                    <Tab label="Mi facturación" value="current-fact" />
                    <Tab label="Cambio de contraseña" value="password" />
                </TabList>
                </Box>
                <TabPanel value="current-fact">
                    <UserInfo />
                </TabPanel>
                <TabPanel value="password">
                    <ChangePassword onResetPassword={handleReset} />
                </TabPanel>
            </TabContext>
        </div>
    )
}