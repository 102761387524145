import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchIncidenciasMetrics } from '../../../../../../../../redux/features/dashboard';
import '../../../Dashboard.scss';
import DateSelector from '../../../../../shared/components/DateSelector';
import IncidenciasMetrics from './components/IncidenciasMetrics';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import VirtualizedAutocomplete from '../../../../../shared/components/Autocomplete';
import VirtualizedAutocompletePersonas from '../../../../../shared/components/AutocompletePersonas';

export default function IncidenciasTab() {
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = useState(null);
    const [currentProveedor, setCurrentProveedor] = useState(null);
    const [currentTipoPieza, setCurrentTipoPieza] = useState(null);
    const [currentCulpa, setCurrentCulpa] = useState(-1);
    const [startDate, setStartDate] = useState(moment().date() >= 26 ?
        moment().date(26).format('YYYY-MM-DD')
    :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().date() >= 26 ? 
        moment().add(1, 'month').date(25).format('YYYY-MM-DD')
    :   moment().date(25).format('YYYY-MM-DD'));
    const [view, setView] = useState('variable');
    const dashboard = useSelector(state => {
        return state.dashboard.entity.admin
    });
    const recambistas = useSelector(state => {
        return state.recambistas
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const { 
        control,
    } = useForm({
        defaultValues: {
            proveedor: null,
            tipo_pieza: null
        }
    });
    const posiblesCulpables = ['Proveedor', 'Cliente', 'Recomotor', 'Transporte proveedor', 'Transporte propio', 'Atencion comercial'];

    useEffect(() => {
        dispatch(fetchIncidenciasMetrics(currentUser, startDate, endDate, view, currentProveedor?.id, currentTipoPieza?.id));
    }, []);


    const handleChangeUser = (e) => {
        dispatch({type: 'loading/set', payload: true});

        if(parseInt(e.target.value) === -1) {
            dispatch(fetchIncidenciasMetrics(null, startDate, endDate, view, currentProveedor?.id, currentTipoPieza?.id));
            setCurrentUser(null);
        } else {
            dispatch(fetchIncidenciasMetrics(e.target.value, startDate, endDate, view, currentProveedor?.id, currentTipoPieza?.id));
            setCurrentUser(e.target.value);
        }
    }

    const handleDateInterval = (startDate, endDate, view) => {
        dispatch({type: 'loading/set', payload: true});
        setView(view);
        setStartDate(startDate);
        setEndDate(endDate);

        dispatch(fetchIncidenciasMetrics(currentUser, startDate, endDate, view, currentProveedor?.id, currentTipoPieza?.id));
    }

    const handleChangeProveedor = async(proveedor) => {
        dispatch({type: 'loading/set', payload: true});

        if(proveedor !== "" || proveedor !== undefined || proveedor !== null) {
            await dispatch(fetchIncidenciasMetrics(currentUser, startDate, endDate, view, proveedor.id, currentTipoPieza?.id));
            setCurrentProveedor(proveedor);
            return;
        } 

        setCurrentProveedor(null);
        await dispatch(fetchIncidenciasMetrics(null, startDate, endDate, view, null, currentTipoPieza?.id));
    }

    const handleChangeTipoPieza = async(tipoPieza) => {
        dispatch({type: 'loading/set', payload: true});

        if(tipoPieza) {
            await dispatch(fetchIncidenciasMetrics(currentUser, startDate, endDate, view, currentProveedor?.id, tipoPieza.id));
            setCurrentTipoPieza(tipoPieza);
            return;
        } 

        setCurrentTipoPieza(null);
        await dispatch(fetchIncidenciasMetrics(null, startDate, endDate, view, currentProveedor?.id, null));
    }

    const handleChangeCulpa = async(e) => { 
        dispatch({type: 'loading/set', payload: true});
        const value = e.target.value;

        if(value != -1) {
            await dispatch(fetchIncidenciasMetrics(currentUser, startDate, endDate, view, currentProveedor?.id, currentTipoPieza?.id, value));
            setCurrentCulpa(value);
            return;
        }
        
        setCurrentCulpa(null);
        await dispatch(fetchIncidenciasMetrics(null, startDate, endDate, view, currentProveedor?.id, currentTipoPieza?.id, null));
    }

    const handleReset = async() => {
        dispatch({type: 'loading/set', payload: true});
        await dispatch(fetchIncidenciasMetrics(null, startDate, endDate, view, null, null));
        setCurrentProveedor(null);
        setCurrentTipoPieza(null);
    }

    return (
        <div className='w-100 d-flex flex-column align-items-center dashboard'>
            <div className='w-100 mt-4 summary'>
                <h1>Información de Incidencias</h1>

                <div className='w-100 d-flex align-items-center'>
                    <div className='flex-grow-1 d-flex align-items-center'>
                        { (recambistas && recambistas.length > 0) &&
                            <select 
                                id="responsable"
                                defaultValue={currentUser ? currentUser : -1}
                                onChange={handleChangeUser}>
                                <option key={'recambistas-' + 0} value={-1}>Todos</option>
                                {recambistas.map(op => <option key={'recambistas-' + op.id} value={op.id}>{op.username}</option>)}
                            </select>
                        }

                        <span className='d-block w-100 mx-3'>
                            <VirtualizedAutocompletePersonas 
                                id={"proveedor"}
                                control={control}
                                item={'proveedor'}
                                labels={['nombre', 'telefono', 'score']}
                                defaultValue={currentProveedor}
                                onChange={handleChangeProveedor}
                            />
                        </span>

                        <VirtualizedAutocomplete
                            id={"tipo_pieza"}
                            control={control}
                            options={tipoPiezas.sort((a, b) => a.familia_tipo_pieza_id - b.familia_tipo_pieza_id)}
                            labels={['nombre']}
                            defaultValue={currentTipoPieza} 
                            onChange={handleChangeTipoPieza}
                        />

                        <select 
                            id="culpa"
                            defaultValue={''}
                            className='ms-3'
                            onChange={handleChangeCulpa}>
                            <option value={-1}>Ninguna</option>
                            {posiblesCulpables.map(op => <option key={'tipo-incidencias-' + op.replace(' ', '-').toLocaleLowerCase()} value={op.replace(' ', '-').toLocaleLowerCase()}>{op}</option>)}
                        </select>

                        <span className="d-block" title="Reset">
                            <FontAwesomeIcon icon={faRedo} color="#dc3545" className="icon ms-3" style={{cursor: 'pointer'}} onClick={() => handleReset()} />
                        </span>
                    </div>

                    <div className='d-flex align-items-center'>
                        <DateSelector onSelectedDate={handleDateInterval} /> 
                    </div>
                </div>


                { (dashboard.incidenciasMetrics) &&  
                    <IncidenciasMetrics 
                        resp={currentUser}
                        view={view}
                        data={dashboard.incidenciasMetrics} />
                }
            </div>
        </div>
    )
}
