import API from '../../../../../services/api';

export const getFacturas = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/facturas' + pagination + searcher).then(res => res.data);
}

export const getFactura = async (idFactura) => {
    return API.get('/facturas/' + idFactura).then(res => res.data);
}

export const getFacturaCompleta = async (idFactura) => {
    return API.get('/facturas/' + idFactura + '/completo').then(res => res.data);
}

export const newFactura = async (factura) => {
    return API.post('/facturas', factura).then(res => res.data);
}

export const reclamarFacturaCredito = async (facturaId) => {
    return API.post('/facturas/' + facturaId + '/reclamar', {}).then(res => res.data);
}

export const updateFactura = async (factura) => {
    return API.put('/facturas/' + factura.id, factura).then(res => res.data);
}

export const generateFacturaPDF = async (idFactura) => {
    return API.get('/facturas/generate_pdf/' + idFactura, {responseType: 'blob'}).then(res => res.data);
}

export const regenerateFactura = async (factura) => {
    return API.post('/facturas-s3', factura).then(res => res.data);
}

export const exportExcel = async (facturas) => {
    return API.post('/facturas/export', facturas, {responseType: 'blob'}).then(res => res.data);
}

export const compareFacturasAndVentas = async (facturas) => {
    return API.post('/facturas/compare', facturas, {responseType: 'blob'}).then(res => res.data);
}

export const exportReport = async (data) => {
    return API.post('/facturas/report', data, {responseType: 'blob'}).then(res => res.data);
}