export const getActions = () => {
   return {
        actions: [
            {
                label: 'Descargar PDF',
                icon: 'pdf',
                action: 'download'
            }
        ],
        extra: []
    }
}