import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getAllowedExt } from "../../../shared/helpers/functionalities";

export default function UploadEtiquetaForm({
    envio,
    onSubmitEtiqueta
}) {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            envio_id: envio.id,
            etiqueta: null
        }
    });

    useEffect(() => {
    }, []);

    const handleSubmitEtiqueta = async(data, e) => {
        e.preventDefault();
        const dataAux = new FormData();

        if(!data.etiqueta || data.etiqueta?.length === 0) {
            toast.error('La etiqueta es obligatoria');
            return
        }

        Object.entries(data).forEach((value) => {
            if((value[0] === 'etiqueta')) {
                dataAux.append(value[0], value[1][0])
            } else { dataAux.append(value[0], value[1]) }
        });
        onSubmitEtiqueta(dataAux);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitEtiqueta)}>
                <input 
                    type="hidden" 
                    {...register("envio_id")} 
                    defaultValue={envio.id} />

                <div className="w-100">
                    <div className="form-group">
                        <label htmlFor="img">Etiqueta</label>
                        <input 
                            type="file" 
                            className="form-control" 
                            accept={getAllowedExt('pdf')}
                            {...register("etiqueta", { maxLength: 255 })} 
                            defaultValue={''} />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}