import React, { useEffect } from "react";
import AdminDashboard from "./components/AdminDashboard";
import UserDashboard from "./components/UserDashboard";

export default function Home() {
   const user = JSON.parse(localStorage.getItem('user'));

   useEffect(() => {
   }, []);

   return (
      <div className="content">
         { (user.rol_id === 1 || user.rol_id === 4 || user.rol_id === 5 || (user.rol_id === 2 && user.responsable)) ?
            <AdminDashboard />
            :
            <UserDashboard />
         }
      </div>
   );
}