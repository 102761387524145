import React, { useEffect, useRef, useState } from 'react';
import ScrappingByRefForm from './ScrappingByRefForm';
import ResultsList from './components/ResultsList';
import SearchProgress from './components/SearchProgress';
import { toast } from 'react-toastify';
import { completePartInfoScraper } from '../../../../../../../../../../../shared/services/scrapping';
import { setErrorMessage } from '../../../../../../../../../../../shared/helpers/functionalities';
import { getPiezasIntegraciones } from '../../../../../../../../../../../shared/services/piezas';
import { useSelector } from 'react-redux';
import { obtainCats } from '../../../../../../../../../../../../../../utils/categoriasmap';
import { obtenerProveedorIntegracion } from '../../../../../../../../../../../shared/services/proveedores';
import Loading from '../../../../../../../../../../../shared/components/Loading';
import ProveedorPocoFiableModal from '../ProveedorPocoFiableModal';
import config from '../../../../../../../../../../../../../../config';

export default function PartSearcher({
    onSelectedPart
}) {
    const [parts, setParts] = useState([]);
    const [showPartsList, setShowPartsList] = useState(false);
    const [showUnreliableModal, setShowUnreliableModal] = useState(false);
    const [partInfo, setPartInfo] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const eventSourceRef = useRef(null);
    const marketPlacesToSearch = useRef(null);

    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const marcas = useSelector(state => {
        const { marcas } = state;
        return marcas;
    });
    const modelos = useSelector(state => {
        const { modelos } = state;
        return modelos;
    });
    const piezasNoGarantia = [
        61, 226, 230, 233, 234,
        235, 236, 237, 238,
        239, 240, 241, 242,
        243, 244, 245, 246,
        247, 248, 249, 251, 261,
        265, 266, 267, 450
    ];

    const getTipo = (tipo) => {
        const cats = obtainCats()
        let id = cats.find(tp => tp.nombre.toLowerCase() ===  tipo.toLowerCase())?.idcrm;
        if (!id){
            id = tipoPiezas.find(tp => tp.nombre.toLowerCase() ===  tipo.toLowerCase())?.id;
        }
        return id
    }

    const getMarca = (marca) => { return marcas.find(m => m.nombre.toLowerCase() ===  marca.toLowerCase())?.id; }

    const getModelo = (modelo) => {
        modelo = modelo.split(' ')[0];
        return modelos.find(m => m.nombre.toLowerCase()?.includes(modelo.toLowerCase()))?.id; 
    }

    useEffect(() => {
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                eventSourceRef.current = null;
            }
        };
    }, []);

    const handleSearch = async (data) => {
        setIsLoading(true);
        setParts([]);
        setShowForm(false);
        const ref = data.ref;
        const selectedMarketPlaces = data.marketPlaces;

        if (selectedMarketPlaces.length > 0) {
            handleScraperSearch(getScraperUrl(selectedMarketPlaces, ref));

            marketPlacesToSearch.current = selectedMarketPlaces.map((marketPlace) => {
                return {
                    name: marketPlace,
                    received: false,
                };
            });
        }

        data.referencia_pieza = ref;

        const resultsResponse = await getPiezasIntegraciones(data, 1);

        if (selectedMarketPlaces.length === 0) {
            setIsLoading(false);
            setShowPartsList(true);
        }

        if(resultsResponse && resultsResponse.success) {
            setParts(mapIntegrationParts(resultsResponse.data.piezas, ref));
        }
    }

    const mapIntegrationParts = (parts, ref) => {
        parts.forEach((part) => {
            part['Año'] = part.anopieza;
            part.isFromIntegration = true;
            part.ref_vendedor = part.id_original;
            part.vendedor = part.origen;
            part.images = part.imgs;
            part.pieza = part.nombre;
            part.observaciones = part.notapublica;
            part['Versión'] = part.modelo;
            part.marketPlace = 'Integración';
            part.url = 'integracion-desguace-' + part.vendedor;
            part.marca_id = getMarca(part.marca);
            part.modelo_id = getModelo(part.modelo);
            part.nombre = part.pieza + ' ' + part.marca + ' ' + part.modelo;
            part.referencia_pieza = ref;
            part.id_referencia_plataforma = part.id_desguace;
            part.precio_original = parseInt(part.precio) === 0 ? '0.01' : parseFloat(part.precio).toFixed(2);
            part.precio_compra = parseInt(part.precio) === 0 ? '0.01' : parseFloat(part.precio).toFixed(2);
            part.casco = 0;
            part.disponible = part.ubicacion.toLowerCase().includes('montada') ? null : 1;
            part.integracion = true;
            part.pieza_integracion_id = part.id;
            part.modelo = null;
            part.marca = null;
        });

        return parts;
    }

    const handleScraperSearch = (url) => {
        eventSourceRef.current = new EventSource(url);

        eventSourceRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data + '}');

            setParts(oldArray => [...oldArray, ...sortParts(data)]);
        }

        eventSourceRef.current.onerror = (event) => {
            eventSourceRef.current.close();
            marketPlacesToSearch.current = null;
            setIsLoading(false);
            setShowPartsList(true);
        }
    }

    const getScraperUrl = (selectedMarketPlaces, ref) => {
        if (!selectedMarketPlaces) {
            return config.recomotor.scraperUrl + '?ref=' + ref;
        }

        let marketPlacesQuery = '';

        selectedMarketPlaces.forEach((marketPlace, i) => {
            marketPlacesQuery = i === 0
                ? marketPlacesQuery + 'marketplaces[]=' + marketPlace
                : marketPlacesQuery + '&marketplaces[]=' + marketPlace;
        });

        return config.recomotor.scraperUrl + '?' + marketPlacesQuery + '&ref=' + ref
    }

    const sortParts = (data) => {
        const marketPlace = Object.keys(data)[0];
        let newParts = [];

        if (marketPlace === 'recambiofacil') {
            newParts = sortRecambioFacilParts(data[marketPlace], marketPlace);
            showNewResultsMessage(marketPlace, newParts);

            return newParts;
        }

        newParts = data[marketPlace];
        newParts.forEach(part => addPropertiesToPart(part, marketPlace));
        showNewResultsMessage(marketPlace, newParts);

        return sortPartsByPrice(newParts);
    }

    const sortRecambioFacilParts = (parts, marketPlace) => {
        const catParts = parts.cat;
        catParts.forEach(part => addPropertiesToPart(part, marketPlace, 'cat'));

        const iamParts = parts.iam;
        iamParts.forEach(part => addPropertiesToPart(part, marketPlace, 'iam'));

        const oemParts = parts.oem;
        oemParts.forEach(part => addPropertiesToPart(part, marketPlace, 'oem'));

        parts = [...catParts, ...iamParts, ...oemParts];

        return sortPartsByPrice(parts);
    }

    const sortPartsByPrice = (parts) => parts.sort((a, b) => parseInt(a.precio) - parseInt(b.precio));

    const addPropertiesToPart = (part, marketPlace, type = '') => {
        part.marketPlace = marketPlace;
        part.type = type;
    };

    const showNewResultsMessage = (marketPlace, newParts) => {
        marketPlacesToSearch.current = marketPlacesToSearch.current.map((web) => {
            if (web.name === marketPlace) {
                web.received = true;
            }

            return web;
        });
    }

    const handleScrapping = (confirm) => {
        if (confirm) {
            onSelectedPart(partInfo);
        } else {
            setPartInfo([]);
            setShowUnreliableModal(false);
        }
    }

    const handleSelectIntegrationPart = async (part) => {
        const proveedorResult = await obtenerProveedorIntegracion({
            id_crm: part.id_crm,
            nombre_origen: part.origen
        });

        const proveedor = await proveedorResult.data;
        const tipo = getTipo(part.nombre);

        let precioTransporte = 0;
        let garantiaId = null;

        if(tipo === 406) { precioTransporte = 70 }
        else if(tipo === 55)  { precioTransporte = 30 }
        else {
            if(tipo === 80 || (tipo >= 146 && tipo <= 151) ||
                (tipo >= 174 && tipo <= 176) || tipo === 184) {
                if(tipo === 80 && proveedor.envio_capot !== null) {
                    precioTransporte = proveedor.envio_capot;
                } else if((tipo >= 146 && tipo <= 151)  && proveedor.envio_puertas !== null) {
                    precioTransporte = proveedor.envio_puertas;
                } else {
                    if(proveedor.envio_porton !== null) {
                        precioTransporte = proveedor.envio_porton;
                    }
                }
            }
        }

        garantiaId = piezasNoGarantia.find(tp => tp === tipo)
            ? 5
            :  proveedor.tipo_garantia_id ? proveedor.tipo_garantia_id : 1;

        part.precio_transporte = precioTransporte;
        part.tipo_pieza_id = getTipo(part.pieza);
        part.tipo_garantia_id = garantiaId;
        part.con_descuento = part.proveedor.descuentos?.length > 0 ? true : false;

        setPartInfo(part);

        if (part?.proveedor && !checkIfIsReliableProveedor(part?.proveedor)) {
            setShowUnreliableModal(true);
            return;
        }

        onSelectedPart(part);
    }

    const handleSelectedPart = async (part) => {
        if (part.marketPlace === 'Integración') {
            await handleSelectIntegrationPart(part);
            return;
        }

        const response = await completePartInfoScraper(part)
            .catch((error) => {
                toast.error(setErrorMessage(error || "Error! Ha ocurrido un problema al obtener toda la información de la pieza."));
            });

        if (response && response.success) {
            const partData = response.data;

            if (!partData) {
                return;
            }

            setPartInfo(partData)

            if (partData?.proveedor && !checkIfIsReliableProveedor(partData?.proveedor)) {
                setShowUnreliableModal(true);
                return;
            }

            onSelectedPart(partData);
        }
    }

    const searchAgain = () => {
        setShowPartsList(false);
        setShowUnreliableModal(false);
        setShowForm(true);
    }

    const checkIfIsReliableProveedor = (proveedor) => proveedor.score === null || (proveedor.score >= 6.5);

    return (
        <>
            { (isLoading) && <Loading /> }
            { (showForm && !isLoading) &&
                <ScrappingByRefForm
                    onSearch={handleSearch}
                    onSelectedPart={handleSelectedPart}
                />
            }

            { marketPlacesToSearch?.current &&
                <SearchProgress marketPlacesToSearch={marketPlacesToSearch.current} />
            }

            { showPartsList &&
                <>
                    <div className={'d-flex justify-content-end'}>
                        <button className={'btn btn-primary'} onClick={searchAgain}>Repetir búsqueda</button>
                    </div>
                    <ResultsList
                        results={parts}
                        onSelectedPart={handleSelectedPart}
                    />
                </>
            }

            { showUnreliableModal &&
                <ProveedorPocoFiableModal
                    pieza={partInfo}
                    onConfirmAction={handleScrapping} 
                    state={showUnreliableModal}>
                </ProveedorPocoFiableModal>
            }
        </>
    )
}