import API from '../../../../../services/api';

export const getReadOnlyData = async () => {
    return await API.get('/dashboard/read-only').then(res => res.data);
}

// ----------------------- ADMIN ----------------------- //
export const getAdminData = async () => {
    return await API.get('/dashboard/admin').then(res => res.data);
}

export const getUserMetrics = async (userId, startDate, endDate, view) => {
    const params = '?user_id= ' + userId + '&startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/user-metrics' + params).then(res => res.data);
}

export const getGeneralMetrics = async (startDate, endDate, view) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/general-metrics' + params).then(res => res.data);
}

export const getPlataformasUserMetrics = async (plataformaId, startDate, endDate, view) => {
    const params = '?plataforma_id= ' + plataformaId + '&startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/plataformas-user-metrics' + params).then(res => res.data);
}

export const getPlataformasGeneralMetrics = async (startDate, endDate, view) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/plataformas-general-metrics' + params).then(res => res.data);
}

export const getUserResume = async (startDate, endDate,) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate;
    return await API.get('/dashboard/user/resumen' + params).then(res => res.data);
}

export const getClientesMetrics = async (startDate, endDate, view) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/clientes-metrics' + params).then(res => res.data);
}

export const getProveedoresMetrics = async (startDate, endDate, view) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/proveedores-metrics' + params).then(res => res.data);
}

export const getPiezasMetrics = async (startDate, endDate, view) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/piezas-metrics' + params).then(res => res.data);
}

export const getIncidenciasMetrics = async (resp, startDate, endDate, view, proveedorId, tipoPiezaId, culpa) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view + 
        (resp ? ('&resp_id=' + resp) : '') + (proveedorId ? ('&proveedor_id=' + proveedorId) : '') + 
        (tipoPiezaId ? ('&tipo_pieza_id=' + tipoPiezaId) : '') + (culpa ? ('&culpa=' + culpa) : '');

    return await API.get('/dashboard/admin/incidencias-metrics' + params).then(res => res.data);
}

export const getTransferencias = async () => {
    return await API.get('/dashboard/admin/transferencias').then(res => res.data);
}

export const getComprobanteTransferencia = async (comprobante) => {
    return await API.get('/dashboard/admin/comprobante/' + comprobante, {responseType: 'blob'}).then(res => res.data);
}

// ----------------------- USER ----------------------- //

export const getRecambistasMessages = async () => {
    return await API.get('/dashboard/user/messages').then(res => res.data);
}