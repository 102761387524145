import { getDevolucionesMercancias } from "../views/Dashboard/components/shared/services/devoluciones_mercancias";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";

const asyncDevoluciones = makeAsyncTypes('devolucion_mercancias');

const [setPending, setFullFilled, setError ] = asyncMac(asyncDevoluciones);

export const devolucionesFetchingReducer = makeFetchingReducer([
    'devolucion_mercancias/pending', 
    'devolucion_mercancias/fullfilled', 
    'devolucion_mercancias/rejected'
]);

const fullfilledReducer = makeSetReducer(['devolucion_mercancias/fullfilled']);

export const fetchDevolucionesMercancias = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getDevolucionesMercancias(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const devolucionesMercanciasReducer = fullfilledReducer;
export const devolucionesMercanciasStatusReducer = devolucionesFetchingReducer;