import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { downloadPlantilla } from "../../../../../shared/services/facturas_desguaces";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getAllowedExt } from "../../../../../shared/helpers/functionalities";

export default function FacturaManualForm({
    onSubmitFacturaManual
}) {
    const dispatch = useDispatch();
    const { register, formState: { errors }, handleSubmit, setValue} = useForm({
        defaultValues: {
            excel: null,
            desguace_id: null
        }
    });
    const desguaces = useSelector(state => {
        const { desguaces } = state;
        return desguaces;
    });

    useEffect(() => {
        
    }, []);

    const handleDownload = async() => {
        dispatch({type: 'loading/set', payload: true});
        const excel = await downloadPlantilla();

        if(excel) {
            toast.success('Plantilla descargada correctamente!')
            dispatch({type: 'loading/set', payload: false});
            const url = window.URL.createObjectURL(new Blob([excel]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'plantilla_factura_manual.xlsx');
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    const handleChangeDesguace = (e) => {
        const value = parseInt(e.target.value);
        setValue('desguace_id', value);
    }

    const handleSubmitFactura = async(data, e) => {
        e.preventDefault();
        const excel = new FormData();
        excel.append('excel', data.excel[0]);
        excel.append('desguace_id', data.desguace_id);
        onSubmitFacturaManual(excel);
    }
    
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitFactura)}>
                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="matricula">1. descargar plantilla</label>
                        <button type="button" className="btn btn-add mb-4" onClick={() => handleDownload()}>
                            <span className="add">
                                <FontAwesomeIcon icon={faDownload} size="1x" className="action" color="#215732" />
                            </span>
                            Descargar
                        </button>
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="matricula">2. SUBIR PLANTILLA RELLENADA</label>
                        <input 
                            type="file"
                            className="form-control"
                            accept={getAllowedExt('excel')}
                            {...register("excel", { required: true })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.excel?.type === 'required' && "El excel es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="rol">Desguace:</label>
                        { (desguaces && desguaces.length > 0) &&
                            <select 
                                {...register("desguace_id", { required: true })}
                                defaultValue={null}
                                onChange={handleChangeDesguace}>
                                {
                                    desguaces.map(op => <option key={'desguace-' + op.id} value={op.id}>{op.nombre}</option>)
                                }
                            </select>
                        }

                        <div className="form-invalid">
                            {errors.desguace_id?.type === 'required' && "El desguace es obligatorio"}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}