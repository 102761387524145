import { useEffect, useState } from "react";
import React from "react";


export default function PersonasSubForm({
    proveedor,
    register,
    disabled,
    isEdit
}) {
    useEffect(() => {
    }, [proveedor]);
   
    return (
        <div className="w-100 d-flex flex-column">
            <h6 className="text-uppercase mt-3">Personas de contacto</h6>
            <div className="d-flex align-items-center">
                <div className="form-group me-3">
                    <label className="mb-2" htmlFor="persona_administracion">Administración</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        disabled={disabled}
                        {...register("persona_administracion")} 
                        defaultValue={isEdit ? proveedor.persona_administracion : ''} />
                </div>

                <div className="form-group me-3">
                    <label className="mb-2" htmlFor="telefono_administracion">Teléfono de administración</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        disabled={disabled}
                        {...register("telefono_administracion")} 
                        defaultValue={isEdit ? proveedor.telefono_administracion : ''} />
                </div>

                <div className="form-group">
                    <label className="mb-2" htmlFor="email_administracion">Email de administración</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        disabled={disabled}
                        {...register("email_administracion")} 
                        defaultValue={isEdit ? proveedor.email_administracion : ''} />
                </div>
            </div>

            <div className="d-flex align-items-center">
                <div className="form-group me-3">
                    <label className="mb-2" htmlFor="persona_recambios">Recambios</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        disabled={disabled}
                        {...register("persona_recambios")} 
                        defaultValue={isEdit ? proveedor.persona_recambios : ''} />
                </div>

                <div className="form-group me-3">
                    <label className="mb-2" htmlFor="telefono_recambios">Teléfono de recambios</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        disabled={disabled}
                        {...register("telefono_recambios")} 
                        defaultValue={isEdit ? proveedor.telefono_recambios : ''} />
                </div>

                <div className="form-group">
                    <label className="mb-2" htmlFor="email_recambios">Email de recambios</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        disabled={disabled}
                        {...register("email_recambios")} 
                        defaultValue={isEdit ? proveedor.email_recambios : ''} />
                </div>
            </div>

            <div className="d-flex align-items-center">
                <div className="form-group me-3">
                    <label className="mb-2" htmlFor="persona_incidencias">Incidencias</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        disabled={disabled}
                        {...register("persona_incidencias")} 
                        defaultValue={isEdit ? proveedor.persona_incidencias : ''} />
                </div>

                <div className="form-group me-3">
                    <label className="mb-2" htmlFor="telefono_incidencias">Teléfono de incidencias</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        disabled={disabled}
                        {...register("telefono_incidencias")} 
                        defaultValue={isEdit ? proveedor.telefono_incidencias : ''} />
                </div>

                <div className="form-group">
                    <label className="mb-2" htmlFor="email_incidencias">Email de incidencias</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        disabled={disabled}
                        {...register("email_incidencias")} 
                        defaultValue={isEdit ? proveedor.email_incidencias : ''} />
                </div>
            </div>
        </div>
    );
}