import React from "react";
import { useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTimes, faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { checkDisabledProveedor, isOnVacation } from "../../../../../../../../../shared/helpers/functionalities";

export default function PiezasSelectorDialog({
  data, 
  open,
  onClose,
  onConfirmAction, 
}) {

  useEffect(() => { }, [data, open]);

  const handleClose = () => { onClose(); };

  const getPiezasFromCategoria = (data, type) => {
    return (
      <div className="w-100">
        <table className="table">
          <thead>
            <tr>
              <td>
                <h1 className="fw-bold text-uppercase m-0">{type}</h1>
              </td>
            </tr>
          </thead>
          <tbody>
            { data?.map((pieza, i) =>{
              return (
                <tr key={'pieza-selector-'+pieza.nombre + '-' + pieza['proveedor'] + '-' + i} 
                  className={pieza['proveedor']?.bloqueado ? "disabled-option" : ''}>
                  <td>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                      <div className="w-100 d-flex flex-column flex-grow-1">
                        <p className="mb-2 d-flex align-items-center">
                          <span className="fw-bold me-2">{ pieza['id_referencia_plataforma'] }</span> 
                          <span className="mx-2">
                            REF: &nbsp; 
                            <span style={{fontWeight: 600}}>
                              { pieza['referencia_pieza'] }
                            </span>
                          </span> 
                          <span>{ pieza['marca'] } - { pieza['nombre'] }</span>
                          <span className="fw-bold ms-2">{ pieza['precio_original']}€</span>
                        </p>

                        <p className="mb-0">
                          {pieza['proveedor']?.bloqueado ? <FontAwesomeIcon icon={faBan} size="1x" className="action" color="#dc3545" /> : ''}
                          <b>{pieza['proveedor']?.nombre}</b>
                          <small className={"ms-3 fw-bold " + (pieza['proveedor']?.score >= 7 ? 'text-green' : (pieza['proveedor']?.score >= 6.5 || pieza['proveedor']?.score === null) ? 'text-warning' : 'text-danger')}>
                            (SCORE: {pieza['proveedor']?.score ? pieza['proveedor']?.score : '-'})
                          </small>
                        </p>
                      </div>

                      <div className="d-flex align-items-center">
                        { (isOnVacation(pieza['proveedor'])) &&
                            <FontAwesomeIcon icon={faUmbrellaBeach} size="1x" className="me-2" color="#dc3545" />
                        }
                        <button 
                          type="button" 
                          className="btn btn-primary btn-sm" 
                          onClick={() => {onConfirmAction(pieza); onClose()}}
                          disabled={checkDisabledProveedor(pieza['proveedor'])}
                        >SELECCIONAR</button>
                      </div>
                    </div>  
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="pieza-selector-title"
      aria-describedby="pieza-selector-description">
      <DialogTitle id="pieza-selector-title">
          <div className="w-100 d-flex align-items-center justify-content-between">
            <p className="fw-bold text-uppercase">Escoge una pieza</p>

              <span title="cerrar" className="action" onClick={() => handleClose()}>
                  <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
              </span>
          </div>
          
      </DialogTitle>
      <DialogContent>
          {getPiezasFromCategoria(data.oem, 'OEM')}
          {getPiezasFromCategoria(data.iam, 'IAM')}
          {getPiezasFromCategoria(data.cat, 'CAT')}
      </DialogContent>
  </Dialog>
  );
}