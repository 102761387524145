import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import React, { createContext, forwardRef, useContext, useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { faArrowsAltH, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomTooltip } from '../../helpers/functionalities';

const LISTBOX_PADDING = 8; // px
let labelsArr =  [];

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  const getOptionLabel = (option) => {
    const labelsArray = labelsArr.map(lab => `${option[lab]}`);
    const label = labelsArray.join(" - ");
    return label;
  }

  const getCustomOptionLabel = (option, label) => {
    return (
      <span>
        { label === 'proveedores' ?
          <CustomTooltip
              title={
              <React.Fragment>
                  <p className={"mb-0 " + (option.score === null ? 'text-info' : (option.score >= 7 && option.score <= 10) ? 'text-green' : (option.score >= 6.5 && option.score <= 6.99) ? 'text-warning' : 'text-danger')}>
                      Score: {option.score ? option.score : 'No hay puntuación'}
                  </p>
              </React.Fragment>
          }>
            <span>
              { (option.score === null) ? 
                <FontAwesomeIcon icon={faThumbsUp} size="1x" className="action" color="#62B1F6" />
                : (option.score >= 7 && option.score <= 10) ?
                <FontAwesomeIcon icon={faThumbsUp} size="1x" className="action" color="#00986C" />
                : (option.score >= 6.5 && option.score <= 6.99) ?
                <FontAwesomeIcon icon={faThumbsUp} size="1x" className="action" color="#ffc107" />
                : <FontAwesomeIcon icon={faThumbsDown} size="1x" className="action" color="#dc3545" />
              }

              <span className='ms-2'>
                {labelsArr.filter(lab => lab !== 'score').map(lab => `${option[lab]}`).join(' - ')}
              </span>
            </span>
          </CustomTooltip>

          : labelsArr.map(lab => `${option[lab]}`).join(' - ')
        }
      </span>
    )
  }

  if (dataSet.hasOwnProperty('group')) {
    return (
      <>
        <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
          {dataSet.group}
        </ListSubheader>
      </>
    );
  }

  return (
    <>
      <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
        { (labelsArr.find(f => f === 'score')) ?
            getCustomOptionLabel(dataSet[1], 'proveedores')
          : getOptionLabel(dataSet[1])
        }
      </Typography>
    </>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

/*ListboxComponent.propTypes = {
  children: PropTypes.node,
};*/

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});


export default function VirtualizedAutocomplete({
  register,
  disabled,
  options, 
  control, 
  groupBy, 
  labels, 
  defaultValue, 
  id, 
  onChange
}) {
  labelsArr = labels;  
  
  const handleOnChange = (value) => {
      onChange(value);
    }

    const getVirtualizeOptionLabel = (option) => {
      const labelsArray = labels.map(lab => `${option[lab]}`);
      const label = labelsArray?.length > 1 ? labelsArray.join(" - ") : labelsArray[0];
      return label;
    }

    return (
      <Controller
          name={id}
          control={control}
          defaultValue={defaultValue}
          render={({ field: props }) => (
            <Autocomplete
                {...props}
                id={id}
                sx={{ width: "100%" }}
                onChange={(e, value)=> handleOnChange(value)}
                disableListWrap
                clearOnBlur={false}
                PopperComponent={StyledPopper}
                ListboxComponent={ListboxComponent}
                options={options}
                disabled={disabled ? true : false}
                groupBy={groupBy ? (option) => `${option[groupBy]}` : null}
                getOptionLabel={(option) =>  getVirtualizeOptionLabel(option)}
                renderOption={(props, option) => [props, option]}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                renderInput={(params) => 
                  <TextField 
                    {...params} 
                    InputProps={{
                      ...params.InputProps,
                    }} />
                }
                renderGroup={(params) => params}
            />
          )}
        />
    );
}