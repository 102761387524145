import { useEffect, useState } from "react";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import '../../../../incidencias.scss';
import { useDispatch, useSelector } from "react-redux";
import { newIncidenciaSteps } from "../../../../../../../../redux/features/fichaIncidencia";
import StepForm from "./components/StepForm";

export default function IncidenciaSteps({
    incidencia,
    incidenciaId,
    tipoId,
    logs,
}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const incidenciaSteps = useSelector(state => {
        const { steps } = state;
        return steps.filter(s => s.tipo_incidencia_id === tipoId);
    });
    const { register, handleSubmit, setValue, control, getValues } = useForm({
        mode: "onChange",
    });
    const { fields, append, replace } = useFieldArray({ control, name: 'step' })
    
    useEffect(() => {
        loadSteps();
    }, []);

    useEffect(() => {
        if(incidencia.estado_incidencia_id === 3) {
            if(user.rol_id !== 4 && user.rol_id !== 1) {
                setIsDisabled(true);
            }
        } else {
            if((incidencia.crafted_by && incidencia.crafted_by?.id !== user.id)) {
                setIsDisabled(true);
            }
        }
    }, [incidencia]);


    const loadSteps = () => {
        incidenciaSteps.sort((a,b) => -b.owner.localeCompare(a.owner) || a.step - b.step)
        .filter(step => {
            if(tipoId === 5) {
                if(step.owner === 'cliente') {
                    const clienteLog = getGarantiaClienteStepLog();

                    if(clienteLog) {
                        if(clienteLog.comentario.toLowerCase() === 'si') {return step.step <= 2; }
                        else { return true; }
                    } else { return true; }
                } else {
                    const proveedorLog = getGarantiaProveedorStepLog();

                    if(proveedorLog) {
                        if(proveedorLog.comentario.toLowerCase() === 'no') { return step.step <= 1; }
                        else { return true; }
                    } else { return true; }
                }
            } else { return true; }
        })
        .forEach((step, i) => {
            const log = getStepLog(step.id);

            append({
                index: i,
                nombre: step.nombre,
                owner: step.owner,
                step: step.step,
                solucion: step.solucion,
                aviso_finanzas: step.aviso_finanzas,
                aviso: step.aviso,
                incidencia_step_id: step.id,
                logId: log ? log.id : null,
                checked: log ? log.checked : false,
                comentario: log ? log.comentario : null,
                fecha_comentario: log ? log.fecha : null,
                created_by: log ? log.created_by : null
            })
        })
    }

    const reloadSteps = (item, value) => {
        const newSteps = []

        incidenciaSteps.sort((a,b) => -b.owner.localeCompare(a.owner) || a.step - b.step)
        .filter((step, i) => {
            if(step.owner === 'cliente') {
                const field2 = fields.find(f => f.owner === 'cliente' && f.step === 2);

                if(item.owner === 'cliente') {
                    if(item.step === 2) {
                        if(value.toLowerCase() === 'si') { 
                            return step.step <= 2;
                        } else { return true; }
                    } else {
                        if(field2?.comentario?.toLowerCase() === 'si') { 
                            return step.step <= 2;
                        } else { return true; }
                    }
                } else {
                    if(field2?.comentario?.toLowerCase() === 'si') { 
                        return step.step <= 2;
                    } else { return true; }
                }
            } else {
                const field1 = fields.find(f => f.owner === 'proveedor' && f.step === 1);

                if(item.owner === 'proveedor') {
                    if(item.step === 1) {
                        if(value.toLowerCase() === 'no') { 
                            return step.step <= 1; 
                        } else { return true; }
                    } else {
                        if(field1.comentario?.toLowerCase() === 'no') { 
                            return step.step <= 1; 
                        } else { return true; }
                    }
                } else {
                    if(field1.comentario?.toLowerCase() === 'no') { 
                        return step.step <= 1; 
                    } else { return true; }
                }
            }
        })
        .forEach((step, i) => {
            const log = getStepLog(step.id);
            const field = fields.find(f => f.owner === step.owner && f.step === step.step);
            let checkValue = false;

            if(step.owner === 'cliente') {
                if(step.step === 2 && item.owner === 'cliente') {
                    if(item.step === 2 && value?.toLowerCase() === 'si') {
                        checkValue = true;
                    } else {
                        checkValue = false;
                    }
                } else {
                    checkValue = field ? getValues(`step.${field.index}.checked`) : false;
                }
            } else {
                if(item.owner === 'proveedor') {
                    if(step.step === 1) {
                        if(item.step === 1 && value?.toLowerCase() === 'no') {
                            checkValue = true;
                        } else {
                            checkValue = false;
                        }
                    } else {
                        if(step.step === 4) {
                            if(item.step === 1 && value?.toLowerCase() !== 'no') {
                                checkValue = true;
                            } else {
                                checkValue = false;
                            }
                        } else {
                            checkValue = false;
                        }
                    }
                } else {
                    checkValue = field ? getValues(`step.${field.index}.checked`) : false;
                }
            }

            newSteps.push({
                index: i,
                nombre: step.nombre,
                owner: step.owner,
                step: step.step,
                solucion: step.solucion,
                aviso_finanzas: step.aviso_finanzas,
                aviso: step.aviso,
                incidencia_step_id: step.id,
                logId: log ? log.id : null,
                checked: checkValue,
                comentario: field ? getValues(`step.${field.index}.comentario`) : '',
                fecha_comentario: log ? log.fecha : null,
                created_by: log ? log.created_by : null
            })
        })
        
        return newSteps;
    }

    // SOLO PARA FALLO DE STOCK
    const handleCheckStep = (item, value) => {
        if(item.owner === 'cliente' ) {
            if(item.step === 2) {
                const newSteps = reloadSteps(item, value);
                replace(newSteps)
            }
        } else {
            if(item.step === 1) {
                const newSteps = reloadSteps(item, value);
                replace(newSteps)
            }
        }
    }

    const getStepLog = (stepId) => logs?.find(log => log.incidencia_step_id === stepId);
    const getGarantiaClienteStepLog = () => logs?.find(log => log.step.owner === 'cliente' && log.step.step === 2);
    const getGarantiaProveedorStepLog = () => logs?.find(log => log.step.owner === 'proveedor' && log.step.step === 1);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        let steps = data.step.map(s => {
            const step = {
                incidencia_id: parseInt(incidenciaId),
                incidencia_step_id : parseInt(s.incidencia_step_id),
                checked: s.checked,
                comentario: s.comentario
            };

            if(s.id) step.id = s.id;

            return step;
        });
        dispatch({type: 'loading/set', payload: true});
        dispatch(newIncidenciaSteps({steps: steps}));
    };

    return (
        <div className="w-100 h-100 d-flex flex-column cliente-card">
            <form className="form h-100 mt-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="w-100 d-flex">
                    <div className="col-12 col-md-6 pe-3">
                        <h1 className="cliente-card-name mb-2">CLIENTE</h1>

                        { fields.filter(f => f.owner === 'cliente').map((cliente, i) => {
                            return(
                                <StepForm 
                                    key={'cliente-' + i}
                                    incidencia={incidencia}
                                    item={cliente}
                                    tipoId={tipoId}
                                    disabled={isDisabled}
                                    register={register}
                                    setValue={setValue}
                                    onChangeStep={handleCheckStep}
                                />
                            )
                        })}
                    </div>

                    <div className="col-12 col-md-6 ps-3">
                        <h1 className="cliente-card-name mb-2">PROVEEDOR</h1>

                        { fields.filter(f => f.owner === 'proveedor').map((proveedor, i) => {
                            return(
                                <StepForm 
                                    key={'proveedor-' + i}
                                    item={proveedor}
                                    tipoId={tipoId}
                                    disabled={isDisabled}
                                    register={register}
                                    setValue={setValue}
                                    onChangeStep={handleCheckStep}
                                />
                            )
                        })}
                    </div>
                </div>
                
                <button 
                    type="submit" 
                    className="btn btn-primary mt-5"
                    disabled={isDisabled}
                >Guardar</button>
            </form>
        </div>
    );
}