import { getFinanzas, getPedidosPendientes, getTransferenciasParaAprobar } from "../../views/Dashboard/components/shared/services/finanzas";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";

const initialState = {
    comprobantes: [],
    pedidos: []
}

export const finanzasReducer = (state = initialState, action) => {
    switch(action.type){
        case "finanzas/fullfilled": {
            return action.payload;
        }
        case "finanzas/comprobantes": {
            return {...state, comprobantes: action.payload }
        }
        case "finanzas/pedidos": {
            return {...state, pedidos: action.payload }
        }
        default:
            return state
    }
}

const asyncFinanzas = makeAsyncTypes('finanzas');

const [setPending, setFullFilled, setError ] = asyncMac(asyncFinanzas);

export const finanzasFetchingReducer = makeFetchingReducer([
    'finanzas/pending', 
    'finanzas/fullfilled', 
    'finanzas/rejected'
]);

export const fetchFinanzas = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getFinanzas()
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchTransferenciasPorAprobar = () => async dispatch => {
    try {
        const response = await getTransferenciasParaAprobar()
        const data = await response.data;
        dispatch({type: "finanzas/comprobantes", payload: data}); 
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchPedidosPendientes = () => async dispatch => {
    try {
        const response = await getPedidosPendientes()
        const data = await response.data;
        dispatch({type: "finanzas/pedidos", payload: data}); 
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const finanzasTopReducer = finanzasReducer;
export const finanzasStatusReducer = finanzasFetchingReducer;