import React from 'react';
import { DoughnutChart } from '../../../../../../../shared/components/Charts/components/Doughnut';
import ResumenGeneral from './components/ResumenGeneral';
import TipoPiezasRanking from './components/TipoPiezasRanking';

export default function IncidenciasMetrics({
    view, 
    data,
    resp
}) {
        return (
            <div className='w-100 d-flex flex-column align-items-center justify-content-between my-3'>
                <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap'>
                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center'>
                        { view === 'dia' ? <h2>Incidencias diarias</h2> : ''}
                        { view === 'semana' ? <h2>Incidencias semanales</h2> : ''}
                        { (view === 'mes' || view === 'variable') ? <h2>Incidencias mensuales</h2> : ''}
                        { view === 'año' ? <h2>Incidencias anuales</h2> : ''}
                        { view === 'ultimos-12meses' ? <h2>Incidencias</h2> : ''}
                        <h1>{data.num_incidencias}</h1>
                    </div>

                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                        { view === 'dia' ? <h2>Cascos diarios</h2> : ''}
                        { view === 'semana' ? <h2>Cascos semanales</h2> : ''}
                        { (view === 'mes' || view === 'variable') ? <h2>Cascos mensuales</h2> : ''}
                        { view === 'año' ? <h2>Cascos anuales</h2> : ''}
                        { view === 'ultimos-12meses' ? <h2>Cascos</h2> : ''}
                        <h1>{data.num_cascos}</h1>
                    </div>
                </div>

                <div className='card-grid w-100 d-flex mt-4 flex-wrap flex-md-nowrap'>
                    <div className='w-100 w-md-50 card-item d-flex flex-column align-items-center'>
                        { view === 'dia' ? <h2>Pérdidas diarias</h2> : ''}
                        { view === 'semana' ? <h2>Pérdidas semanales</h2> : ''}
                        { (view === 'mes' || view === 'variable') ? <h2>Pérdidas mensuales</h2> : ''}
                        { view === 'año' ? <h2>Pérdidas anuales</h2> : ''}
                        { view === 'ultimos-12meses' ? <h2>Pérdidas</h2> : ''}
                        <h1>{data.perdidas_totales}€</h1>
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap align-items-center justify-content-between mt-4">
                    <h1>Responsabilidad</h1>
                    
                    <div className='w-100 d-flex flex-wrap flex-md-nowrap align-items-center mt-3'>
                        <div className='col-12 col-md-4 pe-0 pe-md-3'>
                            { data?.resp_ext_chart ?
                                <DoughnutChart
                                    title={'Responsabilidad Externa'}
                                    dataset={data?.resp_ext_chart}
                                />
                                : <p className='text-center'>No hay datos de Responsabilidad Externa.</p>
                            }
                        </div>

                        <div className='col-12 col-md-4 pe-0 pe-md-3'>
                            { data?.resp_ext_env_chart ?
                                <DoughnutChart
                                    title={'Responsabilidad Envío'}
                                    dataset={data?.resp_ext_env_chart}
                                />
                                : <p className='text-center'>No hay datos de Responsabilidad de envíos.</p>
                            }
                        </div>

                        <div className='col-12 col-md-4 ps-0 ps-md-3'>
                            { data?.resp_int_chart ?
                                <DoughnutChart
                                    title={'Responsabilidad Interna'}
                                    dataset={data?.resp_int_chart}
                                />
                                : <p className='text-center'>No hay datos de Responsabilidad Interna.</p>
                            }
                        </div>
                    </div>
                </div>

                <ResumenGeneral resumen={data?.resumen_general} />

                <TipoPiezasRanking tipoPiezas={data?.tipo_piezas_dataset} />
            </div>
        )
}