import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Collapsible from 'react-collapsible';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import LightBoxImages from "../../../../../../../../../../../../../shared/components/LightBoxImages";
import { checkDisabledProveedor, isOnVacation } from "../../../../../../../../../../../../../shared/helpers/functionalities";
import config from '../../../../../../../../../../../../../../../../config';

export default function ResultsList({
    results,
    onSelectResult
}) {
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    
    const [currentPieza, setCurrentPieza] = useState(null);
    const [openImage, setOpenImage] = useState(false);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
    }, []);

    const handleCloseImages = () => {
        setOpenImage(false);
        setImagesSRCs(null);
        setImageIndex(null);
        setCurrentPieza(null);
    }

    const getTipo = (tipo) => { 
        tipo = tipo.includes(' / ') ? tipo.split(' / ')[0] : tipo;
        return tipoPiezas.find(tp => tp.nombre.toLowerCase()?.includes(tipo.toLowerCase()))?.id; 
    }

    const getDescuento = (result) => {
        let descuentos = result.proveedor?.descuentos?.length > 0 ? result.proveedor?.descuentos.sort((a, b) => b.tipo_piezas?.length - a?.tipo_piezas.length) : result.proveedor?.descuentos;
        let descuento = descuentos?.length === 1 ? descuentos[0] : descuentos?.find(f => {
            let isIn = true;

            if(f.tipo_piezas && f.tipo_piezas.length > 0 ) {
                let tipoPiezaId = getTipo(result.nombre);
                let tipo = tipoPiezas.find(tp => tp.id === parseInt(tipoPiezaId));
                isIn = tipo ? f.tipo_piezas.find(f => f.id === parseInt(tipo.id)) : false;
            }

            if(f.max !== null && f.min !== null) {
                return (parseInt(result.precio) <= f.max && parseInt(result.precio) >= f.min && isIn)
            } else if(f.min !== null && f.max === null) {
                return (parseInt(result.precio) >= f.min && isIn)
            } else if(f.max !== null && f.min === null) {
                return (parseInt(result.precio) <= f.max && isIn)
            } else return null;
        });

        return descuento;
    }

    const getImage = (origen, img) => { return config.recomotor.ftpApiUrl + origen + "?ruta=" + img + '&w=500&h=500'; }

    const listResultImages = (pieza) => {
        let imgs = "";
        

        if(pieza.imgs.length > 0) {
            imgs = pieza.imgs.map(img => {
                const isCDN = pieza.prov !== "recomotor";
                const isS3 = pieza.imgs_s3;
                const imgUrl = (isCDN || isS3) ? img  : getImage(pieza.origen,img);

                return (
                    <img
                       key={'image-' + pieza.origen + '-' + img} 
                       onClick={() => showImage(imgUrl, pieza.imgs, pieza.origen, isCDN, isS3, pieza)} 
                       src={imgUrl} 
                       height="150px" 
                       alt={pieza.origen + ' ' + pieza.referencia_pieza}
                       style={{cursor: 'pointer', marginRight: '8px'}}
                   />
                )
             })
        }

        return imgs;
    }

    const showImage = (src, images, origen, isCDN, isS3, pieza) => { 
        if(src) {
          let srcS = images.map(img => (isCDN || isS3) ? img : getImage(origen, img));
          setImagesSRCs(srcS);
          let index = srcS.findIndex(img => img === src);
          setImageIndex(index);
          setOpenImage(!openImage);
          setCurrentPieza(pieza);
        }
    };

    const getModeloDates = (pieza) => {
        return <div>
            { pieza.anopieza > 0 ? 
                <span> Año pieza: {pieza.anopieza}</span>
                : ''
            }
            { (pieza.modeloinicio > 0) ? <span>{pieza.modeloinicio}</span>
                : (pieza.modeloinicio > 0 && pieza.modelofin > 0) ?
                    <span> - {pieza.modelofin}</span>
                : (pieza.modelofin > 0) ? <span>{pieza.modelofin}</span>
                : ''
            }
        </div>
    }

    return (
        <div className="w-100 d-flex flex-column mt-4">
           {results.piezas.length > 0 ? 
            <div className="w-100 d-flex flex-column">
                <small className="align-self-end mb-4">
                    <b>{results.total_items} {results.total_items > 1 ? 'resultados' : 'resultado'}</b>
                </small>
                {results.piezas.map((result) => {
                    return (
                        <>
                        <div className="w-100 cliente-card position-relative d-flex align-items-end" key={'result-' + result.id + '-' + result.origen}>
                            <div className="w-100 d-flex flex-column align-items-start mb-2">
                                <div className="flex-grow-1 cliente-card-info">
                                    { result.presupuestos_integraciones?.length > 0 ?
                                        <small className="text-uppercase fw-bold mb-2">
                                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                                            &nbsp; Pieza añadida en otro presupuesto &nbsp;
                                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                                        </small>
                                        : ''
                                    }

                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="w-100">
                                            <p style={{fontSize: '110%'}}>
                                                <b>{result.referencia_pieza}</b> - {result.nombre} {result.marca} {result.modelo}
                                            </p>

                                            <div className="w-100 d-flex">
                                                <small className="text-uppercase"> { result.proveedor.nombre}</small>

                                                <div className="d-flex">
                                                    { result.proveedor.credito ?
                                                        <p className="cliente-card-small fw-bold text-uppercase ms-2">A crédito</p>
                                                        : ''
                                                    }
                                                    { result.proveedor?.credito && result.proveedor?.integracion ? <p className="mx-2">|</p> : ''}
                                                    { result.proveedor?.integracion ?
                                                        <p className="cliente-card-small fw-bold text-uppercase">Integrado</p>
                                                        : ''
                                                    }
                                                </div>
                                                
                                                <p>
                                                    <b className="text-uppercase mx-2">(Score: {result.proveedor.score ? result.proveedor.score : '-'})</b>
                                                    { getDescuento(result)?.descuento ? 
                                                        (<b className="text-green text-uppercase">descuento {getDescuento(result)?.descuento}%</b>) 
                                                        : <b className="text-danger text-uppercase">no hay descuento</b>
                                                    } 
                                                </p>
                                            </div>
                                            <small>ID desguace: <i>{result.id_desguace}</i>. Estado: <i>{result.ubicacion}</i></small> 
                                        </div>
                                    </div>
                                    
                                    <p className="fw-bold mt-1" style={{"marginTop": "-0.25rem"}}>{result.familia} <b>{result.peso > 0 ? `${parseFloat(result.peso).toFixed(2)} KG` : ""}</b></p>
                                    <p className="mt-1" style={{'fontSize' : '100%', "color": "#222"}}>
                                        {result.notapublica}
                                    </p>
                                    { getModeloDates(result) }
                                </div>

                                { (result.imgs && result.imgs?.length > 0) && 
                                <Collapsible className="mt-3" contentInnerClassName="collapsable-content-img" trigger={"Ver imagenes"} triggerWhenOpen="Cerrar" lazyRender={false}>
                                    <div className="w-100 d-flex flex-wrap justify-content-start">
                                        { listResultImages(result)}
                                    </div>
                                </Collapsible> }
                            </div>
                            
                            <div className="d-flex flex-column align-items-end">
                                <small className="text-uppercase fw-bold">{result.prov}</small>
                                <span className="my-1"><b>{parseInt(result.precio) === 0 ? 'A consultar' : result.precio_venta + '€'}</b></span>
                                <div className="d-flex align-items-center">
                                    { (isOnVacation(result.proveedor)) &&
                                        <FontAwesomeIcon icon={faUmbrellaBeach} size="1x" className="me-2" color="#dc3545" />
                                    }

                                    <button 
                                        type="button" 
                                        className="btn btn-sm btn-primary"
                                        disabled={checkDisabledProveedor(result.proveedor)}
                                        onClick={() => onSelectResult(result)}
                                    >Seleccionar</button>
                                </div>
                                
                            </div>
                        </div>

                        <hr />
                        </>
                    )
                })}
            </div>
            : <p>No hay resultados.</p>
           }


           { (openImage) &&
                <LightBoxImages
                    images={imagesSRCs}
                    startIndex={imageIndex}
                    pieza={currentPieza}
                    onClose={handleCloseImages}
                />
            }
        </div>
    );
}