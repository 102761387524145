import { useState, useEffect } from "react";
import React from "react";
import moment from 'moment';
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FacturasList({
    facturas,
    onDownload
}) {
   
    useEffect(() => {
    }, [facturas]);
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div className="table-responsibe">
                <table className="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-center">fecha</th>
                            <th className="text-center">tipo</th>
                            <th className="text-center">total</th>
                            <th className="text-center">IVA</th>
                            <th className="text-center">total IVA</th>
                            <th className="text-center">acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        { facturas?.map((factura) => {
                            return (
                                <tr key={'factura-' + factura.id}>
                                    <td>{factura.numero}</td>
                                    <td className="text-center">{moment(factura.fecha).format('DD-MM-YYYY')}</td>
                                    <td className="text-center">{factura.bastidor !== "" ? 'AUTO' : 'MANUAL'}</td>
                                    <td className="text-center">{factura.total}€</td>
                                    <td className="text-center">{factura.iva}%</td>
                                    <td className="text-center">{factura.total_iva}€</td>
                                    <td className="text-center">
                                        <span title="Descargar PDF" style={{cursor: 'pointer'}} onClick={() => onDownload(factura)}>
                                            <FontAwesomeIcon icon={faFilePdf} size="1x" className="action" color="#215732"></FontAwesomeIcon>
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}