import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AutocompleteSelect from "../../../../../shared/components/AutocompleteSelect";
import { newServicioEnvio, updateServicioEnvio } from "../../../../../shared/services/serviciosEnvio";

export default function ServicioEnvioForm({servicio, transportistaId, isEdit, onCancel, onActionDone}) {
    const dispatch = useDispatch();
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            id: 0,
            nombre: '',
            codigo: '',
            precio_base: 0,
            tipo_piezas: []
        }
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });

    useEffect(() => {
        reset(servicio);
    }, [servicio]);

    const handleSubmitServicio = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});
        let response;

        if(data.tipo_piezas && data.tipo_piezas?.length > 0) {
            const piezasIds = data.tipo_piezas.map(p => p.id);
            data.tipo_piezas = piezasIds;
            data.tipo_piezas = piezasIds.join(',');
        }

       if(isEdit) {
            response = await updateServicioEnvio(data);
        } else {
            data.transportista_id = transportistaId;
            response = await newServicioEnvio(data);
        }

        if(response && response.data) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onActionDone();
        }
    }

    const handleChangeTipoPiezas = (tipoPiezas) => { setValue('tipo_piezas', tipoPiezas); }

    const handleCancel = () => onCancel('valor');
    
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitServicio)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? servicio?.id : ''} />
                <input 
                    type="hidden" 
                    {...register("transportista_id")} 
                    defaultValue={isEdit ? servicio?.transportista_id : ''} />

                <div className="w-100 d-flex flex-grow-1">
                    <div className="form-group flex-grow-1 mb-0 me-3">
                        <label htmlFor="nombre">Nombre</label>
                        <input 
                            type="text" 
                            className="form-control"
                            {...register("nombre", { required: true })} 
                            defaultValue={isEdit ? servicio?.nombre : 0} />
                        
                        <div className="form-invalid">
                            {errors.nombre && "Nombre es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1 mb-0 me-3">
                        <label htmlFor="codigo">Código</label>
                        <input 
                            type="text" 
                            className="form-control"
                            {...register("codigo", { required: true })} 
                            defaultValue={isEdit ? servicio?.codigo : 0} />
                        
                        <div className="form-invalid">
                            {errors.codigo && "Código es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="precio_base">Precio base:</label>
                        <div className="position-relative">
                            <input 
                                type="number" 
                                step="0.01"
                                className="form-control" 
                                min={0}
                                {...register("precio_base", { required: true })} 
                                defaultValue={isEdit ? servicio?.precio_base : 0} />
                            <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                        </div>
                        
                        <div className="form-invalid">
                            {errors.precio_original && "Precio original es obligatorio y tiene que ser más de 0"}
                        </div>
                    </div>
                </div>

                
                

                <div className="w-100 d-flex">
                    <div className="form-group flex-grow-1">
                        <label>Tipo de piezas</label>
                        <AutocompleteSelect 
                            options={tipoPiezas}
                            id={'tipo_piezas'}
                            selectedValues={isEdit ? servicio?.tipo_piezas : []}
                            onSetValue={handleChangeTipoPiezas}
                        />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    <button type="button" className="btn btn-default align-self-end ms-3" onClick={handleCancel}>Cancelar</button>
                </div>
            </form>
        </div>
    )
}