import React, { useState } from "react";
import ContactosList from "./components/ContactosList";


export default function EntidadContacto({
    contactos,
    onEditContacto
}) {
    
    return (
        <div className="w-100 sheet">
            <ContactosList 
                contactos={contactos}
                onEditContacto={onEditContacto}
            />
        </div>
    );
}