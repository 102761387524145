import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function PresupuestoForm({
    presupuestoId,
    presupuesto,
    observaciones,
    bastidor,
    onSubmit
}) {
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
        defaultValues: {
            bastidor: bastidor || "",
            observaciones: observaciones || ""
        }
    });
    const [isDisabledCreateNew, setIsDisabledCreateNew] = useState(true);

    useEffect(() => {
        checkIfValidPresupuesto(presupuesto);
    }, [bastidor, presupuesto]);

    const checkIfValidPresupuesto = (presu) => {
        if(presupuestoId > 0) {
            if(!presu.es_venta) {
                checkPresupuesto(presu);
            } else setIsDisabledCreateNew(false);
        } else {
            checkPresupuesto(presu);
        }
    }

    const checkPresupuesto = (presu) => {
        if(presu.piezas?.length === 0
            || presu.piezas?.find(p => p.servicio_envio === null || p.servicio_envio === undefined)
            || getValues('bastidor') === ""
        ) setIsDisabledCreateNew(true);
        else setIsDisabledCreateNew(false);
    }

    const handleChangeObservaciones = (e) => {
        const value = e.target.value;
        setValue('observaciones', value);

        if(presupuestoId > 0) {
            const submitBtn = document.getElementById('submit');
            submitBtn.style.opacity = 1;
        }
    }

    const handleChangeBastidor = (e) => {
        const value = e.target.value;
        setValue('bastidor', value);
       
        if(presupuestoId > 0) {
            const submitBtn = document.getElementById('submit');
            submitBtn.style.opacity = 1;
        }

        checkIfValidPresupuesto(presupuesto);
    }

    const handleNoFacilita = () => {
        setValue('bastidor', 'No facilita');

        if(presupuestoId > 0) {
            const submitBtn = document.getElementById('submit');
            submitBtn.style.opacity = 1;
        }

        checkIfValidPresupuesto(presupuesto);
    }

    const handleSubmitForm = (data, e) => {
        e.preventDefault();
        onSubmit({
            id: presupuestoId,
            codigo_autorizacion:  data.codigo_autorizacion !== '' ? data.codigo_autorizacion : null,
            observaciones:  data.observaciones !== '' ? data.observaciones : null,
            bastidor: data.bastidor,
        });
    }

    return (
        <form className="form col-12 d-flex flex-column align-items-center" onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="w-100 d-flex align-items-center mb-4">
                <div className="flex-grow-1 d-flex flex-column align-items-stretch">
                    <label htmlFor="observaciones">Bastidor del vehículo:</label>
                    <input
                        type="text"
                        className="w-100"
                        {...register("bastidor", {required: true})}
                        onChange={(e) => handleChangeBastidor(e)}
                        defaultValue={bastidor ? bastidor : ""}
                    />
                </div>

                <button type="button" className="btn btn-warning ms-3 mt-3" onClick={() => handleNoFacilita()}>No facilita</button>
            </div>

            <div className="form-group">
                <label htmlFor="observaciones">Observaciones internas:</label>
                <textarea
                    {...register("observaciones")}
                    onChange={(e) => handleChangeObservaciones(e)}
                    defaultValue={observaciones ? observaciones : ""}
                ></textarea>
            </div>

            {presupuesto?.observaciones_cliente && <div className="form-group">
                <label htmlFor="observaciones">Observaciones cliente:</label>
                <textarea value={presupuesto.observaciones_cliente} disabled />
            </div>}

            <div className="w-100">
                { (presupuestoId === 0 && isDisabledCreateNew) &&
                    <div className="alert alert-danger" role="alert">
                        <b>Presupuesto no válido.</b> Seguramente te falte asignar algún envio o añadir el bastidor del vehículo.
                    </div>
                }

                { (presupuestoId > 0) ?
                    <button 
                        type="submit"
                        id="submit"
                        style={{opacity: 0}}
                        className="btn btn-primary"
                    >Actualizar</button>
                    :
                    <button 
                        type="submit" 
                        disabled={isDisabledCreateNew}
                        className="btn btn-primary"
                    >Crear Presupuesto</button>
                }
            </div>
        </form>
    )
}