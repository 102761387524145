import { useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import { fetchTransportistas } from "../../../../../../redux/transportistas";
import { deleteTransportista } from "../../../shared/services/transportistas";
import { deleteServicioEnvio } from "../../../shared/services/serviciosEnvio";
import TransportistaForm from "./components/TransportistaForm";
import ServicioEnvioForm from "./components/ServicioEnvioForm";
import ServiciosTable from "./components/ServiciosTable";

export default function Transportistas() {
    const dispatch = useDispatch();
    const [transportistaId, setTransportistaId] = useState(null);
    const [currentTransportista, setCurrentTransportista] = useState(null);
    const [currentServicio, setCurrentServicio] = useState(null);
    const [isViewServicios, setIsViewServicios] = useState(false);
    const [isEditTransportista, setIsEditTransportista] = useState(false);
    const [isNewTransportista, setIsNewTransportista] = useState(false);
    const [isEditServicio, setIsEditServicio] = useState(false);
    const [isNewServicio, setIsNewServicio] = useState(false);
    const [isForDelete, setIsForDelete] = useState(false);
    const transportistas = useSelector(state => {
        return state.transportistas
    });

    const viewServicios = (transportista) => {
        setTransportistaId(transportista.id);
        setIsViewServicios(true);
    }

    const handleEditElement = (element, elementValue) => {
        if(element === 'transportista') {
            setCurrentTransportista(elementValue);
            setIsEditTransportista(true);
        } else {
            setIsEditServicio(true);
            const servicio = transportistas.find(t => t.id === transportistaId).servicios.find(v => v.id === elementValue);
            setCurrentServicio(servicio);
        }
    }

    const handleCancelElement = (element) => { 
        if(element === 'transportista') {
            if(isEditTransportista) setIsEditTransportista(false); 
            else setIsNewTransportista(false);
    
            setCurrentTransportista(null);
        } else {
            if(isEditServicio) setIsEditServicio(false); 
            else setIsNewServicio(false);
    
            setCurrentServicio(null);
        }
    }

    const newElement = (element) => {
        if(element === 'transportista') {
            setIsEditTransportista(false);
            setIsNewTransportista(true);
        } else {
            setIsEditServicio(false); 
            setIsNewServicio(true);
        }
    }

    const handleActionDone = () => {
        dispatch(fetchTransportistas());
        setIsNewTransportista(false);
        setIsNewServicio(false);
        setIsEditTransportista(false);
        setIsEditServicio(false);
    }

    const handleDeleteElement = async(element, elementId) => {
        let response;
        dispatch({type: 'loading/set', payload: true});

        if(element === 'transportista') {
            response = await deleteTransportista(elementId);
            setTransportistaId(null);
            setCurrentTransportista(null);
        } else {
            response = await deleteServicioEnvio(elementId);
            setCurrentServicio(null);
        }

        if(response && response.data) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchTransportistas());
        }
    }

    const handleDeleteTarifa = (id) => { 
        setTransportistaId(id);
        setIsViewServicios(false);
        setIsForDelete(true); 
    }

    const handleConfirmDelete = (confirm) => {
        if(confirm) {
            handleDeleteElement('transportista', transportistaId)
        } else setIsForDelete(false);
    }

    return (
        <div className="w-100 d-flex flex-wrap flex-md-nowrap">
            <div className="col-12 col-md-6 d-flex flex-column align-items-end pe-0 pe-md-4">
                <button type="button" className="btn btn-add ms-0 ms-md-5" onClick={() => newElement('transportista')}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>

                <div className="w-100 d-flex flex-column mt-4">
                    { transportistas.map(transportista => {
                        return (
                            <div className="w-100 d-flex flex-column" key={'tarifa-' + transportista.id}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="m-0">{transportistaId === transportista.id ? <b>{transportista.nombre}</b> : transportista.nombre}</p>
                                    <div>
                                        <span title="Ver valores" style={{cursor: 'pointer'}} className="me-3">
                                            <FontAwesomeIcon icon={faEye} size="1x" className="action" color="#215732" onClick={() => viewServicios(transportista)} />
                                        </span>
                                        <span title="Editar" style={{cursor: 'pointer'}} className="me-3">
                                            <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleEditElement('transportista', transportista)} />
                                        </span>
                                        <span title="Eliminar" style={{cursor: 'pointer'}}>
                                            <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" onClick={() => handleDeleteTarifa(transportista.id)} />
                                        </span>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        )
                    }) }
                </div>

                <div className="w-100">
                    { (isEditTransportista || isNewTransportista) &&
                        <TransportistaForm 
                            transportista={currentTransportista}
                            isEdit={isEditTransportista}
                            onActionDone={handleActionDone}
                            onCancel={handleCancelElement}
                        />
                    }
                </div>
            </div>

            <div className="col-12 col-md-6 d-flex flex-column align-items-end ps-0 ps-md-4 mt-5 mt-md-0">
                <button type="button" className="btn btn-add" onClick={() => newElement('servicio')}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>
                
                <div className="w-100 d-flex flex-column mt-4">
                    { (transportistaId && isViewServicios) &&
                        <ServiciosTable 
                            servicios={transportistas.find(t => t.id === transportistaId)?.servicios}
                            onEdit={handleEditElement}
                            onDelete={handleDeleteElement}
                        />
                    }

                    { (isEditServicio || isNewServicio) &&
                        <ServicioEnvioForm 
                            servicio={currentServicio} 
                            transportistaId={transportistaId}
                            isEdit={isEditServicio}
                            onActionDone={handleActionDone}
                            onCancel={handleCancelElement}
                        />
                    }
                </div>
            </div>

            { isForDelete && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={isForDelete}>
                </ConfirmModal>
            }
        </div>
    )
}