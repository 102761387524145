import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { toast } from 'react-toastify';
import { deleteAlmacen, deleteDescuento, deleteTransportistaFav, deleteVacaciones, deleteVale, newAlmacen, newDescuento, newProveedor, newTransportistaFav, newVacaciones, newVale, updateAlmacen, updateDescuento, updateProveedor, updateProveedorPost, updateTransportistaFav, updateVacaciones, updateVale } from "../../views/Dashboard/components/shared/services/proveedores";

const initialProveedor = null;

export const proveedorReducer = (state = initialProveedor, action) => {
    switch(action.type){
        case "proveedor/fullfilled": {
            return action.payload;
        }
        case "proveedor/clear": {
            return initialProveedor;
        }

        // DESCUENTOS
        case "proveedor/add-descuento": {
            const newDescuentos = state.descuentos;
            newDescuentos.push(action.payload);
            return {...state, descuentos: newDescuentos }
        }
        case "proveedor/update-descuento": {
            const newDescuentos = state.descuentos;
            const index = newDescuentos.findIndex((descuento) => parseInt(descuento.id) === parseInt(action.payload.id));
            newDescuentos.splice(index, 1, action.payload);

           return {
                ...state,
                descuentos: newDescuentos,
            }
        }
        case "proveedor/delete-descuento": {
            const newDescuentos = state.descuentos;
            const index = newDescuentos.findIndex((descuento) => parseInt(descuento.id) === parseInt(action.payload));
            newDescuentos.splice(index, 1);
            return {...state, descuentos: newDescuentos };
        }

        // TRANSPORTISTAS
        case "proveedor/add-transportista": {
            const newTransportistas = state.transportistas_favoritos;
            newTransportistas.push(action.payload);
            return {...state, transportistas_favoritos: newTransportistas }
        }
        case "proveedor/update-transportista": {
            const newTransportistas = state.transportistas_favoritos;
            const index = newTransportistas.findIndex((transportista) => parseInt(transportista.id) === parseInt(action.payload.id));
            newTransportistas.splice(index, 1, action.payload);

           return {
                ...state,
                transportistas_favoritos: newTransportistas,
            }
        }
        case "proveedor/delete-transportista": {
            const newTransportistas = state.transportistas_favoritos;
            const index = newTransportistas.findIndex((transportista) => parseInt(transportista.id) === parseInt(action.payload));
            newTransportistas.splice(index, 1);
            return {...state, transportistas_favoritos: newTransportistas };
        }

        // ALMACENES
        case "proveedor/add-almacen": {
            const newAlmacenes = state.almacenes;
            newAlmacenes.push(action.payload);
            return {...state, almacenes: newAlmacenes }
        }
        case "proveedor/update-almacen": {
            const newAlmacenes = state.almacenes;
            const index = newAlmacenes.findIndex((almacen) => parseInt(almacen.id) === parseInt(action.payload.id));
            newAlmacenes.splice(index, 1, action.payload);

           return {
                ...state,
                almacenes: newAlmacenes,
            }
        }
        case "proveedor/delete-almacen": {
            const newAlmacenes = state.almacenes;
            const index = newAlmacenes.findIndex((almacen) => parseInt(almacen.id) === parseInt(action.payload));
            newAlmacenes.splice(index, 1);
            return {...state, almacenes: newAlmacenes };
        }

        // VACACIONES
        case "proveedor/add-vacaciones": {
            const newVacaciones = state.vacaciones;
            newVacaciones.push(action.payload);
            return {...state, vacaciones: newVacaciones }
        }
        case "proveedor/update-vacaciones": {
            const newVacaciones = state.vacaciones;
            const index = newVacaciones.findIndex((vacaciones) => parseInt(vacaciones.id) === parseInt(action.payload.id));
            newVacaciones.splice(index, 1, action.payload);

           return {
                ...state,
                vacaciones: newVacaciones,
            }
        }
        case "proveedor/delete-vacaciones": {
            const newVacaciones = state.vacaciones;
            const index = newVacaciones.findIndex((vacaciones) => parseInt(vacaciones.id) === parseInt(action.payload));
            newVacaciones.splice(index, 1);
            return {...state, vacaciones: newVacaciones };
        }

        // VALES
        case "proveedor/add-vale": {
            const newVales = state.vales;
            newVales.push(action.payload);
            return {...state, vales: newVales }
        }
        case "proveedor/update-vale": {
            const newVales = state.vales;
            const index = newVales.findIndex((vale) => parseInt(vale.id) === parseInt(action.payload.id));
            newVales.splice(index, 1, action.payload);

           return {
                ...state,
                vales: newVales,
            }
        }
        case "proveedor/delete-vale": {
            const newVales = state.vales;
            const index = newVales.findIndex((vale) => parseInt(vale.id) === parseInt(action.payload));
            newVales.splice(index, 1);
            return {...state, vales: newVales };
        }

        default:
            return state
    }
}

const asyncProveedor = makeAsyncTypes('proveedor');

const [setPending, setFullFilled, setError ] = asyncMac(asyncProveedor);

export const proveedorFetchingReducer = makeFetchingReducer([
    'proveedor/pending', 
    'proveedor/fullfilled', 
    'proveedor/rejected'
]);

export const saveProveedor = (proveedor) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await newProveedor(proveedor)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const editProveedor = (proveedor) => async dispatch => {
    dispatch(setPending());

    try {
        let response;

        if(proveedor instanceof FormData) {
            response = await updateProveedorPost(proveedor)
        } else {
            response = await updateProveedor(proveedor)
        }

        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const saveDescuento = (descuento) => async dispatch => {
    try {
        const response = await newDescuento(descuento)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/add-descuento', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const editDescuento = (descuento) => async dispatch => {
    try {
        const response = await updateDescuento(descuento)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/update-descuento', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const removeDescuento = (descuentoId) => async dispatch => {
    try {
        const response = await deleteDescuento(descuentoId)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/delete-descuento', payload: descuentoId});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const saveTransportista = (transportista) => async dispatch => {
    try {
        const response = await newTransportistaFav(transportista)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/add-transportista', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const editTransportista = (transportista) => async dispatch => {
    try {
        const response = await updateTransportistaFav(transportista)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/update-transportista', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const removeTransportista = (transportistaId) => async dispatch => {
    try {
        const response = await deleteTransportistaFav(transportistaId)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/delete-transportista', payload: transportistaId});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const saveAlmacen = (almacen) => async dispatch => {
    try {
        const response = await newAlmacen(almacen)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/add-almacen', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const editAlmacen = (almacen) => async dispatch => {
    try {
        const response = await updateAlmacen(almacen)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/update-almacen', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const removeAlmacen = (almacenId) => async dispatch => {
    try {
        const response = await deleteAlmacen(almacenId)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/delete-almacen', payload: almacenId});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const saveVacaciones = (vacaciones) => async dispatch => {
    try {
        const response = await newVacaciones(vacaciones)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/add-vacaciones', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const editVacaciones = (vacaciones) => async dispatch => {
    try {
        const response = await updateVacaciones(vacaciones)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/update-vacaciones', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const removeVacaciones = (vacacionesId) => async dispatch => {
    try {
        const response = await deleteVacaciones(vacacionesId)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/delete-vacaciones', payload: vacacionesId});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const saveVale = (vale) => async dispatch => {
    try {
        const response = await newVale(vale)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/add-vale', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const editVale = (valeId, vale) => async dispatch => {
    try {
        const response = await updateVale(valeId, vale)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/update-vale', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const removeVale = (valeId) => async dispatch => {
    try {
        const response = await deleteVale(valeId)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'proveedor/delete-vale', payload: valeId});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
    }
}

export const currentProveedorReducer = proveedorReducer;
export const currentProveedorStatusReducer = proveedorFetchingReducer;