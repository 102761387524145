import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EnvioForm from "./components/EnvioForm";

export default function EnvioModal({
    open,
    cliente,
    selectedDireccionId,
    pieza,
    onSubmit,
    onClose
}) {
    const [direccionEnvio, setDireccionEnvio] = useState(null);
    const [currentServicioEnvio, setCurrentServicioEnvio] = useState(null);

    const presupuesto = useSelector(state => {
        return state.presupuesto.entity
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });

    useEffect(() => {
        if(selectedDireccionId) {
            const direccion = cliente?.direcciones.find(d => d.id === selectedDireccionId);

            setDireccionEnvio(direccion);
        }

        if(pieza) {
            const servicio = presupuesto?.servicios_envio?.find(f => f.pieza_id === pieza.id);
            
            if(servicio) { setCurrentServicioEnvio(servicio); }
        }
    }, [pieza, selectedDireccionId]);

    const handleCloseModal = () => onClose();

    const getTipoPieza = (tipoPiezaId) => tipoPiezas.find(tp => tp.id === tipoPiezaId)

    const getMedidasPieza = (pieza) => { 
        const tipo = getTipoPieza(parseInt(pieza.tipo_pieza_id));
        return `${tipo.alto} x ${tipo.largo} x ${tipo.ancho}`; 
    }

    const getPesoTotal = () => {
        const tipo = getTipoPieza(parseInt(pieza.tipo_pieza_id));
        const totalVolumetrico = ((tipo.alto * tipo.largo * tipo.ancho) / 4000);
        const totalPeso = parseFloat(tipo.peso);

        if (totalPeso > totalVolumetrico) return totalPeso; 
        else return totalVolumetrico;
    }

    const handleSubmitEnvio = (data) => {
        if(currentServicioEnvio) { data.id = currentServicioEnvio.id; }
        onSubmit(data);
    }

    return (
        <Dialog
            open={open}
            onClose={handleCloseModal}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="envio-form-title"
            aria-describedby="envio-form-description">
        <DialogTitle id="envio-form-title">
            <div className="w-100 d-flex align-items-center justify-content-between">
                <p className="fw-bold text-uppercase">Nuevo Envío</p>

                <span title="cerrar" className="action" onClick={() => handleCloseModal()}>
                    <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                </span>
            </div>
           
        </DialogTitle>
        <DialogContent>
            <div className="w-100 d-flex flex-column justify-content-between text-uppercase">
                <p className="mb-1">origen: <b>{pieza?.proveedor?.nombre}</b></p>

                <div className="w-100 d-flex align-items-center justify-content-between">
                    <small>PIEZA: <b>{getTipoPieza(pieza.tipo_pieza_id)?.nombre}</b> </small>
                    <small>Peso total: <b>{getPesoTotal(pieza)} KG</b> </small>
                    <small>Medidas: {getMedidasPieza(pieza)} CM</small>
                </div>
            </div>
            
            <EnvioForm 
                cliente={cliente}
                pieza={pieza}
                direccionEnvio={direccionEnvio}
                onSubmit={handleSubmitEnvio}
            />
        </DialogContent>
    </Dialog>
    )
}