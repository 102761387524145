import { useEffect, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { editVale, saveVale } from "../../../../../../../../redux/features/currentProveedor";

export default function ValeForm({
    vale,
    proveedorId, 
    isEdit,
    onActionDone
}) {
    const dispatch = useDispatch();
    const proveedorSchema = yup.object({
        total: yup.number().integer().min(1, 'El mínimo del total tiene que ser superior a 0').required("El total es obligatorio").nullable(),
      }).required();

    const { register, formState: { errors }, handleSubmit, reset} = useForm({
        resolver: yupResolver(proveedorSchema),
        mode: "onChange",
        defaultValues: {
            id: 0,
            total: 0,
            vale: '',
            proveedor_id: proveedorId
        }
    });

    useEffect(() => {
        if(vale) reset(vale);
    }, [vale]);

    const handleSubmitVale = async (data, e) => {
        e.preventDefault();
        if(!isEdit) { delete data.id; }

        let dataAux = null;

        if(data.vale.length > 0) {
            dataAux = new FormData();
            Object.entries(data).forEach((value) => {
                if((value[0] === 'vale')) {
                    if(value[1]?.length > 0) {
                        dataAux.append(value[0], value[1][0])
                    }
                } else { dataAux.append(value[0], value[1]) }
            });
        } else {
            dataAux = data;
        }

        if(isEdit) {
            dispatch(editVale(data.id, dataAux));
        } else {
            dispatch(saveVale(dataAux));
        }

        onActionDone();
    };

    
    return (
        <div className="w-100 d-flex">
            <form className="form w-100" onSubmit={handleSubmit(handleSubmitVale)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? vale.id : ''} />
                <input 
                    type="hidden" 
                    {...register("proveedor_id")} 
                    defaultValue={isEdit ? vale.proveedor_id : proveedorId} />

                <div className="form-group flex-row align-items-center">
                    <label className="m-0 me-2">Habilitado:</label>
                    <input 
                        type="checkbox"
                        defaultChecked={isEdit ? vale.active : true}
                        {...register("active")} />
                </div>

                <div className="w-100 d-flex flex-column align-items-center">
                    <div className="input-group align-items-center">
                        <label>Total:</label>
                        <div className="position-relative w-100">
                            <input 
                                className="form-control"
                                type="number" 
                                step="0.01"
                                {...register("total", {required: true})} 
                                defaultValue={vale ? vale.total : 0} />
                            <span className="text-success icon">
                                <b>€</b>
                            </span>
                        </div>

                        <div className="form-invalid">
                            {(errors.total) && errors.total?.message}
                        </div>
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="vale">Foto del vale</label>
                        <input 
                            type="file" 
                            className="form-control" 
                            {...register("vale", { required: true, maxLength: 255 })} 
                            defaultValue={''} />

                        <div className="form-invalid">
                            {(errors.vale) && errors.vale?.message}
                        </div>
                    </div>
                

                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
           
    );
}