

export const loadingReducer = (state = false, action) => {
    switch(action.type){
        case "loading/set": {
            return action.payload;
        }
        default:
            return state
    }
}