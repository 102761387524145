import React, { useEffect } from "react";
import { useState } from "react";
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import Advanced from "./components/Advanced";

export default function Searcher({
    detalleTab,
    pageName,
    onChange,
}) {
    const searcher = useSelector(state => {
        return state.search
      })
    const [reset, setReset] = useState(false);

    useEffect(() => {
    }, [searcher, detalleTab]);

    const handleReset = () => { 
        setReset(true); 
        handleSearch({});
    }

    const handleSearch = (searchData) => {
        onChange('', searchData);
    };

    return (
        <div className="w-100 d-flex flex-wrap flex-lg-nowrap align-items-stretch justify-content-between mt-2"> 
            <Advanced 
                reset={reset}
                pageName={pageName}
                detalleTab={detalleTab}
                onChange={handleSearch} />

            <div className="d-flex mt-3 mt-lg-0 align-items-center justify-content-end">
                <span className="d-block mt-3" title="Reset">
                    <FontAwesomeIcon icon={faRedo} color="#dc3545" className="icon ms-3" style={{cursor: 'pointer'}} onClick={() => handleReset()} />
                </span>
            </div>
        </div> 
    );
}