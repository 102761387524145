import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { updateIncidencia } from "../../../../../shared/services/incidencias";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

export default function PerdidaForm({
    incidencia
}) {
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm({
        defaultValues: {
            perdida: incidencia?.perdida ? incidencia?.perdida : 0,
            perdida_comercial: incidencia?.perdida_comercial ? incidencia?.perdida_comercial : 0,
            observaciones: incidencia?.observaciones ? incidencia?.observaciones : ''
        }
    });

    useEffect(() => {
    }, [incidencia]);

    const handleSubmitIncidencia = async (data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});

        const response = await updateIncidencia(incidencia.id,data);

        if(response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
        }
    };
 
    return (
        <div className="flex-grow-1 d-flex flex-column justify-content-center">
            <form className="form" onSubmit={handleSubmit(handleSubmitIncidencia)}>
                <div className="w-100 d-flex align-items-end">
                    { (incidencia?.culpa === 'recomotor') &&
                        <div className="input-group align-items-center me-3">
                            <label className="me-3">Pérdida generada por el recanvista:</label>
                            <div className="position-relative w-100">
                                <input 
                                    className="form-control"
                                    type="number" 
                                    step="0.01"
                                    {...register("perdida" )} 
                                    defaultValue={incidencia?.perdida ? incidencia?.perdida : 0} />
                                <span className="text-success icon">
                                    <b>€</b>
                                </span>
                            </div>
                        </div>
                    }

                    <div className="input-group align-items-center me-3">
                        <label className="me-3">Pérdida por atención comercial:</label>
                        <div className="position-relative w-100">
                            <input 
                                className="form-control"
                                type="number" 
                                step="0.01"
                                {...register("perdida_comercial" )} 
                                defaultValue={incidencia?.perdida_comercial ? incidencia?.perdida_comercial : 0} />
                            <span className="text-success icon">
                                <b>€</b>
                            </span>
                        </div>
                    </div>

                    <div className="form-group m-0">
                        <label htmlFor="observaciones">Observaciones</label>
                        <textarea
                            {...register("observaciones")}
                            defaultValue={incidencia.observaciones ? incidencia.observaciones : ""}
                        ></textarea>
                    </div>

                    <button type="submit" className="btn btn-primary ms-2">Guardar</button>
                </div>
            </form>
        </div>
    );
}