export const getActions = (item, hasAvisos) => {
    const actions = {
        actions: [
            {
                label: 'Ver detalles',
                icon: 'details',
                action: 'details'
            },
            {
                label: 'Editar',
                icon: 'edit',
                action: 'edit'
            },
            {
                label: 'Crear envío',
                icon: 'shipment',
                action: 'shipment'
            },
            {
                label: 'Cambiar responsable',
                icon: 'user',
                action: 'change-responsable'
            },
            {
                label: 'Cargar documentos',
                icon: 'upload',
                action: 'upload'
            },
            {
                label: hasAvisos ? "Editar aviso" : "Crear aviso",
                icon: hasAvisos ? "edit" : "add",
                action: 'avisos'
            },
        ],
        extra: []
    }

    if(item.estado_incidencia_id === 1) {
        actions.actions.push({
            label: 'Cambiar Subestado',
            icon: 'status',
            action: 'status'
        })
    }

    if(item.estado_incidencia_id !== 3) {
        actions.actions.push({
            label: 'Finanzas',
            icon: 'pay',
            action: 'finance'
        })
    }

    if(item.estado_incidencia_id !== 1) {
        if(item.estado_incidencia_id === 4) {
            actions.actions.push({
                label: 'Validar',
                icon: 'check',
                action: 'reactivate'
            })
        } else {
            actions.actions.push({
                label: 'Reactivar',
                icon: 'enable',
                action: 'reactivate'
            })
        }
    }
    

    if(item.estado_incidencia_id !== 2) {
        actions.actions.push({
            label: 'Finalizar',
            icon: 'done',
            action: 'finish'
        })
    }
    

    actions.actions.push({
        label: 'Eliminar',
        icon: 'delete',
        action: 'delete'
    })

    actions.actions.push({
        label: 'Enlace encuesta',
        icon: 'link',
        action: 'survey-link'
    })

    return actions;
}