import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import moment from "moment";
import ActionsMenu from "../../../shared/components/ActionsMenu";
import { getActions } from "./actions";
import InfoModal from "../../../shared/components/InfoModal";
import FinalizarForm from "./components/FinalizarForm";

export default function ItemsTable({
    id,
    currPage,
    devoluciones,
    onPageChange,
    onViewDetail,
    onAdminImages,
    onUpdate,
    onAssignVenta
}) {
    const [devolucionesList, setDevolucionesList] = useState([]);
    const [currentDevolucion, setCurrentDevolucion] = useState(null);
    const [openFinalizar, setOpenFinalizar] = useState(false);
 
    useEffect(() => {
        const list = devoluciones.devoluciones;
        setDevolucionesList(list);
    }, [id, currPage, devoluciones]);

    const handleChangePage = (event, newPage) => { onPageChange(newPage); }
  
    const getPaginationCount = () => {
        var totalPages = devoluciones.total_items < 15 ? 1 : Math.ceil(devoluciones.total_items / 15);
        return parseInt(totalPages);
    }

    const handleAction = (action, devolucion) => {
        if(action === 'details') {
            onViewDetail(devolucion);
        } else  if(action === 'assign') {
            onAssignVenta(devolucion);
        } else  if(action === 'images') {
            onAdminImages(devolucion)
        } else  if(action === 'disable') {
            setCurrentDevolucion(devolucion);
            setOpenFinalizar(true);
        } else if(action === 'enable') {
            onUpdate({id: devolucion.id, active: true})
        }
    }

    const handleFinalizar = (data) => {
        onUpdate({
            id: currentDevolucion.id, 
            active: false, 
            estado_pieza_id: data.estado_pieza_id
        })
        handleCloseFinalizar();
    }
    const handleCloseFinalizar = () => {
        setOpenFinalizar(false);
        setCurrentDevolucion(null);
    }
    

    return (
        <div className="w-100 d-flex flex-column align-items-end">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-center">fecha</th>
                            <th>Pieza</th>
                            <th>cliente</th>
                            <th className="text-center">factura</th>
                            <th className="text-center">estado</th>
                            <th className="text-center">resp</th>
                            <th className="text-center"></th>
                        </tr>
                    </thead>

                    <tbody>
                    { devolucionesList?.map((devolucion, index) => {
                        return (
                            <tr key={index}>
                                <td>{devolucion.numero}</td>
                                <td className="text-center">{moment(devolucion.fecha).format('DD-MM-YYYY')}</td>
                                <td>
                                    <div className="w-100 d-flex flex-column text-uppercase">
                                        <small className="w-100 d-flex flex-column">
                                            <span>{devolucion?.pieza?.nombre}</span>
                                            <span>ID <b>#{devolucion?.pieza?.id_referencia_plataforma}</b></span>
                                            { devolucion?.pieza?.casco > 0 ?
                                                <span className="fw-bold text-uppercase highlight"><small>contiene casco</small></span>
                                                : ''
                                            }
                                        </small>

                                        <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + devolucion?.pieza?.proveedor?.id} title="Ficha Proveedor" className="text-green text-uppercase">
                                            {devolucion?.pieza?.proveedor?.nombre}
                                        </Link>
                                    </div>
                                </td>
                                <td>
                                    { devolucion?.venta ?
                                        <div className="d-flex flex-column">
                                            <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + devolucion?.venta?.cliente?.id} title="Ficha Cliente" className="text-green text-uppercase">
                                                {devolucion?.venta?.cliente?.nombre}
                                            </Link>
                                            <small>{devolucion?.venta?.cliente?.telefono}</small>
                                        </div>
                                        : '-'
                                    }
                                </td>
                                <td className="text-center">
                                    { devolucion?.venta ?
                                        <small>{devolucion?.venta?.numero}</small>
                                        : '-'
                                    }
                                </td>
                                <td className="text-center text-uppercase">
                                    <span className={"fw-bold " +
                                        (devolucion?.estado_pieza_id === 1 ? 'text-danger'
                                        : devolucion?.estado_pieza_id === 2 || devolucion?.estado_pieza_id === 5 ? 'text-warning' 
                                        : devolucion?.estado_pieza_id === 3 || devolucion?.estado_pieza_id === 3 ? 'text-info'
                                        : 'text-default')
                                    }>{devolucion?.estado_pieza?.nombre}</span>
                                </td>
                                <td className="text-center text-uppercase">{devolucion.created_by.username}</td>
                                <td width={'5%'} className="text-center">
                                    <ActionsMenu 
                                        options={getActions(devolucion)}
                                        onAction={(action) => handleAction(action, devolucion)}
                                    />
                                </td>
                            </tr>
                        )
                        }) 
                    }
                    </tbody>
                </table>
            </div>
            
            <Pagination 
                id={id}
                className="mt-3" 
                count={getPaginationCount()} 
                page={currPage} 
                onChange={handleChangePage} 
            />


            { (openFinalizar) && 
                <InfoModal
                    state={openFinalizar}
                    title={"Finalizar devolución de mercancía " + currentDevolucion.numero}
                    content={
                        <FinalizarForm 
                            onSubmitFinalizar={handleFinalizar}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseFinalizar}
                ></InfoModal>
            }
        </div>
    )
}