import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { getAllowedExt } from "../../../../../shared/helpers/functionalities";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { downloadVehiculoInfo } from "../../../../../shared/services/facturas_desguaces";

export default function FacturaAutomaticaForm({
    onSubmitFacturaAutomatica
}) {
    const dispatch = useDispatch();
    const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({
        defaultValues: {
            precio: 0,
            bastidor: '',
            vehiculo_id: '',
            matricula: '',
            file: null,
            desguace_id: null
        }
    });
    const desguaces = useSelector(state => {
        const { desguaces } = state;
        return desguaces;
    });

    useEffect(() => {
        
    }, []);

    const handleDownloadVehiculoInfo = async() => {
        if(getValues('matricula') !== '') {
            dispatch({type: 'loading/set', payload: true});
            const csv = await downloadVehiculoInfo({
                matricula: getValues('matricula')
            });
    
            if(csv) {
                toast.success('Plantilla descargada correctamente!')
                dispatch({type: 'loading/set', payload: false});
                const url = window.URL.createObjectURL(new Blob([csv]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', (getValues('matricula') + '.csv'));
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error('Ups! Algo ha ido mal...')
            }
        } else {
            toast.warning('Tienes que introducir una matrícula antes de generar el CSV!')
        }
    }

    const handleChangeDesguace = (e) => {
        const value = parseInt(e.target.value);
        setValue('desguace_id', value);
    }

    const handleSubmitFactura = async(data, e) => {
        e.preventDefault();
        const dataAux = new FormData();

        Object.entries(data).forEach((value) => {
            if((value[0] === 'file')) {
                dataAux.append(value[0], value[1][0])
            } else { dataAux.append(value[0], value[1]) }
        });
        onSubmitFacturaAutomatica(dataAux);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form align-items-stretch mb-4" onSubmit={handleSubmit(handleSubmitFactura)}>
                <div className="w-100 d-flex align-items-center justify-content-end">
                    <div className="form-group me-3">
                        <input 
                            type="text"
                            className="form-control"
                            placeholder="Introduce una matrícula"
                            {...register("matricula")} 
                            defaultValue={''} />
                    </div>

                    <button type="button" className="btn btn-add mb-4" onClick={() => handleDownloadVehiculoInfo()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" />
                        </span>
                        Generar
                    </button>
                </div>

                <hr />

                <div className="w-100 d-flex flex-column flex-grow-1 mt-3">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="precio">Valor del vehículo (aplicaremos margen 20%)</label>
                        <input 
                            type="number" 
                            step={0.01}
                            className="form-control"
                            {...register("precio", { required: true })} 
                            defaultValue={0} />
                        
                        <div className="form-invalid">
                            {errors.precio?.type === 'required' && "El precio es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="bastidor">Bastidor</label>
                        <input 
                            type="text"
                            className="form-control"
                            {...register("bastidor", { required: true })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.bastidor?.type === 'required' && "El bastidor es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="vehiculo_id">ID vehículo</label>
                        <input 
                            type="text"
                            className="form-control"
                            {...register("vehiculo_id", { required: true })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.vehiculo_id?.type === 'required' && "El ID de vehículo es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="matricula">Fichero</label>
                        <input 
                            type="file"
                            className="form-control"
                            accept={getAllowedExt('csv')}
                            {...register("file", { required: true })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.file?.type === 'required' && "El fichero es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="rol">Desguace:</label>
                        { (desguaces && desguaces.length > 0) &&
                            <select 
                                {...register("desguace_id", { required: true })}
                                defaultValue={null}
                                onChange={handleChangeDesguace}>
                                {
                                    desguaces.map(op => <option key={'desguace-' + op.id} value={op.id}>{op.nombre}</option>)
                                }
                            </select>
                        }

                        <div className="form-invalid">
                            {errors.desguace_id?.type === 'required' && "El desguace es obligatorio"}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}