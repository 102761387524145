import axios from 'axios';
import config from '../config';

const tecDocAPI = axios.create({
  baseURL: config.recomotor.tecdocUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

export default tecDocAPI