import API from '../../../../../services/api';

export const getVehiculos = async (page, search) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/dashboard/vehiculos' + pagination + searcher).then(res => res.data);
}

export const getVehiculo = async (bastidor) => {
    return await API.get('/dashboard/vehiculos/' + bastidor).then(res => res.data);
}

export const exportVehiculos = async (data) => {
    return await API.post('/vehiculos/export', data, {responseType: 'blob'}).then(res => res.data);
}