import React, { useState } from "react";
import Searcher from "./components/Searcher";
import ResultsList from "./components/ResultsList";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import Filter from "./components/Filter";
import { getPiezasIntegraciones } from "../../../../../../../../../../../shared/services/piezas";
import { obtainCats } from "../../../../../../../../../../../../../../utils/categoriasmap";
import Loading from "../../../../../../../../../../../shared/components/Loading";

export default function PiezasSearcher({
    onResultSelected,
    onCancel
}) {
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const marcas = useSelector(state => {
        const { marcas } = state;
        return marcas;
    });
    const modelos = useSelector(state => {
        const { modelos } = state;
        return modelos;
    });

    const [filters, setFilters] = useState(null);
    const [results, setResults] = useState(null);
    const [currentPage, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const piezasNoGarantia = [
        61, 226, 230, 233, 234, 
        235, 236, 237, 238, 
        239, 240, 241, 242, 
        243, 244, 245, 246, 
        247, 248, 249, 251, 261,
        265, 266, 267, 450
    ];
    const piezasIrrompibles = [
        2, 3, 4, 5, 8, 9, 10,
        11, 14, 15, 16, 18, 19, 
        22, 23, 24, 25, 26, 27, 36, 37,
        52, 62, 65, 69, 70, 71, 72, 73,
        75, 77, 78, 80, 81, 82, 84, 85,
        86, 87, 88, 89, 91, 92, 94, 95,
        96, 97, 98, 99, 100, 101, 102, 103, 104,
        105, 107, 108, 109, 112, 113, 120, 121,
        126, 127, 144, 146, 147, 148, 149, 150,
        151, 155, 157, 158, 159, 160, 163, 164,
        166, 167, 168, 171, 172, 173, 174, 175,
        176, 177, 178, 179, 181, 182, 183, 184,
        185, 187, 189, 193, 195, 200, 202, 203,
        210, 215, 216, 217, 218, 224, 295, 196,
        297, 298, 299, 300, 301, 302, 303, 304,
        305, 306, 307, 308, 309, 310, 311, 312,
        313, 314, 315, 316, 317, 318, 319, 320,
        321, 322, 323, 324, 325, 326, 327, 328,
        329, 330, 331, 332, 333, 334, 335, 336,
        337, 338, 340, 341, 342, 343, 344, 346,
        347, 348, 349, 350, 351, 352, 353, 354,
        355, 356, 357, 359, 360, 361, 362, 375,
        379, 380, 384, 387, 388, 391, 392, 399,
        403, 405, 414, 415, 416, 418, 420, 421,
        422, 423, 424, 425, 426, 427, 428, 429,
        430, 431, 432, 433, 437, 438, 439, 441,
        442, 447, 451, 460, 461, 462, 463, 464,
        465, 466, 471, 472, 473, 474, 476, 477,
        478, 481, 482, 490, 491, 492, 494, 495,
        496, 497, 498, 499, 504, 505, 506, 507,
        509, 510, 511, 512, 1075, 1076, 1078, 1079,
        1080, 1081, 1082, 1083, 1084, 1085, 1086,
        1089, 1090, 1091, 1094, 1096, 1097, 1098, 1099,
        1100, 1101, 1102, 1103, 1104, 1105, 1106, 1107,
        1136, 1137, 1138, 1139, 1140, 1159, 1160, 1161,
        1164, 1166, 1179, 1180, 1181, 1182, 1183, 1184,
        1189, 1191, 1221, 1230, 1234, 1236, 1239, 1240,
        1241, 1243, 1244, 1257, 1258, 1250, 1261, 1264,
        1265, 1266, 1267, 1290, 1292, 1293, 1294, 1295,
        1296, 1297, 1298, 1299, 1300, 1313, 1314, 1315,
        1323, 1324, 1325, 1331, 1343, 1344, 1346, 1347,
        1349, 1351, 1360, 1364, 1365, 1408, 1410, 1418,
        1419, 1420, 1421, 1422, 1423, 1424, 1425, 1426,
        1427, 1428, 1429, 1430, 1431, 1432, 1440, 1441,
        1442, 1455, 1456, 1457, 1458, 1459, 1460, 1461,
        1464, 1465, 1466, 1467, 1468, 1469, 1470, 1472,
        1489, 1497, 1498, 1499, 1500, 1501, 1502, 1505,
        1506, 1507, 1508, 1509, 1510, 1514, 1515, 1516,
        1517, 1518, 1519, 1522, 1523, 1524, 1525, 1526,
        1527, 1528, 1535, 1536, 1566, 1568, 1569, 1570,
        1571, 1572, 1573, 1574, 1575, 1576, 1580, 1581,
        1582, 1583, 1586, 1588, 1589, 1590, 1591, 1593,
        1594, 1595, 1596, 1597, 1598, 1599, 1600, 1601,
        1602, 1603, 1604, 1605, 1609, 1611, 1612, 1613,
        1614, 1615, 1616, 1617, 1618, 1721, 1722, 1723,
        1728, 1729, 1730, 1731, 1733, 1740, 1741, 1742,
        1759, 1761, 1772, 1777, 1778, 1779, 1780, 1784,
        1785, 1786, 1787, 1788, 1789, 1790, 1791, 1792,
        1793, 1794, 1795, 1797, 1798, 1799, 1800, 1801,
        1802, 1803, 1804
    ];

    const handleSearch = async(data, page = 1) => {
        setIsLoading(true);
        data.modelo = data.modelo_id;
        data.marca = data.marca_id;
        delete data.marca_id;
        delete data.modelo_id;
        const resultsResponse = await getPiezasIntegraciones(data, page);

        if(resultsResponse && resultsResponse.success) {
            setResults(resultsResponse.data);
            setFilters(data);
            setIsLoading(false);
        }
    }

    const handleFilter = async(data, page = 1) => {
        setIsLoading(true);
        setFilters(data);
        delete data.tipo_pieza;
        data = {...filters, ...data};
        const resultsResponse = await getPiezasIntegraciones(data, page);

        if(resultsResponse && resultsResponse.success) {
            setResults(resultsResponse.data);
            setIsLoading(false);
        }
    }

    const handlePageChange = (event, newPage) => { 
        setIsLoading(true);
        setPage(newPage); 
        handleSearch(filters, newPage);
    }
    
    const getPaginationCount = () => {
        var totalPages = results.total_items < 15 ? 1 : Math.ceil(results.total_items / 15);
        return parseInt(totalPages);
    }

    const handleMakePieza = async(pieza) => {
        const tipo = getTipo(pieza.nombre);
        let precioTransporte = 0;
        let garantiaId = null;

        if(tipo === 406) { precioTransporte = 70 }
        else if(tipo === 55)  { precioTransporte = 30 }
        else {
            if(tipo === 80 || (tipo >= 146 && tipo <= 151) ||
            (tipo >= 174 && tipo <= 176) || tipo === 184) {
                if(tipo === 80 && pieza.proveedor.envio_capot !== null) {
                    precioTransporte = pieza.proveedor.envio_capot;
                } else if((tipo >= 146 && tipo <= 151)  && pieza.proveedor.envio_puertas !== null) {
                    precioTransporte = pieza.proveedor.envio_puertas;
                } else {
                    if(pieza.proveedor.envio_porton !== null) {
                        precioTransporte = pieza.proveedor.envio_porton;
                    }
                }
            }
        }

        if(piezasNoGarantia.find(tp => tp === tipo)) {
            garantiaId = 5;
        } else if(piezasIrrompibles.find(tp => tp === tipo)) {
            garantiaId = 4;
        } else {
            garantiaId = pieza.proveedor.tipo_garantia_id ? pieza.proveedor.tipo_garantia_id : 1;
        }

        const newPieza = {
            url: 'integracion-desguace-' + pieza.origen,
            nombre: pieza.nombre + ' ' + pieza.marca + ' ' + pieza.modelo,
            referencia_pieza: pieza.referencia,
            id_referencia_plataforma: pieza.id_original,
            precio_original: parseFloat(pieza.precio_original).toFixed(2),
            precio_compra: parseFloat(pieza.precio_compra).toFixed(2),
            precio_venta: parseFloat(pieza.precio_venta).toFixed(2),
            precio_transporte: precioTransporte,
            casco: 0,
            modelo_id: getModelo(pieza.modelo),
            marca_id: getMarca(pieza.marca),
            tipo_pieza_id: tipo,
            tipo_garantia_id: garantiaId,
            observaciones: pieza.notapublica,
            proveedor: pieza.proveedor,
            con_descuento: pieza.proveedor.descuentos?.length > 0 ? true : false,
            disponible: 1,
            integracion: true,
            pieza_integracion_id: pieza.id,
            unidades: 1,
            integracion_json: JSON.stringify(pieza)
        }

        onResultSelected(newPieza);
    }

    const getTipo = (tipo) => {
        const cats = obtainCats()
        let id = cats.find(tp => tp.nombre.toLowerCase() ===  tipo.toLowerCase())?.idcrm;
        if (!id){
            id = tipoPiezas.find(tp => tp.nombre.toLowerCase() ===  tipo.toLowerCase())?.id; 
        }
        return id
    }

    const getMarca = (marca) => { return marcas?.find(m => m.nombre.toLowerCase() ===  marca?.toLowerCase())?.id; }

    const getModelo = (modelo) => { 
        modelo = modelo.split(' ')[0];
        return modelos.find(m => m.nombre.toLowerCase()?.includes(modelo.toLowerCase()))?.id; 
    }

    return (
        <div className="w-100 d-flex flex-column">
            <Searcher onSearch={handleSearch} onCancel={onCancel} />

            { isLoading ? 
                <Loading />
                : 
                <div className="w-100">
                { results && 
                    <div className="w-100">
                        <Filter 
                            filters={filters}
                            tipoPiezasFiltro={results.tipo_piezas}
                            onFilter={handleFilter} />
                        
                        <ResultsList 
                            results={results} 
                            onPageChange={handleSearch}
                            onSelectResult={handleMakePieza}
                        /> 

                        <Pagination 
                            count={getPaginationCount()} 
                            page={currentPage} 
                            onChange={handlePageChange} 
                        />
                    </div>
                }
                </div>
            }
        </div>
    );
}