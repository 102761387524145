export const getActions = () => {
    const actions = {
        actions: [
            {
                label: 'Editar',
                icon: 'edit',
                action: 'edit'
            },
            {
                label: 'Cambiar fecha de proximo contacto',
                icon: 'date',
                action: 'next-contact'
            },
            {
                label: 'Añadir contacto',
                icon: 'user',
                action: 'add-contact'
            },
            {
                label: 'Añadir observación',
                icon: 'add',
                action: 'add-observation'
            }
        ],
        extra: []
    };

    return actions
}