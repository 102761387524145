import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules'
import imgDefault from '../../../../../../../../images/placeholder.jpg'
import { useState } from 'react'

import './swiperslider.scss'
import SkeletonImgSlider from '../SkeletonImgSlider'

const SwiperSlider = ({fotos, isLoadingImgs}) => {
  const [isImgDefault, setIsImgDefault] = useState(false)
  return (
    <div className="mySwiper">
        <div>
            {fotos?.length > 0
              ? <Swiper
                  style={{
                  '--swiper-navigation-color': '#303030',
                  '--swiper-pagination-color': '#303030',
                  }}
                  cssMode={true}
                  navigation={true}
                  pagination={true}
                  keyboard={true}
                  modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              >
                  {fotos?.map((foto, idx) => (
                    <SwiperSlide key={idx} >
                        <img
                          src={foto.pic}
                          onError={(e) => {
                            setIsImgDefault(true)
                            e.target.src = imgDefault
                          }}
                          className={isImgDefault ? 'img-default' : ''}
                          alt='img'/>
                    </SwiperSlide>
                  ))}
                </Swiper>
              : isLoadingImgs? <div className='mySwiper__container-skeleton'><SkeletonImgSlider /></div>  : <div className='mySwiper__img'><img src={imgDefault} alt='img-default' className='img-default'/></div>}
        </div>
    </div>
  )
}

export default SwiperSlider
