import { toast } from "react-toastify";
import { deleteIncidencia, getIncidencias, marcarComoActiva, marcarComoFinalizada, marcarComoFinanzas } from "../views/Dashboard/components/shared/services/incidencias";
import { asyncMac, makeAsyncTypes, makeCrudReducer, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";
import { getExpresswayStatus, getHermesStatus } from "../views/Dashboard/components/shared/services/envios";

const crudReducer = makeCrudReducer([
    'incidencias/add',
    'incidencias/edit',
    'incidencias/delete'
])

export const incidenciaReducer = (state = {}, action) => {
    switch(action.type){
        case "incidencias/fullfilled": {
            return action.payload;
        }
        case "incidencias/set-estado": {
            const newIncidencias = state.incidencias.items;
            const incidencia = newIncidencias.find(i => i.numero === action.payload.incidencia);
            const incidenciaEnvioIndex = incidencia.envios.findIndex(e => e.codigo_transporte === action.payload.envio);
            incidencia.envios[incidenciaEnvioIndex].estado = action.payload.estado;
            const indexIncidencia = newIncidencias.findIndex(i => i.numero === action.payload.incidencia);
            newIncidencias[indexIncidencia].splice(indexIncidencia, 1, incidencia);

           return {
            ...state,
            secciones: newIncidencias
           }
        }
        default:
            return state
    }
}

const asyncIncidencias = makeAsyncTypes('incidencias');

const [setPending, setFullFilled, setError ] = asyncMac(asyncIncidencias);

export const incidenciasFetchingReducer = makeFetchingReducer([
    'incidencias/pending', 
    'incidencias/fullfilled', 
    'incidencias/rejected'
]);

const fullfilledReducer = makeSetReducer(['incidencias/fullfilled']);

export const fetchIncidencias = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getIncidencias(page, search)
        const data = await response.data;

        if(search?.includes('search') && !search?.includes('seccion')) {
            const activeSection = data?.secciones.reduce((prev, current) => (prev.total_items > current.total_items) ? prev : current)
            
            if(activeSection) {
                localStorage.setItem('incidencias-tab', activeSection.item);
            }
        }

        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const removeIncidencia = (incidenciaId) => async dispatch => {
    try {
        const response = await deleteIncidencia(incidenciaId)
        
        if(response && response.success) {
            toast.success(response.message);
        }
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const cambiarAactiva = (incidenciaId) => async dispatch => {
    try {
        const response = await marcarComoActiva(incidenciaId)

        if(response && response.success) {
            toast.success(response.message);
        }
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const cambiarAfinalizada = (incidenciaId) => async dispatch => {
    try {
        const response = await marcarComoFinalizada(incidenciaId)

        if(response && response.success) {
            toast.success(response.message);
        }
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const cambiarAfinanzas = (incidenciaId) => async dispatch => {
    try {
        const response = await marcarComoFinanzas(incidenciaId)

        if(response && response.success) {
            toast.success(response.message);
        }
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const obtenerEstado = (numSeguimiento, transportistaId) => async dispatch => {
    try {
        let response;

        if(transportistaId === 2) {
            response = await getExpresswayStatus(numSeguimiento)
        } else {
            response = await getHermesStatus(numSeguimiento)
        }
        
        const data = await response.data;

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            dispatch({type: "incidencias/set-estado", payload: data});
            toast.success(response.message);
        }
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const incidenciasReducer = reduceReducers(crudReducer, fullfilledReducer, incidenciaReducer);
export const incidenciasStatusReducer = incidenciasFetchingReducer;