import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function ResponsableForm({
    incidencia,
    onSubmitResponsable
}) {
    const [users, setCurrentUsers] = useState(null);

    const atencionCliente = useSelector(state => {
        return state.atencion_cliente
    });
    const administracion = useSelector(state => {
        return state.administracion
    });
    const recambistas = useSelector(state => {
        return state.recambistas
    });
    const piezasSchema = yup.object({
        crafted_by: yup.number().integer().moreThan(0).required(),
    }).required();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(piezasSchema),
        defaultValues: {
            crafted_by: -1
        }
    });

    useEffect(() => {
        setCurrentUsers([...atencionCliente, ...recambistas])
    }, []);

    const handleChangeResponsable = (e) => { 
        const value = parseInt(e.target.value);
        setValue('crafted_by', value);
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();
        data.id = incidencia.id;
        onSubmitResponsable(data);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div className="w-100">
                <form className="form d-flex flex-column align-items-end" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label>Nuevo responsable</label>
                        { (incidencia.estado_incidencia_id === 3) ?
                            (administracion && administracion.length > 0) &&
                                <select 
                                    defaultValue={-1}
                                    {...register("crafted_by", {required: true})}
                                    onChange={handleChangeResponsable}>
                                    <option key={'responsable-' + 0} value={-1}>Selecciona un responsable</option>
                                    {administracion.map(op => <option key={'responsable-' + op.id} value={op.id}>{op.username}</option>)}
                                </select>
                            :
                            (users && users.length > 0) &&
                                <select 
                                    defaultValue={-1}
                                    {...register("crafted_by", {required: true})}
                                    onChange={handleChangeResponsable}>
                                    <option key={'responsable-' + 0} value={-1}>Selecciona un responsable</option>
                                    {users.map(op => <option key={'responsable-' + op.id} value={op.id}>{op.username}</option>)}
                                </select>
                        }

                        <div className="form-invalid">
                            {errors.crafted_by?.message && 'Selecciona un responsable.'}
                        </div>
                    </div>

                    <div className="d-flex align-items-center align-self-end">
                        <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}