import { getFacturas } from "../views/Dashboard/components/shared/services/facturas_desguaces";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";

const asyncFacturas = makeAsyncTypes('facturas_desguaces');

const [setPending, setFullFilled, setError ] = asyncMac(asyncFacturas);

export const facturasFetchingReducer = makeFetchingReducer([
    'facturas_desguaces/pending', 
    'facturas_desguaces/fullfilled', 
    'facturas_desguaces/rejected'
]);

const fullfilledReducer = makeSetReducer(['facturas_desguaces/fullfilled']);

export const fetchFacturasDesguaces = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getFacturas(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const facturasDesguacesReducer = fullfilledReducer;
export const facturasDesguacesStatusReducer = facturasFetchingReducer;