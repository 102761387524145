import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getFacturas = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/desguaces' + pagination + searcher).then(res => res.data);
}

export const generateFacturaPDF = async (idFactura) => {
    return API.get('/desguaces/generate_pdf/' + idFactura, {responseType: 'blob'}).then(res => res.data);
}

export const regenerateFactura = async (factura) => {
    return API.post('/desguaces/regenerate', factura).then(res => res.data);
}

export const downloadPlantilla = async () => {
    return API.post('/desguaces/plantilla', {}, {responseType: 'blob'}).then(res => res.data);
}

export const downloadVehiculoInfo = async (vehiculo) => {
    return API.post('/desguaces/vehiculo-info', vehiculo, {responseType: 'blob'}).then(res => res.data);
}

export const generateFacturaAutomatica = async (vehiculo) => {
    return API.post('/desguaces/facturacion-automatica', vehiculo, headers).then(res => res.data);
}

export const generateFacturaManual = async (vehiculo) => {
    return API.post('/desguaces/facturacion-manual', vehiculo, headers).then(res => res.data);
}
