import { getFacturas } from "../views/Dashboard/components/shared/services/facturas";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";

const asyncFacturas = makeAsyncTypes('facturas');

const [setPending, setFullFilled, setError ] = asyncMac(asyncFacturas);

export const facturasFetchingReducer = makeFetchingReducer([
    'facturas/pending', 
    'facturas/fullfilled', 
    'facturas/rejected'
]);

const fullfilledReducer = makeSetReducer(['facturas/fullfilled']);

export const fetchFacturas = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getFacturas(page, search)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const facturasReducer = fullfilledReducer;
export const facturasStatusReducer = facturasFetchingReducer;