import NoResults from '../../../NoResults'
import './tableresults.scss'

const TableResults = ({tab, data, dataInfoDetail}) => {
  const textTab = {
    oem: ['OEM Original', 'descman', 'OENbr', 'description'],
    iam: ['IAM Equivalente', 'supplier', 'PartsDataSupplierArticleNumber', 'description']
  }
   
  const isSelectedRow = (item) => {
    if(tab === 'oem') {
      if (item?.descman === dataInfoDetail?.brand && item?.OENbr === dataInfoDetail?.oenbr && item?.description === dataInfoDetail?.description) return true
      return false
    }
    return false
  }
  
  return (
    <div className='table-results'>
      {
        data && data.length > 0 && 
          <table>
            <thead>
              <tr><th>Marca</th><th>Referencia {textTab[tab][0]}</th><th>Descripción</th></tr>
            </thead>
            <tbody>
              {data.map(item => 
                <tr className={isSelectedRow(item) ? 'table-results__selected' : ''}>
                  <td>{item[textTab[tab][1]]}</td>
                  <td>{item[textTab[tab][2]]}</td>
                  <td>{item[textTab[tab][3]]}</td>
                </tr>)
              }
            </tbody>
          </table>
      }
      {
        data && data.length === 0 && <NoResults />
      }
      
    </div>
  )
}

export default TableResults
