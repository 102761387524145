import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getEntidades = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/entities' + pagination + searcher).then(res => res.data);
}

export const getEntidad = async (idCliente) => {
    return API.get('/entities/' + idCliente).then(res => res.data);
}

export const getFichaEntidad = async (section, search = null, page, idCliente) => {
    const pagination = '?page=' + page;
    const seccion = '&seccion=' + section;
    const searcher = search ? '&' + search : '';

    return API.get('/entities/' + idCliente + pagination + seccion + searcher).then(res => res.data);
}

export const newEntidad = async (cliente) => {
    return API.post('/entities', cliente).then(res => res.data);
}

export const buscarEntidad = async (cliente) => {
    return API.post('/entities/buscar', cliente).then(res => res.data);
}

export const comprobarEntidad = async (cliente) => {
    return API.post('/entities/comprobar', cliente).then(res => res.data);
}

export const updateEntidad = async (cliente) => {
    return API.put('/entities/' + cliente.id, cliente).then(res => res.data);
}

export const importarEntidades = async (data) => {
    const headers2 = headers;
    headers2.responseType = 'blob';
    return API.post('/entities/import/excel', data, headers).then(res => res.data);
}

export const comprasExport = async (data) => {
    return API.post('/entities/export/compras', data, {responseType: 'blob'}).then(res => res.data);
}

export const comprasMargenExport = async (data) => {
    return API.post('/entities/export/margen', data, {responseType: 'blob'}).then(res => res.data);
}

export const extraccionFacturas = async (data) => {
    return API.post('/entities/export/extraccion', data, {responseType: 'blob'}).then(res => res.data);
}

export const fusionarClientes = async (data) => {
    return API.post('/entities/merge', data).then(res => res.data);
}