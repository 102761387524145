import API from '../../../../../services/api';

export const getFinanzas = async () => {
    return await API.get('/finanzas').then(res => res.data);
}

export const getTransferenciasParaAprobar = async () => {
    return await API.get('/finanzas/transferencias').then(res => res.data);
}

export const getPedidosPendientes = async () => {
    return await API.get('/finanzas/pedidos').then(res => res.data);
}

export const getComprobante = async (comprobante) => {
    return await API.get('/finanzas/comprobante/' + comprobante, {responseType: 'blob'}).then(res => res.data);
}

export const aprobarPedidoPieza = async (ventaId, piezaId) => {
    return await API.get('/finanzas/' + ventaId + '/confirmacion/' + piezaId).then(res => res.data);
}