import { useState, useEffect } from "react";
import React from "react";
import { getUserResume } from "../../../shared/services/dashboard";
import moment from 'moment';
import Loading from "../../../shared/components/Loading";

export default function UserInfo() {
    const user = JSON.parse(localStorage.getItem('user'));
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);

     useEffect(() => {
        const fetchData = async() => {
            const fechas = getFechas();
            const response = await getUserResume(fechas.inicio, fechas.fin);

            if(response && response.success) {
                setUserData(response.data);
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    const getFechas = () => {
        const start = moment().date() >= 26 ?
            moment().date(26).format('YYYY-MM-DD')
        :   moment().subtract(1, 'month').date(26).format('YYYY-MM-DD');
        const end = moment().date() >= 26 ? 
            moment().add(1, 'month').date(25).format('YYYY-MM-DD')
        :   moment().date(25).format('YYYY-MM-DD'); 

        return {inicio: start, fin: end}; 
    }
    const getFechaInicio = () => { 
        return moment().date() >= 26 ?
                moment().date(26).format('DD MMM YYYY')
            :   moment().subtract(1, 'month').date(26).format('DD MMM YYYY') 
    }
    const getFechaFin = () => { 
        return moment().date() >= 26 ? 
            moment().add(1, 'month').date(25).format('DD MMM YYYY')
        :   moment().date(25).format('DD MMM YYYY') 
    }

    if(isLoading) {
        return ( <Loading /> );
    } else {
        return (
            <div className='w-100 mt-3'>
                { user.rol_id === 2 ?
                    <div className='w-100 mt-3'>
                        <div className="w-100 d-flex justify-content-between mb-3">
                            <h1>Resumen</h1>
                            <small>{getFechaInicio()} - {getFechaFin()}</small>
                        </div>
                        

                        <div className='w-100 table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>presupuestos</th>
                                        <th className='text-center'>ventas</th>
                                        <th className='text-center'>incidencias</th>
                                        <th className='text-center'>ratio conversión</th>
                                        <th className='text-center'>ratio beneficio</th>
                                        <th className='text-center'>ratio incidencias</th>
                                        <th className='text-center'>facturación</th>
                                        <th className='text-center'>beneficio</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-center'>{userData.num_presupuestos}</td>
                                        <td className='text-center'>{userData.num_ventas}</td>
                                        <td className='text-center'>{userData.num_incidencias}</td>
                                        <td className='text-center'>{userData.ratio_conversion}%</td>
                                        <td className='text-center'>{userData.ratio_beneficio}%</td>
                                        <td className='text-center'>{userData.ratio_incidencias}%</td>
                                        <td className='text-center'>{userData.facturacion_total}€</td>
                                        <td className='text-center'>{userData.beneficio_total}€</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : 
                    <div className="w-100">
                        <p>Tu tipo de usuario no tiene facturación.</p>
                    </div>
                }
            </div>
        
        )
    }
}