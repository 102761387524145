import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export default function FinalizarForm({
    onSubmitFinalizar
}) {
    const { register, formState: { errors }, handleSubmit, setValue } = useForm({
        defaultValues: {
           estado_pieza_id: null
        }
    });
    const estadosPiezas = useSelector(state => {
        const { estados_piezas } = state;
        return estados_piezas;
    });

    useEffect(() => {
        
    }, []);

    const handleChangeEstado = (e) => { 
        const value = parseInt(e.target.value);
        setValue('estado_pieza_id', value);
    }

    const handleSubmitFinalizar = async (data, e) => {
        e.preventDefault();
        onSubmitFinalizar(data);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form" onSubmit={handleSubmit(handleSubmitFinalizar)}>
                <div className="w-100 d-flex">
                    <div className="form-group">
                        <label htmlFor="rol">Estado de la pieza:</label>
                        { (estadosPiezas && estadosPiezas.length > 0) &&
                            <select 
                                {...register("estado_pieza_id", { required: true })}
                                defaultValue={null}
                                onChange={handleChangeEstado}>
                                <option value={-1}>Selecciona un estado...</option>
                                {
                                    estadosPiezas.map(op => <option key={'estados-' + op.id} value={op.id}>{op.nombre}</option>)
                                }
                            </select>
                        }
                    </div> 
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}