import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getEnvios = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/envios' + pagination + searcher).then(res => res.data);
}

export const getEnviosData = async (search) => {
    return API.get('/envios-data?search=' + search).then(res => res.data);
}

export const getEtiqueta = async (envioId) => {
    return API.get('/envios/' + envioId + '/etiqueta', {responseType: 'blob'}).then(res => res.data);
}

/*** ESTADO ENVIO EXPRESSWAY  ***/
export const getExpresswayStatus = async (numSeguimiento) => {
    return await API.get('/expressway/' + numSeguimiento + '/estado').then(res => res.data);
}

/*** ESTADO ENVIO HERMES  ***/
export const getHermesStatus = async (numSeguimiento) => {
    return await API.get('/hermesLogistics/' + numSeguimiento + '/estado').then(res => res.data);
}

export const consumoEnviosExport = async (data) => {
    return API.post('/envios/export/consumo', data, {responseType: 'blob'}).then(res => res.data);
}

export const getEndOfDay = async (data) => {
    return API.post('/envios/export/fin-dia', data, {responseType: 'blob'}).then(res => res.data);
}

export const getInfoEnvios = async (data) => {
    return API.post('/envios-info', data).then(res => res.data);
}

export const newEnvio = async (envio) => {
    return API.post('/envios', envio).then(res => res.data);
}

export const updateEnvio = async (envio) => {
    return API.put('/envios/' + envio.id, envio).then(res => res.data);
}

export const regenerarEnvioPendiente = async (envio) => {
    return API.post('envios/regenerar/pendiente', envio).then(res => res.data);
}

export const enviarEmailProveedor = async (envioId) => {
    return API.post('envios/' + envioId + '/email').then(res => res.data);
}

export const uploadEtiquetaEnvio = async (envio) => {
    return API.post('envios/' + envio.get("envio_id") + '/upload-etiqueta', envio, headers).then(res => res.data);
}

export const cancelarEnvio = async (envio) => {
    return API.post('/envios/' + envio.id + '/cancelar', envio).then(res => res.data);
}