import React from "react";
import { toast } from "react-toastify";
import ImportForm from "../../../shared/components/ImportForm";
import { setErrorMessage } from "../../../shared/helpers/functionalities";
import { importarEntidades } from "../../../shared/services/clientes";
import { useDispatch } from "react-redux";
import { importarMedidas } from "../../../shared/services/tipo_piezas";

export default function Excels() {
    const dispatch = useDispatch();

    const handleImportEntidades = async (excel) => {
        dispatch({type: 'loading/set', payload: false});
        const excelResult = await importarEntidades(excel)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
            dispatch({type: 'loading/set', payload: false});
          });

        if(excelResult) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Importación hecha correctamente!');
            
            const url = window.URL.createObjectURL(new Blob([excelResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'pendientes-crm.xlsx');
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    const handleImportMedidas = async (excel) => {
        dispatch({type: 'loading/set', payload: true});
        const excelResult = await importarMedidas(excel)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
            dispatch({type: 'loading/set', payload: false});
        });

        if(excelResult && excelResult.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Importación hecha correctamente!');
        } else toast.error(excelResult.message);
    }

    return (
        <div className="w-100 d-flex flex-wrap">
            <div className="flex-grow-1 d-flex flex-column align-items-center mb-5">
                <label>IMPORTACIÓN DE ENTIDADES</label>

                <ImportForm 
                    onSubmitImport={handleImportEntidades}
                /> 
            </div>

            <div className="flex-grow-1 d-flex flex-column align-items-center mb-5">
                <label>IMPORTACIÓN DE MEDIDAS TIPO PIEZAS</label>

                <ImportForm 
                    onSubmitImport={handleImportMedidas}
                /> 
            </div>
        </div>
    )
}