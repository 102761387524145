import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboard } from '../../../../../../redux/features/dashboard';
import Loading from '../../../shared/components/Loading';
import '../Dashboard.scss';
import ClientesTab from './components/ClientesTab';
import IncidenciasTab from './components/IncidenciasTab';
import PiezasTab from './components/PiezasTab';
import ProveedoresTab from './components/ProveedoresTab';
import UsersTab from './components/UsersTab';
import PlataformasTab from './components/PlataformasTab';
import ResumenRecambistas from './components/ResumenRecambistas';
import ResumenPlataformas from './components/ResumenPlataformas';

export default function AdminDashboard() {
    const dispatch = useDispatch();
    const [tab, setTab] = useState('usuarios');
    const dashboard = useSelector(state => {
        return state.dashboard.entity.admin
    });
    const dashboardStatus = useSelector(state => {
        return state.dashboard.status
    });

    useEffect(() => {
        dispatch(fetchDashboard());
    }, []);


    const handleChangeTab = (e, tab) => setTab(tab);

    if(dashboardStatus.loading === 'pending' || dashboardStatus.loading === 'idle') {
        return ( <Loading /> );
      }
    
    if(dashboardStatus.loading === 'succeed' || dashboardStatus.loading === 'rejected') {
        return (
            <div className='w-100 d-flex flex-column dashboard pb-5'>
               <div className='w-100 d-flex flex-column align-items-start summary mb-4'>
                    <h1>Información General</h1>

                   <div className='w-100 d-flex align-items-center flex-wrap'>
                        <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
                            <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                                <h2>Presupuestos diarios</h2>
                                <h1>{dashboard.general.presupuestos_dia}</h1>
                            </div>

                            <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                                <h2>Presupuestos semanales</h2>
                                <h1>{dashboard.general.presupuestos_semana}</h1>
                            </div>

                            <div className='card-item d-flex flex-column align-items-center mt-4 mt-md-0'>
                                <h2>Presupuestos mensuales</h2>
                                <h1>{dashboard.general.presupuestos_mes}</h1>
                            </div>
                        </div>

                        <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
                            <div className='card-item d-flex flex-column align-items-center mt-3'>
                                <h2>Ventas diarias</h2>
                                <h1>{dashboard.general.ventas_dia}</h1>
                            </div>

                            <div className='card-item d-flex flex-column align-items-center mt-3'>
                                <h2>Ventas semanales</h2>
                                <h1>{dashboard.general.ventas_semana}</h1>
                            </div>

                            <div className='card-item d-flex flex-column align-items-center mt-3'>
                                <h2>Ventas mensuales</h2>
                                <h1>{dashboard.general.ventas_mes}</h1>
                            </div>
                        </div>

                        <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
                            <div className='card-item d-flex flex-column align-items-center mt-3'>
                                <h2>Facturación diaria</h2>
                                <h1>{dashboard.general.facturacion_diaria}€</h1>
                            </div>

                            <div className='card-item d-flex flex-column align-items-center mt-3'>
                                <h2>Facturación semanal</h2>
                                <h1>{dashboard.general.facturacion_semanal}€</h1>
                            </div>

                            <div className='card-item d-flex flex-column align-items-center mt-3'>
                                <h2>Facturación mensual</h2>
                                <h1>{dashboard.general.facturacion_mensual}€</h1>
                            </div>
                        </div>

                        <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
                            <div className='card-item d-flex flex-column align-items-center mt-3'>
                                <h2>Talleres que han comprado +2 veces en los últimos 60 dias</h2>
                                <h1>{dashboard.general.talleres_60_dias}</h1>
                            </div>

                            <div className='card-item d-flex flex-column align-items-center mt-3'>
                                <h2>Bolsa envíos</h2>
                                <h1>{dashboard.general.bolsa_envios}€</h1>
                            </div>
                        </div>
                   </div>

                    { (tab === "usuarios" && dashboard.generalMetrics?.resumen && dashboard.generalMetrics?.resumen?.length > 0) &&
                        <ResumenRecambistas 
                            resumen={dashboard.generalMetrics?.resumen}
                        />
                    }

                    { (tab === "plataformas" && dashboard.plataformasGeneralMetrics?.resumen && dashboard.plataformasGeneralMetrics?.resumen?.length > 0) &&
                        <ResumenPlataformas 
                            resumen={dashboard.plataformasGeneralMetrics?.resumen}
                        />
                    }
                </div>

                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="Ajustes">
                        <Tab label="Usuarios" value="usuarios" />
                        <Tab label="Clientes" value="clientes" />
                        <Tab label="Piezas" value="piezas" />
                        <Tab label="Incidencias" value="incidencias" />
                        <Tab label="Proveedores" value="proveedores" />
                        <Tab label="Plataformas" value="plataformas" />
                    </TabList>
                    </Box>
                    <TabPanel value="usuarios">
                        <UsersTab />
                    </TabPanel>
                    <TabPanel value="clientes">
                        <ClientesTab />
                    </TabPanel>
                    <TabPanel value="piezas">
                        <PiezasTab />
                    </TabPanel>
                    <TabPanel value="incidencias">
                        <IncidenciasTab />
                    </TabPanel>
                    <TabPanel value="proveedores">
                        <ProveedoresTab />
                    </TabPanel>
                    <TabPanel value="plataformas">
                        <PlataformasTab />
                    </TabPanel>
                </TabContext>
            </div>
        
        )
    }
}
