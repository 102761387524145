import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { generatePresupuestoPDF } from "../../../../../shared/services/presupuestos";
import { generateFacturaPDF } from "../../../../../shared/services/facturas"
import { useDispatch, useSelector } from "react-redux";

export default function ItemsTable({
  cliente,
  list, 
  item,
  page,
  count,
  onPageChange
}) {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const tipoIncidencias = useSelector(state => {
    const { tipo_incidencias } = state;
    return tipo_incidencias;
  });

  useEffect(() => {
    var totalPages = count < 15 ? 1 : Math.ceil(count / 15);
    setPageCount(parseInt(totalPages));
  }, [list, page, count]);

  const getTipoIncidencia = (tipoId) => { return tipoIncidencias?.find(f => f.id === tipoId)?.nombre; }

  const generateThePdf = async (type, itm) => {
    dispatch({type: 'loading/set', payload: true});
    let pdfResult;
    let pdfName = itm.numero;
    
    if(type === 'presupuesto') {
      pdfResult = await generatePresupuestoPDF(itm.id);
    } else {
      pdfResult = await generateFacturaPDF(itm.factura_id);
    }

    if(pdfResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('PDF generado correctamente!')
      const url = window.URL.createObjectURL(new Blob([pdfResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', pdfName + '.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }
    
  return (
      <div className="w-100 d-flex flex-column align-items-end">
          <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>{item === 'presupuesto'  ? 'Nº pres' :  item === 'incidencia' ? 'Nº incidencia' : 'Nº factura'}</th>
                <th className="text-center">fecha</th>
                <th>Pieza</th>
                <th className="text-center">€ venta</th>
                <th className="text-center">€ envío</th>
                { item === 'incidencia' ?
                  <th className="text-center">TIPO</th>
                  :
                  <th className="text-center">TOTAL IVA</th>
                }
                <th className="text-center">resp</th>
                { item !== 'incidencia' &&
                  <th className="text-center">acciones</th>
                }
              </tr>
            </thead>
              { 
                list.map((itm, index) => {
                  let piezasRows = null;

                  const piezasValues = Object.values(itm.piezas);
                    piezasRows = piezasValues.map((pieza, i) => {
                      let hasDevolucion;
                      let entidad = null;

                      if(item === 'venta') {
                        hasDevolucion = itm.es_devolucion?.length > 0 ? itm.es_devolucion[0] : false;
                      }

                      if(cliente.tipo_cliente_b2b_id === 2) {
                        entidad = i === 0 ? 
                        <td rowSpan={piezasValues.length + 1} width="12%">
                          {itm.cliente.nombre}
                        </td> : null
                      }

                      const numero = i === 0 ? 
                        <td rowSpan={piezasValues.length + 1} width="6%">
                          {(item === 'presupuesto' || item === 'incidencia') ? 
                            itm.numero : 
                            item === 'devolucion' ?
                              <b className={itm.tipo_devolucion_id === 1 ? "text-warning" : "text-danger"}>{itm.numero}</b> 
                            :  
                            <b className={hasDevolucion ? (hasDevolucion.tipo_devolucion_id === 1 ? "text-warning" : "text-danger") : ''}>{itm.numero}</b> 
                          }
                        </td> : null
                      const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">{moment(itm.fecha).format("DD-MM-YYYY")}</td> : null
                      const totalIva = item === 'incidencia' ? 
                        (i === 0 ? <td rowSpan={piezasValues.length + 1 } width="12%" className="text-center text-uppercase">{getTipoIncidencia(itm.tipo_incidencia_id)}</td> : null)
                        :
                        (i === 0 ? <td rowSpan={piezasValues.length + 1 } width="12%" className="text-center">{itm.total_iva}€</td> : null)
                      const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1}  width="12%" className="text-center text-uppercase">{
                        itm.created_by?.username }</td> : null
                      const tipoFacturaType = item !== 'incidencia' ? 
                        (item === 'presupuesto' ? 'presupuesto' 
                          : (item === 'venta' ? 'factura' : '') 
                        ) : '';
                      const pdf = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%" className="text-center">
                        {
                          <span title="Descargar PDF" style={{cursor: 'pointer'}}>
                              <FontAwesomeIcon icon={faFilePdf} size="1x" className="action" color="#215732" onClick={() => generateThePdf(tipoFacturaType, itm)}></FontAwesomeIcon>
                          </span>
                        }
                      </td> : null;

                      return (
                        <tr key={i}>
                          {entidad}
                          {numero}
                          {fecha}
                          <td>
                            {
                              item === 'presupuesto' && pieza.presupuesto_relationship.unidades > 1 ?
                                <strong>x{pieza.presupuesto_relationship.unidades}</strong> 
                              : item === 'venta' && pieza.venta_relationship.unidades > 1 ?
                                <strong>x{pieza.venta_relationship.unidades}</strong> 
                              : item === 'devolucion' && pieza.devolucion_relationship.unidades > 1 ?
                                <strong>x{pieza.devolucion_relationship.unidades}</strong> 
                              : ''
                            } 
                            {pieza.nombre}
                          </td>
                          <td className="text-center">{pieza.precio_venta}€</td>
                          <td className="text-center">{pieza.precio_transporte}€</td>
                          {totalIva}
                          {responsable}
                          {item !== 'incidencia' ? pdf : null}
                        </tr>
                      )
                    })

                  return (
                    <tbody key={index}>
                      {piezasRows}
                    </tbody>
                  )
                }) 
              }
          </table>
        </div>

        <Pagination 
          className="mt-3" 
          count={pageCount} 
          page={page} 
          onChange={onPageChange} 
        />

      </div>
  );
}