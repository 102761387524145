import { useState, useEffect } from "react";
import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import VirtualizedAutocompletePersonas from "../AutocompletePersonas";
import ConfirmModal from "../ConfirmModal";

export default function ClienteChangeModal({
    open,
    title,
    presupuestoClient,
    isEdit,
    isChange,
    fromPresupuesto,
    onClienteChange,
    onNewCliente,
    onClose,
}) {
    const { 
        control 
    } = useForm({
        defaultValues: {
            cliente: null
        }
    });

    const [currentCliente, setCurrentCliente] = useState(null);
    const [isExistingCliente, setIsExistingCliente] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    useEffect(() => {

    }, [presupuestoClient, isEdit, isChange]);

    const handleCloseModal = () => onClose();
    const handleExistentClient = async () => { setIsExistingCliente(true);  }

    const handleOpenConfirm = (cliente) => {
        if(fromPresupuesto) {
            setOpenConfirm(true);
            setCurrentCliente(cliente);
        } else { handleChangeClienteInPresupuesto(cliente); }
    }
    
    const handleConfirmChangeCliente = (confirm) => {
        if(confirm) {
            if(currentCliente !== null) {
                handleChangeClienteInPresupuesto(currentCliente);
            } else {
                onNewCliente();
            }
        }
        
        setOpenConfirm(false);
    }

    const handleChangeClienteInPresupuesto = (cliente) => {
        if(!cliente.bloqueado) {
            onClienteChange(cliente.id);
            setIsExistingCliente(false);
        } else { toast.error('Error! Cliente bloqueado.'); }

        onClose();
    }

    const handleNewCliente = () => {
        if(fromPresupuesto) {
            setOpenConfirm(true);
            setCurrentCliente(null);
        } else {
            onNewCliente();
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseModal}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="cambiar-cliente-form-title"
                aria-describedby="cambiar-form-description">
            <DialogTitle id="cambiar-cliente-form-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">{title}</p>

                    <span title="cerrar" className="action" onClick={() => handleCloseModal()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            
            </DialogTitle>
            <DialogContent>
                <div className="w-100 h-100">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <button className="btn btn-primary" onClick={() => handleExistentClient()}>Cliente existente</button>
                        <button className="btn btn-default" onClick={() => handleNewCliente()}>Nuevo cliente</button>
                    </div>

                    <div className="w-100">
                        { isExistingCliente &&
                            <div className="w-100 d-flex align-items-center justify-content-center my-3">
                                <VirtualizedAutocompletePersonas 
                                    id={"entity_id"}
                                    control={control}
                                    item={'cliente'}
                                    labels={['nombre', 'cif', 'telefono']}
                                    defaultValue={currentCliente}
                                    onChange={handleOpenConfirm}
                                />
                            </div>
                        }
                    </div>
                </div>
            </DialogContent>
            </Dialog>

            { (openConfirm) &&
                <ConfirmModal 
                    onConfirmAction={handleConfirmChangeCliente} 
                    title={'Cambiar cliente'} 
                    description={'Importante! El cliente que has seleccionado, al editar/añadir una pieza se le aplicará la tarifa correspondiente, que es distinta a la del actual cliente.'}
                    state={openConfirm}>
                </ConfirmModal>
            }
        </>
    )
}