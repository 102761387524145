import React from "react";
import { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, Tab } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FacturaManualForm from "./components/FacturaManualForm";
import FacturaAutomaticaForm from "./components/FacturaAutomaticaForm";
import { generateFacturaAutomatica, generateFacturaManual } from "../../../shared/services/facturas_desguaces";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../shared/helpers/functionalities";

export default function FacturaModal({ 
    state,
    onReloadFacturas,
    onClose
}) {
    const dispatch = useDispatch();
    const [tab, setTab] = useState('automatica');
    const [open, setOpen] = useState(state);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };
    const handleChangeTab = (e, tab) => setTab(tab);

    const handleSubmitFacturaAutomatica = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await generateFacturaAutomatica(data)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
            dispatch({type: 'loading/set', payload: false});
        });

        if(response && response.success) {
            toast.success('Factura automática generada correctamente!')
            dispatch({type: 'loading/set', payload: false});
            onReloadFacturas();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    const handleSubmitFacturaManual = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await generateFacturaManual(data)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
            dispatch({type: 'loading/set', payload: false});
        });

        if(response && response.success) {
            toast.success('Factura manual generada correctamente!')
            dispatch({type: 'loading/set', payload: false});
            onReloadFacturas();            
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="info-title"
            aria-describedby="info-description">
            <DialogTitle id="info-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">Nueva factura de vehículo</p>

                    <span title="cerrar" className="action" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="Mi Cuenta">
                        <Tab label="Automática" value="automatica" />
                        <Tab label="Manual" value="manual" />
                    </TabList>
                    </Box>
                    <TabPanel value="automatica">
                        <FacturaAutomaticaForm
                            onSubmitFacturaAutomatica={handleSubmitFacturaAutomatica}
                        />
                    </TabPanel>
                    <TabPanel value="manual">
                        <FacturaManualForm
                            onSubmitFacturaManual={handleSubmitFacturaManual}
                        />
                    </TabPanel>
                </TabContext>
            </DialogContent>
        </Dialog>
    );
}