export const getActions = (item) => {
    const actions = {
        actions: [
            {
                label: 'Generar Incidencia',
                icon: 'issue',
                action: 'issue'
            },
            {
                label: 'Cambiar estado',
                icon: 'status',
                action: 'status'
            },
            {
                label: 'Ver Histórico',
                icon: 'details',
                action: 'history'
            },
            {
                label: 'Descargar PDF',
                icon: 'pdf',
                action: 'download'
            },
            {
                label: 'Adjuntar factura proveedor',
                icon: 'upload',
                action: 'provider-invoice'
            }
        ],
        extra: [
            {
                label: 'Enviar email formulario incidencia',
                icon: 'send',
                action: 'incidencia-form-mail'
            },
            {
                label: 'Copiar texto formulario incidencia',
                icon: 'duplicate',
                action: 'incidencia-form-phone'
            }
        ]
    };

    if((item.es_devolucion.length === 0 || (item.es_devolucion?.length > 0 && item.es_devolucion[0].tipo_devolucion_id === 1))) {
        actions.actions.unshift({
            label: 'Generar Devolución',
            icon: 'refund',
            action: 'refund'
        })
    }

    return actions;
}