import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import '../../Header.scss';
import { Avatar, Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faRightFromBracket, faUmbrellaBeach, faUserAlt, faUserAstronaut } from "@fortawesome/free-solid-svg-icons";

export default function UserAvatar({
    onLogout,
    onOpenReportProblem
}) {

    const currentUser = JSON.parse(localStorage.getItem('user'));
    const [anchorEl, setAnchorEl] = useState(null);

    const stringAvatar = (name) => {
        return {
          sx: {
            bgcolor: stringToColor(currentUser.email),
          },
          children: `${name.split('')[0]}`,
        };
    }

    const stringToColor = (string) => {
        let hash = 0;
        let i;
        let color = '#';
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.substr(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    
    const handleMenu = (event) => { setAnchorEl(event.currentTarget); };
    const handleLogout = () => { onLogout(); };
    const handleClose = () => { setAnchorEl(null); };
    const handleOpenReportarProblema = () => {
        handleClose();
        onOpenReportProblem();
    }

    return (
        <div>
            <Avatar
                style={{ cursor: 'pointer' }} 
                {...stringAvatar(currentUser?.username?.toUpperCase())} 
                aria-label="Mi cuenta"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit" />
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                    <MenuItem onClick={handleOpenReportarProblema}>
                        <FontAwesomeIcon icon={faUserAstronaut} size="1x" className="action me-3" color="#215732" />
                        <small>Reportar problema</small>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <FontAwesomeIcon icon={faUmbrellaBeach} size="1x" className="action me-3" color="#215732" />
                        <a href="https://cronos.recomotor.com/" alt="Cronos" target="_blank" rel="noreferrer">
                            <small>Cronos</small>
                        </a>
                    </MenuItem>
                    <Link to={"/mi-cuenta"} title="Mi cuenta">
                        <MenuItem onClick={handleClose}>
                            <FontAwesomeIcon icon={faUserAlt} size="1x" className="action me-3" color="#215732" />
                            <small>Mi cuenta</small>
                        </MenuItem>
                    </Link>
                    { (currentUser?.rol_id === 1 || currentUser?.responsable || currentUser?.rol_id === 5) ?
                        <Link to={"/ajustes"} title="Ajustes">
                            <MenuItem onClick={handleClose}>
                                <FontAwesomeIcon icon={faCogs} size="1x" className="action" style={{marginRight: '0.775rem'}} color="#215732" />
                                <small>Ajustes</small>
                            </MenuItem>
                        </Link>
                        : ''
                    }
                    <MenuItem onClick={handleLogout}>
                        <FontAwesomeIcon icon={faRightFromBracket} size="1x" className="action me-3" color="#215732" />
                        <small>Cerrar sesión</small>
                    </MenuItem>
            </Menu>
        </div>
    );
}