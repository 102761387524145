import { asyncMac, makeAsyncTypes } from "../utils";
import { toast } from 'react-toastify';
import { newPieza, updatePieza } from "../../views/Dashboard/components/shared/services/piezas";

const initialPieza = null;

export const piezaReducer = (state = initialPieza, action) => {
    switch(action.type){
        case "pieza/fullfilled": {
            return action.payload;
        }
        case "pieza/servicio_envio": {
            return {
                ...state,
                servicio_envio: action.payload
            }
        }
        case "pieza/servicio_envio_precio": {
            const newPieza = action.payload;
            newPieza.servicio_envio = state.servicio_envio;
            newPieza.servicio_envio.precio_transporte = action.payload.precio_transporte;

            return newPieza;
        }
        case "pieza/clear": {
            return initialPieza;
        }
        default:
            return state
    }
}

const asyncPieza = makeAsyncTypes('pieza');

const [setPending, setFullFilled, setError ] = asyncMac(asyncPieza);

export const savePieza = (pieza) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await newPieza(pieza)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const editPieza = (pieza) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await updatePieza(pieza)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const currentPiezaReducer = piezaReducer;