export const getActions = () => {
    return {
        actions: [
            {
                label: 'Descargar PDF',
                icon: 'pdf',
                action: 'download'
            },
            {
                label: 'Regenerar',
                icon: 'regenerate',
                action: 'regenerate'
            }
        ],
        extra: []
    }
}