import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { userChangePassword } from "../../../../../shared/services/users";
import { useDispatch } from "react-redux";

export default function ChangePasswordForm({onChange}) {
    const dispatch = useDispatch();
    const [equalOldPasswords, setEqualOldPasswords] = useState(false);
    const [equalPasswords, setEqualPasswords] = useState(false);
    const { register, formState: { errors }, setError, handleSubmit, reset, getValues } = useForm({
        defaultValues: {
            id: 0,
            old_password: '',
            confirm_old_password: '',
            password: '',
            confirm_password: '',
        }
    });

    useEffect(() => {
        reset();
    }, []);


    const handleValidateOldPasswords = (e) => {
        if(e.target.value !== getValues('old_password')) {
            setEqualOldPasswords(false);
            setError('confirm_old_password', { type: 'custom', message: 'Las constraseñas no coinciden' });
        } else setEqualOldPasswords(true);
    }

    const handleValidatePasswords = (e) => {
        if(e.target.value !== getValues('password')) {
            setEqualPasswords(false);
            setError('confirm_password', { type: 'custom', message: 'Las constraseñas no coinciden' });
        } else setEqualPasswords(true);
    }

    const handleSubmitPassword = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});
        const response = await userChangePassword(data);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onChange();
        }
    }
    
    return (
        <div className="w-100 d-flex flex-column mt-4">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitPassword)}>
                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group">
                        <label htmlFor="nombre">Contraseña antigua</label>
                        <input 
                            type="password" 
                            className="form-control"
                            {...register("old_password", { required: true })} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "Nombre es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="nombre">Repite ontraseña antigua</label>
                        <input
                            type="password"
                            className="form-control"
                            {...register("confirm_old_password", {
                            required: true,
                            validate: (val) => {
                                if (getValues('old_password') !== val) {
                                return "Your passwords do no match";
                            }
                            },
                            })}
                          onBlur={handleValidateOldPasswords}
                        />
                        
                        <div className="form-invalid">
                            {errors.confirm_old_password && errors.confirm_old_password.message }
                        </div>
                    </div>

                   { equalOldPasswords &&
                    <div>
                        <div className="form-group">
                            <label htmlFor="nombre">Nueva contraseña</label>
                            <input 
                                type="password" 
                                className="form-control"
                                {...register("password", { required: true })} />
                            
                            <div className="form-invalid">
                                {errors.nombre?.type === 'required' && "Nombre es obligatorio"}
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="nombre">Repite nueva contraseña</label>

                            <input
                                type="password"
                                className="form-control"
                                {...register("confirm_password", {
                                required: true,
                                validate: (val) => {
                                    if (getValues('password') !== val) {
                                    return "Your passwords do no match";
                                }
                                },
                                })}
                                onBlur={handleValidatePasswords}
                            />
                            
                            <div className="form-invalid">
                            {errors.confirm_password && errors.confirm_password.message }
                        </div>
                        </div>
                    </div>
                    }
                </div>

                { (equalOldPasswords && equalPasswords) &&
                    <div className="d-flex align-items-center align-self-end mt-3">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                }
            </form>
        </div>
    )
}