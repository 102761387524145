import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getMarcasIntegraciones, getModelosIntegraciones, getTipoPiezasIntegraciones } from "../../../../../../../../../../../../../shared/services/piezas";
import VirtualizedAutocomplete from "../../../../../../../../../../../../../shared/components/Autocomplete";

export default function Searcher({
    onSearch,
    onCancel
}) {
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [marcas, setMarcas] = useState([])
    const [tipoPiezas, setTipoPiezas] = useState([])

    const modelos = useSelector(state => {
        const { modelos } = state;
        return modelos;
    });

    const { 
        register, 
        formState: { errors }, 
        handleSubmit, 
        control, 
        setValue, 
        getValues,
        watch
    } = useForm({
        mode: "onChange",
        defaultValues: {
            id_original: '',
            referencia_pieza: '',
            tipo_pieza_id: 0,
            modelo_id: null,
            modelo: null,
            marca: null,
            tipo_pieza: null
        }
    });
    const [ filterModelos, setFilterModelos ] = useState(modelos);

    useEffect( () => {
        const fetchData = async() => { 
            setMarcas(await getMarcasIntegraciones())
            setTipoPiezas(await getTipoPiezasIntegraciones())
        }

        fetchData()
    }, []);

    const handleChangeMarca = (value) => {
        if(value) {
            setValue('marca', value);
            setValue('marca_id', value.nombre);
            setValue('modelo_id', 0);
            getModelosIntegraciones(value.id).then((data) => { setFilterModelos(data); })
        } else {
            setValue('marca', null);
            setValue('marca_id', 0);
            setValue('tipo_pieza', null);
            setValue('tipo_pieza_id', 0);
            setValue('modelo', null);
            setValue('modelo_id', 0);
            setFilterModelos([])
        }

        checkIfIsEnabled();
    }

    const handleChangeModelo = (value) => {
        if(value) {
            setValue('modelo', value);
            setValue('modelo_id', value.nombre);
        } else {
            setValue('modelo', null);
            setValue('modelo_id', 0);
            setValue('tipo_pieza', null);
            setValue('tipo_pieza_id', 0);
        }

        checkIfIsEnabled();
    }

    const handleChangeTipoPieza = (value) => {
        if(value) {
            setValue('tipo_pieza', value);
            setValue('tipo_pieza_id', value.nombre);
        } else {
            setValue('tipo_pieza', null);
            setValue('tipo_pieza_id', 0);
        }

        checkIfIsEnabled();
    }

    const handleChangeReferencia = (e) => { 
        const value = e.target.value.trim();

        if(value !== '') setValue('referencia_pieza', value); 
        else setValue('referencia_pieza', ''); 

        checkIfIsEnabled();
    }

    const handleChangeID = (e) => { 
        const value = e.target.value.trim();

        if(value !== '') setValue('id_original', value); 
        else setValue('id_original', ''); 

        checkIfIsEnabled();
    }

    const checkIfIsEnabled = () => {
        if((getValues('referencia_pieza') !== '') || 
            (getValues('id_original') !== '') ||
            (
                (getValues('marca') !== 'null' && getValues('marca') !== null)
                
            )
        ) setBtnDisabled(false);
        else setBtnDisabled(true);
    }

    const handleSubmitSearch = (data) => {
        delete data.modelo;
        delete data.marca;
        delete data.tipo_pieza;
        onSearch(data);
    }

    return (
        <div className="w-100 d-flex flex-column mt-4 mt-md-0 mt-lg-0">
            <form className="form" onSubmit={handleSubmit(handleSubmitSearch)}>
                <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
                    <div className="form-group me-0 me-md-3">
                        <label htmlFor="referencia_pieza">Referencia</label>

                        <input type="text"  
                            {...register("referencia_pieza")}
                            onChange={handleChangeReferencia}
                            defaultValue={''} />
                    </div>

                    <div className="form-group me-0 me-md-3">
                        <label htmlFor="id_original">ID</label>

                        <input type="text"  
                            {...register("id_original")}
                            onChange={handleChangeID}
                            defaultValue={''} />
                    </div>
                </div>

                <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
                    <div className="form-group">
                        <label htmlFor="marca_id">Marca</label>

                        <input type="hidden" 
                            {...register("marca_id")} 
                            defaultValue={0} />

                        { marcas.length > 0 &&
                            <VirtualizedAutocomplete
                                id={"marca"}
                                control={control}
                                options={marcas?.sort((a, b) => a.marca_id - b.marca_id)}
                                groupBy={null}
                                labels={['nombre']}
                                defaultValue={null} 
                                onChange={handleChangeMarca}
                            />
                        }

                        <div className="form-invalid">
                            {errors.modelo_id?.message && 'Selecciona un modelo válido.'}
                        </div>
                    </div>

                    <div className="form-group mx-0 mx-md-3">
                        <label htmlFor="modelo_id">Modelo</label>

                        <input type="hidden" 
                            {...register("modelo_id")} 
                            defaultValue={0} />

                        {
                            <VirtualizedAutocomplete
                                id={"modelo"}
                                control={control}
                                options={filterModelos?.sort((a, b) => a.marca_id - b.marca_id)}
                                disabled={!watch('marca') || watch('marca') === ""}
                                labels={['nombre']}
                                defaultValue={null} 
                                onChange={handleChangeModelo}
                            />
                        }

                        <div className="form-invalid">
                            {errors.modelo_id?.message && 'Selecciona un modelo válido.'}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="tipo_pieza_id">Tipo de pieza</label>

                        <input type="hidden" 
                            {...register("tipo_pieza_id")} 
                            defaultValue={0} />

                        { <VirtualizedAutocomplete
                                id={"tipo_pieza"}
                                control={control}
                                options={tipoPiezas?.sort((a, b) => a.familia_tipo_pieza_id - b.familia_tipo_pieza_id)}
                                groupBy={null}
                                labels={['nombre']}
                                disabled={!watch('modelo') || watch('modelo') === ""}
                                defaultValue={null} 
                                onChange={handleChangeTipoPieza}
                            />
                        }

                        <div className="form-invalid">
                            {errors.modelo_id?.message && 'Selecciona un modelo válido.'}
                        </div>
                    </div>

                    <button 
                        disabled={btnDisabled}
                        type="submit" 
                        className="btn btn-primary ms-0 ms-md-3"
                    >BUSCAR</button>
                    <span title="Cancelar" className="ms-3" onClick={() => onCancel()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                    </span>
                </div>

                <small
                    style={{fontSize: '11px'}} 
                    className="text-uppercase">Busca por: ID | referencia | marca + modelo + tipo de pieza</small>
            </form>
        </div>
    );
}