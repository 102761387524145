import React from "react";
import ChangePasswordForm from "./components/ChangePasswordForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function ChangePassword({onResetPassword}) {

    return (
        <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
            <div className="col-12 d-flex flex-column align-items-center">
                <h1 className="page-name">Cambio de contraseña</h1>

                <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                    <ChangePasswordForm onChange={onResetPassword}/>

                    <div className="col-12 col-md-4 d-flex flex-column ps-0 ps-md-5" style={{textAlign: 'justify'}}>
                        <span className="d-flex flex-row align-items-center form-group">
                            <span title="Información">
                                <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" />
                            </span>
                            <label className="ps-3 m-0">Información</label> 
                        </span>
                        
                        <small>1. Introduce tu contraseña antigua.</small>
                        <small>2. Repite la contraseña antigua</small>
                        <small>3. Si coinciden, apareceran los campos para la nueva contraseña.</small>
                        <small>4. Si estas también coinciden, aparecerá el botón de guardar.</small>
                        <small>5. Guardar y se cerrará sesión para establecer la nueva contraseñaa</small>
                    </div>
                </div>
            </div>
        </div>
    )
}