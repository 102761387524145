import React, { useEffect } from 'react';
import { DoughnutChart } from '../../../../../../../shared/components/Charts/components/Doughnut';
import VerticalBarChart from '../../../../../../../shared/components/Charts/components/VerticalBar';
import MetricsTable from '../../../shared/MetricsTable';

export default function ProveedoresMetrics({view, data}) {

    useEffect(() => {
        
    }, [data, view]);

    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-between my-3'>
            <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between'>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.top_proveedores ?
                        <MetricsTable 
                            titulo={"Top 10 Proveedores anual (piezas pedidas)"}
                            labels={['proveedor', 'total']}
                            data={data.top_proveedores}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>

                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.top_facturacion ?
                        <MetricsTable 
                            titulo={"Mejores Proveedores"}
                            labels={['proveedor', 'facturacion']}
                            data={data.top_facturacion}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>
            </div>

            <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between'>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.top_proveedores_view ?
                        <MetricsTable 
                            titulo={"Top 10 Proveedores (piezas pedidas)"}
                            labels={['proveedor', 'total']}
                            data={data.top_proveedores_view}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>

                <div className="col-12 col-md-6 pe-0 pe-md-3">
                </div>
            </div>
        </div>
    )
}